<template>
  <div class="direct-messaging-message-editor-container">
    <div class="editor-container">
      <editor
          initialEditType="wysiwyg"
          height="130px"
          :options="customOptions"
          @change="handleEditorChange"
          ref="editorRef"
      />
    </div>
    <div class="editor-footer">
      <button class="btn-primary send-message-btn" @click="sendMessage" :disabled="!canSendMessage">
        <i class="fa fa-paper-plane"></i>Send
      </button>
    </div>
  </div>
</template>

<script>


import { Editor } from '@toast-ui/vue-editor'
import Conversation from "@services/directMessaging/conversation";

export default {
  name: 'direct-messaging-message-editor',
  components: {
    Editor,
  },
  props: {
    disabled: { type: Boolean, required: true },
    conversation: { type: Conversation, required: true },
    afterSendHook: { type: Function, required: false },
  },
  data: () => ({
    newMessage: {html: '', markdown: ''},
    editorElement: null,
    editorContentsElement: null,
    customOptions: {
      hideModeSwitch: true,
      toolbarItems: [
        ['bold', 'italic', 'strike',],
        ['ul', 'ol'],
      ]
    }
  }),
  async mounted () {
    this.editorElement = this.$refs.editorRef.$el;
    this.editorElement.addEventListener('keydown', this.handleEditorKeyTyping, true);

    this.editorContentsElement = this.editorElement.querySelector('div.ProseMirror.toastui-editor-contents');
  },
  destroy() {

  },
  computed: {
    canSendMessage() {
      return !this.disabled
              && this.newMessage.markdown.trim().length > 0;
    },
  },
  methods: {
    handleEditorKeyTyping(event) {
      // replaces original editor Enter and shift+Enter behavior with our own
      // (shift+Enter for new line, Enter for send message)
      if (event.customEvent !== true){
        if (event.key === 'Enter' && event.shiftKey){
          event.preventDefault(); // Prevent default Enter behavior
          let keyDownEnterEvent = new KeyboardEvent('keydown', {'key': 'Enter'});
          keyDownEnterEvent.customEvent = true;
          this.editorContentsElement.dispatchEvent(keyDownEnterEvent);
        }
        else if (event.key === 'Enter'){
          event.preventDefault(); // Prevent default Enter behavior
          this.sendMessage();
        }
      }
    },
    async sendMessage() {
      if (this.canSendMessage){
        const message = {text: this.newMessage.html};
        await this.conversation.getStreamChannel.sendMessage(message);
        this.$refs.editorRef.invoke('setHTML', '');

        if (this.afterSendHook) {
          this.afterSendHook();
        }
      }
    },
    handleEditorChange() {
      const html = this.$refs.editorRef.invoke('getHTML');
      const markdown = this.$refs.editorRef.invoke('getMarkdown');

      this.newMessage = {html: html, markdown: markdown};
    },
  },
  watch: {
    conversation(newConversation, oldConversation) {
      this.$refs.editorRef.invoke('setHTML', '');
    },
  }

}

</script>

<style lang="scss" scoped>

.direct-messaging-message-editor-container {
  border: 1px solid #dadde6;

  .editor-container {

  }

  .editor-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .send-message-btn {
    background-color: #5d3c7f;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: darken(#611f69, 10%);
    }

    &:disabled {
      background-color: #f0f0f0;
      color: #b1b1b1;
      cursor: not-allowed;
      border: none;

      i {
        color: #b1b1b1;
      }
    }
  }
}
</style>
