<template>
  <div class="course-step-assignments">
    <div class="assignments-list-container" v-for="assignment in assignments" :key="assignment.id">
      <assignment-submitter :assignmentId="assignment.id" :assignmentsStoreId="assignmentsStore.uuid"></assignment-submitter>
    </div>
  </div>
</template>

<script>
  import AssignmentSubmitter from '@components/AssignmentSubmitter.vue'
  import { getAssignmentsStoreFor } from '@stores/AssignmentsStore'
  import { WindowVariables } from '@utils'

  export default {
    components: {
      AssignmentSubmitter
    },
    data () {
      return {
        assignmentsStore: getAssignmentsStoreFor({ groupId: WindowVariables.groupId, courseStepId: WindowVariables.courseStepId })
      }
    },
    fromMobx: {
      assignments () {
        return this.assignmentsStore.allRecords
      }
    }
  }

</script>

<style lang="scss" scoped>
  .course-step-assignments {
    color: $black;
  }

</style>