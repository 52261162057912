<template>
  <div class="direct-messaging-panel-container">
    <div class="direct-messaging-panel-title">
      <button
          v-if="conversation.isGroupChat"
          @click="openGroupChatInfoModal"
          class="group-chat-info-btn"
      >
        <span>{{ groupChatConversationName }}</span><span class="chevron-icon"></span>
      </button>

      <span v-else >{{ conversation.name }}</span>
    </div>
    <div class="direct-messaging-panel-content" ref="messagesContainer">
      <div v-for="(group, index) in groupMessagesByDay(messages)" :key="index" class="day-group">
        <div class="day-indicator">{{ group.day }}</div>
        <div v-for="(message, index) in group.messages" :key="index" class="message-container">
          <DirectMessagingAvatar :name="message.user.username" :userOnlineStatus="userOnlineStatus(message)" class="message-avatar"/>
          <div class="message-content">
            <div class="message-header">
              <span class="username">{{ message.user.username }}</span>
              <span class="timestamp">{{ formatTimestamp(message.created_at) }}</span>
            </div>
            <div v-if="hasVideoAttachments(message)" class="video-container">
              <DirectMessagingIframeVideo
                  v-for="(attachment, idx) in message.attachments"
                  v-if="isVideoAttachment(attachment)"
                  :key="idx"
                  :attachment="attachment"
                  @load="onMediaLoaded"
              />
            </div>
            <div v-if="hasImageAttachments(message)" class="image-container">
              <img
                  v-for="(attachment, idx) in message.attachments"
                  v-if="isImageAttachment(attachment)"
                  :key="idx"
                  :src="attachment.image_url"
                  :alt="attachment.title"
                  class="chat-image"
                  @load="onMediaLoaded"
              >
            </div>
            <div class="clear-base-el aligned-list" v-html="stripEnclosingPElement(message.html)"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="message-input-container">
      <DirectMessagingMessageEditor :conversation="conversation" :disabled="!shouldEnableSendingMessages" :afterSendHook="afterSendHook" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DirectMessagingAvatar from "./DirectMessagingAvatar.vue";
import DirectMessagingMessageEditor from "./DirectMessagingMessageEditor.vue";
import DirectMessagingIframeVideo from "@components/directMessaging/DirectMessagingIframeVideo.vue";
import Conversation from "@services/directMessaging/conversation";
import User from "@services/directMessaging/user";
import ModalService from "@services/ModalService";

const baseUrl = window.location.origin

export default {
  name: 'direct-messaging-current-conversation',
  components: {
    DirectMessagingAvatar,
    DirectMessagingMessageEditor,
    DirectMessagingIframeVideo,
  },
  props: {
    currentUser: { type: User, required: true },
    connected: { type: Boolean, required: true },
    conversation: { type: Conversation, required: true },
  },
  data: () => ({
    messages: [],
    messagesContainer: null,
    stickScrollToBottom: true,

    alertVisible: false,
    errorMessage: '',
  }),
  async mounted () {
    this.messagesContainer = this.$refs.messagesContainer;
    this.messagesContainer.addEventListener('scroll', this.handleScrollEvents);
    await this.selectConversation(this.conversation);
  },
  destroy() {

  },
  computed: {
    shouldEnableSendingMessages() {
      return this.connected
              && (this.conversation.isGroupChat || this.conversation.isConversationWithMyself || this.conversation.members[0].active === true);
    },
    groupChatConversationName() {
      return this.conversation.members.length >= 4?
          this.conversation.members.slice(0, 2).map(member => member.username).join(', ') + ` and ${this.conversation.members.length - 2} others`:
          this.conversation.name;
    },
  },
  methods: {
    openGroupChatInfoModal() {
      ModalService.open('direct-messaging-group-chat-info-modal', {conversation: this.conversation})
    },
    stripEnclosingPElement(messageContent) {
      // getstream encloses the whole message in a <p> element, but we are already sending html so we strip it out
      const message = messageContent.trim();
      const enclosedByPElement = message.startsWith('<p>') && message.endsWith('</p>');
      return enclosedByPElement ? message.slice(3, -4) : message;
    },
    hasVideoAttachments(message) {
      return message.attachments && message.attachments.some(att => this.isVideoAttachment(att));
    },
    isVideoAttachment(attachment) {
      return attachment.type === 'video';
    },
    hasImageAttachments(message) {
      return message.attachments && message.attachments.some(att => this.isImageAttachment(att));
    },
    isImageAttachment(attachment) {
      return attachment.type === 'image';
    },
    isScrollAtBottom(container){
      // The "+ 1" accounts for fractional pixel rounding issues
      return container ?
             container.scrollTop + container.clientHeight + 1 >= container.scrollHeight :
             false;
    },
    isScrollAtTop(container){
      return container ?
             container.scrollTop === 0 :
             false;
    },
    afterSendHook() {
      this.moveScrollToBottom(this.messagesContainer);
    },
    moveScrollToBottom(container){
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    handleScrollEvents(event) {
      const container = event.target;
      this.stickScrollToBottom = this.isScrollAtBottom(container);
      if (this.isScrollAtTop(container) && this.messages.length > 0) {
        this.loadOlderMessages();
      }
    },
    async loadOlderMessages() {
      const currentScrollPosition = this.messagesContainer.scrollHeight;
      await this.conversation.loadOlderMessages(25);

      this.$nextTick(() => {
        // Adjust the scroll position so it stays in position after loading older messages
        this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight - currentScrollPosition;
      });
    },
    groupMessagesByDay(messages) {
      const grouped = {};

      messages.forEach(message => {
        const day = this.formatDay(message.created_at);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });

      return Object.keys(grouped).map(day => ({ day, messages: grouped[day] }));
    },
    formatDay(date) {
      return dayjs(date).format('MMMM D');
    },
    formatTimestamp(date) {
      return dayjs(date).format('hh:mm A');
    },
    selectConversation(conversation){
      this.messages = conversation.getStreamChannel.state.messages;
      conversation.markMessagesAsRead();
      this.stickScrollToBottom = true;
      this.moveScrollToBottom(this.messagesContainer);
    },
    onMediaLoaded() {
      if (this.stickScrollToBottom) {
        this.moveScrollToBottom(this.messagesContainer);
      }
    },
    userOnlineStatus(message){
      const allMembers = [...this.conversation.members, ...[this.conversation.currentUser]]
      const member = allMembers.find(m => String(m.dmUserId) === message.user.id);
      return this.conversation.getMemberOnlineStatus(member?.dmUserId);
    }
  },
  watch: {
    conversation(newConversation, oldConversation) {
      this.selectConversation(newConversation)
    },
    'conversation.getStreamChannel.state.messages'(newMessages, oldMessages) {
      this.messages = newMessages;
      this.conversation.markMessagesAsRead();
      if (this.stickScrollToBottom) {
        this.moveScrollToBottom(this.messagesContainer);
      }
    },
  }

}

</script>

<style lang="scss" scoped>

//.current-conversation-container {
//  //padding: 0;
//  height: 100%;
//  display: flex;
//  flex-direction: column;
//}

.message-container {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  //padding: 5px;

  .message-avatar {
    margin-right: 10px;
  }
}

.message-content {
  flex-grow: 1;
}

.message-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.username {
  font-weight: bold;
  margin-right: 20px;
}

.timestamp {
  color: #999999;
  font-size: 0.8em;
}

.day-group {
  position: relative;
  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
  padding-top: 10px;
}

.day-indicator {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: #666666;
  padding: 0 10px;
  font-weight: bold;
  border-radius: 10px;
}

.image-container img.chat-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.send-message-btn {
  margin-left: 20px;
}

.group-chat-info-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #ebebeb;
  }
}

</style>
