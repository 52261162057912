<template>
  <div class="users-component">
    <group-roster />
  </div>
</template>

<script>

  import GroupRoster from '@components/GroupRoster.vue'

  export default {
    name: 'users-component',
    components: {
      GroupRoster
    },
    data () {
      return {

      }
    }
  }
</script>

<<style lang="scss" scoped>

</style>
