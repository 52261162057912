import Model from '@models/Model'

class CourseStepContent extends Model {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'name',
        'description',
        'content_type',
        'presigned_content_url',
        'presigned_thumbnail_url',
        'content_tags',
      ],
      momentKeys: [
        'created_at',
        'updated_at',
      ],
      relationshipMap: {},
    })
  }

  get description_shortened () {
    let res = this.description.substr(0, 30)
    return this.description.length > 30 ? res + '...' : res
  }
}

export default CourseStepContent
