import {StreamChat} from "stream-chat";
import PromiseHandler from "@services/promiseHandler";
import _ from "lodash";

/**
 * This is a wrapper class for the GetStream.io client.
 *
 */

class GetStreamClient {
    constructor(apiKey) {
        this.client = new StreamChat(apiKey);
        this.connectionChangedInternalListener = this.client.on("connection.changed", event => this.#handleConnectionChangedEvent(event));
        this.messageNewInternalListener = this.client.on("message.new", event => this.#handleMessageNewEvent(event));
        this.notificationMessageNewInternalListener = this.client.on("notification.message_new", event => this.#handleNotificationMessageNewEvent(event));
        this.notificationMarkReadInternalListener = this.client.on("notification.mark_read", event => this.#handleNotificationMarkReadEvent(event));
        this.connected = false;
        this._reconnectCountDown = 0;
        this.reconnectIntervalId = null;
        this.totalUnreadMessagesCount = null;
    }

    get reconnectCountDown() {
        return this._reconnectCountDown;
    }

    async connect(currentUser, authToken, errorHandlingOptions = {}) {
        const streamUserId = String(currentUser.dmUserId);
        const streamUser = {id: streamUserId}
        this.getStreamUser = await this.#connect(streamUser, authToken, errorHandlingOptions);
        return this.getStreamUser;
    }

    async disconnect() {
        await this.client.disconnectUser();
        this.notificationAddedToChannelListener.unsubscribe();
        this.connectionChangedListener.unsubscribe();
        this.connectionChangedInternalListener.unsubscribe();
        this.notificationMessageNewInternalListener.unsubscribe();
        this.notificationMarkReadInternalListener.unsubscribe();
        this.messageNewInternalListener.unsubscribe();
    }

    startListeningToNotificationAddedToTheChannel(eventHandler) {
        this.notificationAddedToChannelListener = this.client.on("notification.added_to_channel", event => eventHandler(event));
    }

    startListeningToConnectionChanged(eventHandler) {
        this.connectionChangedListener = this.client.on("connection.changed", event => eventHandler(event));
    }

    async queryMessage(messageId) {
        return this.client.getMessage(messageId);
    }

    async queryGetStreamChannel(channelId, watch = true, presence = true, errorHandlingOptions = {}){
        return this.queryGetStreamChannels([channelId], watch, presence, errorHandlingOptions)
            .then(response => response?.[0] ?? null);
    }

    async queryGetStreamChannels(channelIds, watch = true, presence = true, errorHandlingOptions = {}){
        const pageSize = 5;
        const getStreamChannelIds = channelIds.map(id => `messaging:${id}`);
        const sort = [{ last_message_at: -1 }];
        const currentUserId = this.getStreamUser.me.id;

        const queryChannelsFns = _.chunk(getStreamChannelIds, pageSize)
            .map(idsPage => ({ type: 'messaging', cid: { $in: idsPage }, members: { $in: [currentUserId] } }))
            .map(filter => () => this.client.queryChannels(filter, sort, {watch: watch, state: true, presence: presence}));

        const onFailedAttemptEnrichedFn = (error, nextDelay) => this.#defaultOnFailedAttemptQueryChannels(error, nextDelay);
        const defaultOptions = { retries: Infinity, onFailedAttemptEnriched: onFailedAttemptEnrichedFn };
        const finalErrorHandlingOptions = { ...defaultOptions, ...errorHandlingOptions };
        const concurrency = 5;

        return PromiseHandler.callMany(queryChannelsFns, finalErrorHandlingOptions, concurrency)
               .then(result => result.flat());
    }

    async #connect(streamUser, authToken, errorHandlingOptions = {}) {
        const connectFn = () => this.client.connectUser(streamUser, authToken);
        const onFailedAttemptEnrichedFn = (error, nextDelay) => this.#defaultOnFailedAttemptConnection(error, nextDelay);
        const defaultOptions = { retries: Infinity, onFailedAttemptEnriched: onFailedAttemptEnrichedFn };
        const finalOptions = { ...defaultOptions, ...errorHandlingOptions };

        return PromiseHandler.call(connectFn, finalOptions);
    }

    #defaultOnFailedAttemptConnection(error, nextDelay) {
        this.#startReconnectCountdown(nextDelay);
        console.warn(`Error connecting to GetStream! error -> ${error} - Retrying in ${nextDelay}...`);
    }

    #defaultOnFailedAttemptQueryChannels(error, nextDelay) {
        this.#startReconnectCountdown(nextDelay);
        console.warn(`Error querying channels on GetStream! error -> ${error} - Retrying in ${nextDelay}...`);
    }

    #handleConnectionChangedEvent(event) {
        this.connected = event.online;
    }

    #handleMessageNewEvent(event) {
        this.totalUnreadMessagesCount = event.total_unread_count;
    }

    #handleNotificationMessageNewEvent(event) {
        this.totalUnreadMessagesCount = event.total_unread_count;
    }

    #handleNotificationMarkReadEvent(event) {
        this.totalUnreadMessagesCount = event.total_unread_count;
    }

    #startReconnectCountdown(countdownNextReconnectInMs){
        if (this.reconnectIntervalId) {
            clearInterval(this.reconnectIntervalId);
        }

        this._reconnectCountDown = countdownNextReconnectInMs;

        const callback = () => {

            this._reconnectCountDown -= 1000;

            if (this._reconnectCountDown <= 0) {
                clearInterval(this.reconnectIntervalId);
                this._reconnectCountDown = 0;
            }
        }

        // Update countdown every second
        this.reconnectIntervalId = setInterval(callback, 1000); // Update interval
    }

}

export default GetStreamClient;
