<template>
  <div class="users-list-component">
    <b-table striped hover :items="processedData" :fields="fields">
      <template #cell(first_name)="data">
        <a :href="userUrl(data.item.id)">{{ data.value }}</a>
      </template>

      <template #cell(last_name)="data">
        <a :href="userUrl(data.item.id)">{{ data.value }}</a>
      </template>

      <template  #cell(role)="data">
        <template v-for="mnt in data.item._current_mentorships">
          <span class="badge badge-pill badge-secondary" :key="mnt.id">
            {{ mnt.mentor.first_name }} {{ mnt.mentor.last_name }}
            <i class="fa fa-minus-circle" @click="disableMentorship(mnt.id)"></i>
          </span>
        </template>

        <button class="btn btn-secondary btn-sm" v-if="!data.item._has_mentorship" @click="open(data.item.id)">Assign Mentor</button>
        <i class="fa fa-plus-circle" v-if="data.item._has_mentorship" @click="open(data.item.id)" />
      </template>
    </b-table>
  </div>
</template>

<script>
  import { find, filter, map } from 'lodash-es'
  import dayjs from '@services/dayjsWrapper'
  import { WindowVariables } from '@utils'
  import { url } from '@services/LinkService'
  import { getMentorshipsStoreFor} from '@stores/MentorshipsStore'
  import { getUsersStoreFor } from '@stores/UsersStore'
  import ModalService from '@services/ModalService'

  import StoreInstances from '@stores/StoreInstances'




  export default {
    name: 'users-list',
    components: {},
    data() {
      return {
        groupId: WindowVariables.groupId,
        mentorshipsStore: getMentorshipsStoreFor(),
      }
    },
    computed: {
      processedData () {
        return this.data.map(user => {
          user._current_group_membership = find(user.group_memberships, { group_id: WindowVariables.groupId })
          user._current_mentorships = map(filter(user._current_group_membership.mentorships, { mentee_id: user.id, inactive_at: null }), (mnt) => {
            return {
              ...mnt,
              mentor: StoreInstances.findRecordByTypeAndId('Users', mnt.mentor_id),
            }
          })
          user._has_mentorship = user._current_mentorships.length > 0
          user._overall_completion_ratio = user._current_group_membership.cached_metadata['overall_completion_ratio'] || 0
          user._current_mentorships
          return user
        })
      },
      isStudent() {
        return this.userRole === 'student'
      },
      fields () {
        let fields = [{
          key: 'first_name',
          label: 'First Name',
          sortable: true
        }, {
          key: 'last_name',
          label: 'Last Name',
          sortable: true
        }, {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          formatter: v => !v ? '' : dayjs.utc(v).tz(WindowVariables.timezone).format('MM-DD-YY h:mm A'),
        }, ]

        if (WindowVariables.group.hasStarterTrackingFeature && this.userRole === 'student') {
          fields.push({
            key: '_overall_completion_ratio',
            label: '% Completed',
            sortable: true,
            formatter: v => `${(v * 100).toFixed(1)}%`,
          })
        }

        if (WindowVariables.currentUser.teacherForGroupId(WindowVariables.groupId) &&
            this.userRole === 'student' &&
            WindowVariables.group.hasMentoringFeature) {
          fields.push({
            key: 'role',
            label: 'Mentor',
            sortable: true,
          })
        }

        return fields
      },
    },
    methods: {
      userUrl (userId) {
        return url({ entityType: 'user', entityId: userId })
      },
      open (userId) {
        ModalService.open('mentorship-modal', { groupId: this.groupId, userId: userId })
      },
      disableMentorship (mentorshipId) {
        this.mentorshipsStore.update({
          id: mentorshipId,
          status: 'inactive',
        })
        .then(() => {
          this.vm.usersStore._reload()
        })
      }
    },
    props: {
      data: {
        type: Array,
      },
      userRole: {
        role: String,
      },
    },
  }
</script>

<style lang="scss" scoped>
.fa-minus-circle, .fa-plus-circle {
  cursor: pointer;
}
</style>
