<template>
  <div class="vue-flash-messages">
    <flash-messages />
  </div>
</template>

<script>

  import { each } from 'lodash-es'
  import FlashMessages from '@components/flashMessages/FlashMessages.vue'
  import FlashMessageService from '@services/FlashMessageService'
  import { WindowVariables } from '@utils'

  export default {
    name: 'flash-message',
    components: {
      FlashMessages,
    },
    mounted () {
      each(WindowVariables.flashMessages, msg => {
        FlashMessageService[msg[0]]({ msg: msg[1], timeout: 180000 })
      })
    },
  }
</script>
