<template>
  <div class="analytics-event-tracking"></div>
</template>

<script>
  import AnalyticEvents from '@dataServices/AnalyticEvents'

  export default {
    data () {
      return {
        groupId: window.courseVars.group_id,
        courseStepId: window.courseVars.course_step_id,
        courseId: window.courseVars.course_id,
        skipTimeUpdate: true,
        lastSeekedTime: null,
      }
    },
    methods: {

    },
    mounted () {
      // say that we loaded the page
      AnalyticEvents.trackStepLoad(this.groupId, this.courseStepId, this.courseId)

      // bind to any document download links and track those
      $('.btn--action-step').click((evt) => {
        AnalyticEvents.trackActionStepDownload(
          this.groupId,
          this.courseStepId,
          this.courseId,
          parseInt(evt.delegateTarget.dataset.attachmentId)
        )
      })
    }
  }
</script>

<style lang="scss" scoped>
</style>
