<template>
  <v-container fluid :class="{'is-collapsed': collapsed}" class="direct-messaging-sidebar-container">
    <div class="sidebar-title-container">
      <div class="sidebar-title">Find & Connect</div>
      <button @click="toggleCollapse" aria-label="Collapse side navigation" type="button" class="collapse-toggle-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <v-alert v-if="shouldShowOfflineAlertWithReconnect" v-model="shouldShowOfflineAlert" type="error" class="offline-alert">
      <div class="offline-alert-content">
        <span class="offline-alert-title">You are currently disconnected!</span>
        <span class="offline-alert-text">Please verify your internet connection. Attempting to reconnect in {{reconnectCountDownInSeconds}} seconds.</span>
        <span class="offline-alert-reconnect" @click="reconnectNowCallback">Reconnect Now</span>
      </div>
    </v-alert>
    <v-alert v-else v-model="shouldShowOfflineAlert" type="error" class="offline-alert">
      <div class="offline-alert-content">
        <span class="offline-alert-title">You are currently disconnected!</span>
        <span class="offline-alert-text">Please verify your internet connection.</span>
      </div>
    </v-alert>
    <div class="direct-messages-header">
      <span class="direct-messages-title">START NEW CONVERSATION</span>
      <v-btn color="white" class="search-users-btn" @click="handleShowSearchButtonClick" :disabled="!connected" aria-label="Start a new Find And Connect conversation">+</v-btn>
    </div>
    <div class="direct-messages-sidebar-conversations">
      <div v-if="isLoadingConversations" class="loading-indicator">
        <v-progress-circular
            indeterminate
            size="80"
            width="6"
        >
        </v-progress-circular>
      </div>
      <v-list v-else class="conversation-list" dense>
        <v-list-item-group
            :mandatory="isMandatory"
            v-model="selectedConversation"
        >
          <v-list-item
              v-for="(conversation, index) in conversations"
              :key="conversation.id"
              @click="selectConversation(conversation)"
              :value="conversation"
              class="conversation-item"
              :disabled="!connected"
          >
            <v-list-item-content>
              <v-list-item-title class="conversation-wrapper">
                <div class="conversation-header">
                  <div class="left-content">
                    <DirectMessagingAvatar :name="conversation.name" :size="30" :font-size="16" class="conversation-avatar" :userCount="userCount(conversation)" :userOnlineStatus="userOnlineStatus(conversation)" />
                    <span class="conversation-title">{{ conversation.name }}</span>
                    <span class="unread-messages-count" v-if="shouldShowUnreadMessagesCount(conversation)">
                      {{ conversation.unreadMessagesCount }}
                    </span>
                  </div>

                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </v-container>
</template>

<script>

import GlobalEventBus from '@services/GlobalEventBus'
import axios from '@dataServices/axios'
import DirectMessagingCurrentConversation from "./DirectMessagingCurrentConversation.vue";
import DirectMessagingSearchUsers from "./DirectMessagingSearchUsers.vue";
import DirectMessagingAvatar from "./DirectMessagingAvatar.vue";
import GetStreamClient from "@services/directMessaging/getStreamClient";
import User from "@services/directMessaging/user";
import Conversation from "@services/directMessaging/conversation";
import { VContainer, VList, VListItem, VListItemContent, VListItemGroup, VListItemTitle, VAlert, VBtn, VProgressCircular } from 'vuetify/lib'

const baseUrl = window.location.origin
const NAME = 'direct-messaging-sidebar'


export default {
  name: NAME,
  components: {
    DirectMessagingCurrentConversation,
    DirectMessagingSearchUsers,
    DirectMessagingAvatar,
    VContainer,
    VList,
    VListItem,
    VListItemGroup,
    VListItemContent,
    VListItemTitle,
    VAlert,
    VBtn,
    VProgressCircular,
  },
  props: {
    currentUser: { type: User, required: true },
    getStreamClient: { type: GetStreamClient, required: true },
    connected: { type: Boolean, required: true },
    connectedAtLeastOnce: { type: Boolean, required: true },
    reconnectCountDown: { type: Number, required: false },
    reconnectNowCallback: {type: Function, required: true},
  },
  data: () => ({
    conversations: [],
    conversationIds: new Set(),
    selectedConversation: null,
    showSearchUsersToggle: false,

    unsubscribeListenerFunctions: [],
    isMandatory: false, //this is used so the user cannot deselect the selected conversation by clicking on it
    isLoadingConversations: true,
    retryAbortController: new AbortController(),
    shouldTriggerConversationsSortingWatch: true,

    shouldShowOfflineAlert: false,
    timerToShowOfflineAlert: null,
    collapsed: false,
  }),
  async mounted () {
    if (this.connected === false){
      this.startTimerToShowOfflineAlert();
    }
    this.init();
    // directMessagingState.getStreamClient.client.on(event => console.info('client.event:', event.type, event)); //FIXME: remove all console.info
    // directMessagingState.getStreamClient.client.on("notification.added_to_channel", event => console.info('client.event:', event)); //FIXME: remove all console.info
    // directMessagingSidebarState.setSelectedConversation(this.selectedConversation);
    // directMessagingSidebarState.setShowSearchUsersToggle(this.showSearchUsersToggle);
  },
  async beforeDestroy () {
    this.$emit('destroyed');
    this.retryAbortController.abort(new Error("Aborting current retry attempt!"));
    this.selectedConversation = null;
    this.conversations.forEach(c => c.destroy());
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
  },
  computed: {
    shouldShowOfflineAlertWithReconnect(){
      return this.shouldShowOfflineAlert
             && this.connectedAtLeastOnce === false;
    },
    reconnectCountDownInSeconds(){
      return this.reconnectCountDown && this.reconnectCountDown > 0?
              Math.round(this.reconnectCountDown / 1000) :
              this.reconnectCountDown;
    },
    latestMessagePerConversationTimestamps(){
      return this.conversations.map(c => {
        const lastMessageAt = c.getStreamChannel.state.last_message_at;
        const channelCreatedAt = c.getStreamChannel.data.created_at;
        return lastMessageAt? lastMessageAt : channelCreatedAt;
      })
    },
  },
  methods: {
    init(){
      if (this.getStreamClient && this.connected === true && this.isLoadingConversations === true){
        this.fetchConversations();
        this.getStreamClient.startListeningToNotificationAddedToTheChannel(this.handleAddedToChannelEvent);
        this.unsubscribeListenerFunctions.push(GlobalEventBus.subscribe('direct-messaging-search-users', (event) => this.handleSearchUsersEvent(event)));
      }
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    shouldShowUnreadMessagesCount(conversation){
      return conversation?.id !== this.selectedConversation?.id
          && conversation?.unreadMessagesCount > 0
    },
    handleSearchUsersEvent(event) {
      switch (event.type) {
        case 'createdNewConversation':
          this.handleCreatedNewConversation(event);
          break;
        default:
          console.error('DirectMessaging Error! Received Unknown event type from direct-messaging-search-users!', event.type);
      }
    },
    async handleCreatedNewConversation(event) { //DirectMessagingSearchUsers createdNewConversation event for when the user starts a new conversation
      const createdChannel = event.createdChannel;

      const conversationToSelect = this.findExistingConversation(createdChannel);

      if (conversationToSelect){
        this.selectConversation(conversationToSelect);
      }
    },
    async handleAddedToChannelEvent(event) { //getstream notification event for when the user is added to a new channel
      const response = await this.fetchConversation(event.channel.id).catch(error => this.processResponseError(error))
      const conversationToSelect = await this.getOrCreateConversation(response.data)
      const currentUserIsOwner = event.channel.created_by.id === String(this.currentUser.dmUserId);
      if (conversationToSelect && currentUserIsOwner){
        this.selectConversation(conversationToSelect);
      }
    },
    async getOrCreateConversation(backendChannel){
      const sameConversationFound = this.conversationIds.has(String(backendChannel.id));

      // TODO: the better way of doing this would be if the backend could return domain error codes,
      // so then we could check for the error code and know if the conversation already exists or not and not have to treat it here
      const result = sameConversationFound ?
                      await this.findExistingConversation(backendChannel) :
                      await this.addNewConversation(backendChannel) ;

      return result;

    },
    findExistingConversation(backendChannel){
      return this.conversations.find(c => c.id === backendChannel.id);
    },
    async addNewConversation(backendChannel){
      this.conversationIds.add(String(backendChannel.id));
      const getStreamChannel = await this.getStreamClient.queryGetStreamChannel(backendChannel.id, true, true, this.getErrorHandlingOptions());
      const newConversation = new Conversation(this.currentUser, backendChannel, getStreamChannel);

      this.conversations.push(newConversation);
      return newConversation;
    },
    async fetchConversation(channelId) {
      return await axios.get(baseUrl + `/api/direct_messages/channels/${channelId}`)
    },
    async fetchConversations() {
      let keepFetching = true;
      let page = 1;
      let perPage = 30;
      this.isLoadingConversations = true;
      this.conversations = [];

      try {
        while (keepFetching) {
          const request = { params: { page: page, per_page: perPage } }
          const response = await axios.get(baseUrl + `/api/direct_messages/channels`, request);
          this.processFetchConversationsResponse(response);
          keepFetching = response.data.records.length === perPage;
          page += 1;
        }
      } catch (error) {
        this.processResponseError(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async processFetchConversationsResponse (response) {
      const channelIds = response.data.records.map(channel => String(channel.id));
      const getStreamChannels = await this.getStreamClient.queryGetStreamChannels(channelIds, true, true, this.getErrorHandlingOptions());
      const newConversations = response.data.records.map(channel => {
        const getStreamChannel = getStreamChannels.find(c => c.data.id === String(channel.id));

        if (getStreamChannel) {
          return new Conversation(this.currentUser, channel, getStreamChannel)
        }
        else {
          console.error('DirectMessaging Error! Error fetching Conversations! Could not find getStreamChannel for channel:', channel);
          return null;
        }
      })
      .filter(c => c !== null)
      ;
      this.conversations.push(...newConversations);
    },
    processResponseError (error) {
      this.errorMessage = error.response? error.response.data.message : error.message;
      this.alertVisible = true;
      console.error('Backend Error:', this.currentUser, this.errorMessage, error.response?.data, error.response?.status);
    },
    selectConversation(conversation) {
      this.isMandatory = true;
      this.showSearchUsersToggle = false;
      if (this.selectedConversation) {
        this.selectedConversation.deselect();
      }
      this.selectedConversation = conversation;
      this.selectedConversation.select();
    },
    handleShowSearchButtonClick() {
      this.showSearchUsersToggle = true;
      this.isMandatory = false;
      this.selectedConversation = null;
    },
    getErrorHandlingOptions() {
      return {
        retries: Infinity,
        timeoutInMs: 2000,
        minTimeout: 1000,
        maxTimeout: 60000,
        factor: 2,
        signal: this.retryAbortController.signal
      }
    },
    userCount(conversation) {
      return conversation.isGroupChat?
             conversation.members.length:
             null;
    },
    userOnlineStatus(conversation){
      return conversation.isGroupChat ? null:
             conversation.isConversationWithMyself? conversation.getMemberOnlineStatus(conversation.currentUser.dmUserId):
             conversation.getMemberOnlineStatus(conversation.members[0].dmUserId);
    },
    getSortedConversations(conversations){
      return [...conversations].sort((a, b) => {
        const lastMessageAtA = a.getStreamChannel.state.last_message_at;
        const lastMessageAtB = b.getStreamChannel.state.last_message_at;

        const channelCreatedAtA = a.getStreamChannel.data.created_at;
        const channelCreatedAtB = b.getStreamChannel.data.created_at;

        const timestampA = lastMessageAtA ? new Date(lastMessageAtA).getTime() : new Date(channelCreatedAtA).getTime();
        const timestampB = lastMessageAtB ? new Date(lastMessageAtB).getTime() : new Date(channelCreatedAtB).getTime();

        return timestampB - timestampA; // For descending order
      })
    },
    startTimerToShowOfflineAlert(){
      const fn = () => { this.shouldShowOfflineAlert = true }
      this.timerToShowOfflineAlert = setTimeout(fn, 10000);
    },
  },
  watch: {
    conversations(newVal, oldVal) {
      this.conversationIds = new Set(newVal.map(c => String(c.id)));
    },
    connected(newVal, oldVal) {
      if (newVal === true && oldVal === false){
        if (this.timerToShowOfflineAlert){
          clearTimeout(this.timerToShowOfflineAlert);
        }

        this.shouldShowOfflineAlert = false;
        this.init();
      }

      if (newVal === false && oldVal === true){
        this.shouldShowOfflineAlert = true;
      }
    },
    getStreamClient(newVal, oldVal) {
      this.init();
    },
    latestMessagePerConversationTimestamps(newVal, oldVal) {
      const sortedConversations = this.getSortedConversations(this.conversations);

      const orderChanged = sortedConversations.some((item, index) => {
        return item.id !== this.conversations[index].id;
      });

      if (orderChanged){ //need this check otherwise it will trigger an infinite loop with the watcher
        this.conversations = sortedConversations;
        if (this.selectedConversation){
          this.selectConversation(this.selectedConversation);
        }
      }
    },
    selectedConversation(newVal, oldVal) {
      const payload = { selectedConversation: newVal, showSearchUsersToggle: this.showSearchUsersToggle, }
      GlobalEventBus.dispatch(NAME, payload)
    },
    showSearchUsersToggle(newVal, oldVal) {
      const payload = { selectedConversation: this.selectedConversation, showSearchUsersToggle: newVal, }
      GlobalEventBus.dispatch(NAME, payload)
    },
  },
}

</script>

<style lang="scss" scoped>
.direct-messaging-sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 320px;
  background-color: rgb(31 41 55); //bg-gray-800
  border-color: rgb(55 65 81); //border-gray-700

  //transition: width 3.1s ease; // Smooth transition for width change


  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
  }

  .sidebar-title {
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    text-align: left;
    padding: 15px;
  }

  .direct-messages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 15px;

    .direct-messages-title {
      color: white;
      font-size: 0.875em;
      font-weight: bold;
    }

    .search-users-btn {
      display: flex;
      align-items: middle;
      justify-content: center;
      color: #9CA3AF;
      background-color: transparent;
      box-shadow: none;
      min-width: unset;
      width: 32px;
      height: 32px;
      font-size: 1.5rem;

      &:hover {
        background-color: #4B5563;
        border-radius: 8px;
        color: white;
      }

      &:disabled {
        color: #A0AEC0 !important; //necessary to add !important because vuetify overrides this
        background-color: #2D3748 !important;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .direct-messages-sidebar-conversations {
    flex-grow: 1;
    overflow-y: auto;

    .conversation-list {
      background-color: transparent;
      padding: 0;

      .conversation-item {
        padding: 0 15px;
        margin-bottom: 10px;
        background-color: transparent;
        border: 1px solid transparent;
        color: white;
        border-radius: 12px;

        &:hover {
          background-color: #4B5563;
          border: 1px solid #9CA3AF;
        }

        &.v-list-item--active {
          background-color: #334155;
          border-radius: 12px;
          border: 1px solid #475569;
        }
      }

      .conversation-wrapper .left-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1;  // This makes the left-content flex container grow to fill available space

        .conversation-avatar {
          margin-right: 8px;
          flex-shrink: 0; // Prevents the avatar from shrinking
        }

        .conversation-title {
          font-size: .875rem;
          font-weight: medium;
          line-height: 1rem;
          color: white;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 1;  // Allows the title to grow but also shrink if needed
          margin-right: 10px;
        }
      }
    }
  }

  .direct-messaging-panel {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  &.is-collapsed {
    width: 50px;

    .sidebar-title,
    .direct-messages-header,
    .direct-messages-sidebar-conversations {
      display: none; // Hide contents when collapsed
    }
  }
}

.conversation-view {
  height: 100%;
  width: 100%;
  padding: 8px 20px 3px 0;
  margin: 0;

  .conversation-panel {
    padding: 10px 24px;
    background-color: white;
    border-color: white;
    height: 100%;
    border-radius: 12px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.8);
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.collapse-toggle-btn {
  color: #f3f4f6;
  background-color: transparent;
  border-radius: 0.375rem;
  padding: 0.25rem;
  //transition: all 3.2s;
  //transition: transform 0.3s ease;
  //transform: translate(0, 0); // Original position

  .direct-messaging-sidebar-container.is-collapsed & {
    transform: translate(calc(100% - 35px), calc(100% - 10px)); // Adjust so the button moves with the collapsing sidebar
  }

  &:hover {
    background-color: #374151;
    color: #ffffff;
  }
}

.sidebar-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
