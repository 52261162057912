<template>
  <div class="current-students-table">
    <div style="margin-bottom: 15px">
      <BulkActionModalDropdownNew :selectedGroupMembershipIds="selectedStudents.map(s => s.student_group_membership_id)" :selectedTriageRequestIds="selectedStudents.map(s => s.id)"/>
    </div>
    <div class="table-filter-row configuration flex flex-row">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="table-filter-label">Student Name</label>
        <input
          id="student-filter"
          type="text"
          placeholder="Filter students"
          v-model="tableFilter.student"
          class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out"
        />
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-phase-filter" class="table-filter-label">Student Phase</label>
        <multiselect
            id="student-phase-filter"
            v-model="tableFilter.studentPhase"
            track-by="id"
            label="name"
            :options="filterOptions.phases">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="current-coach-filter" class="table-filter-label">Current Coach</label>
        <multiselect
            id="current-coach-filter"
            v-model="tableFilter.studentCurrentCoach"
            track-by="id"
            label="name"
            :options="filterOptions.coaches">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="current-goal-filter" class="table-filter-label">Current Goal</label>
        <multiselect
            id="current-goal-filter"
            v-model="tableFilter.currentGoal"
            track-by="id"
            label="name"
            :options="filterOptions.currentGoal">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="have-accountability-partner-filter" class="table-filter-label">Have Acct. Partner</label>
        <multiselect
            id="have-accountability-partner-filter"
            v-model="tableFilter.haveAccountabilityPartner"
            track-by="id"
            label="name"
            :options="filterOptions.haveAccountabilityPartner">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="have-mentor-filter" class="table-filter-label">Have Mentor</label>
        <multiselect
            id="have-mentor-filter"
            v-model="tableFilter.haveMentor"
            track-by="id"
            label="name"
            :options="filterOptions.haveMentor">
        </multiselect>
      </div>
    </div>
    <div class="table-filter-row configuration flex flex-row">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="business-mentor-filter" class="table-filter-label">Business Mentor</label>
        <multiselect
            id="business-mentor-filter"
            v-model="tableFilter.businessMentorCompleted"
            track-by="id"
            label="name"
            :options="filterOptions.businessMentorCompleted">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="received-funding-filter" class="table-filter-label">Received Funding</label>
        <multiselect
            id="received-funding-filter"
            v-model="tableFilter.receivedFundingCompleted"
            track-by="id"
            label="name"
            :options="filterOptions.receivedFundingCompleted">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="strategic-partnerships-filter" class="table-filter-label">Strat. Partnerships</label>
        <multiselect
            id="strategic-partnerships-filter"
            v-model="tableFilter.strategicPartnershipsCompleted"
            track-by="id"
            label="name"
            :options="filterOptions.strategicPartnershipsCompleted">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="received-intent-filter" class="table-filter-label">Received Intent</label>
        <multiselect
            id="received-intent-filter"
            v-model="tableFilter.receivedIntentCompleted"
            track-by="id"
            label="name"
            :options="filterOptions.receivedIntentCompleted">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="earned-revenue-filter" class="table-filter-label">Earned Revenue</label>
        <multiselect
            id="earned-revenue-filter"
            v-model="tableFilter.earnedRevenueCompleted"
            track-by="id"
            label="name"
            :options="filterOptions.earnedRevenueCompleted">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="reviewed-filter" class="table-filter-label">Reviewed</label>
        <multiselect
            id="reviewed-filter"
            v-model="tableFilter.reviewed"
            track-by="id"
            label="name"
            :options="filterOptions.reviewed">
        </multiselect>
      </div>
    </div>
    <div class="table-wrapper">
      <v-data-table
        :headers="tableHeaders"
        :items="allItems"
        v-model="selectedStudents" show-select
        item-key="id"
        :loading="tableLoading"
        loading-text="Loading..."
        :server-items-length="totalNumberOfItems"
        :items-per-page="perPage"
        :page="tablePagination.current_page"
        :sort-desc.sync="sortDesc"
        @update:page="fetchTableDataDebounced"
        @update:sort-by="handleSortColumn"
        @update:sort-desc="handleSortDirection"
      >

        <template v-slot:item.venture_90_day_goal="{ item }">
          {{ translateCurrentGoal(item.venture_90_day_goal) }}
        </template>
        <template v-slot:item.gm_pct_workshops_attended="{ item }">
          {{ toPercentage(item.gm_pct_workshops_attended) }}
        </template>
        <template v-slot:item.mentor_step_review_status="{ item }">
          <div style="display: flex; justify-content: center" v-html="toReviewStatusHtml(item.mentor_step_review_status)"></div>
        </template>
        <template v-slot:item.funding_step_review_status="{ item }">
          <div style="display: flex; justify-content: center" v-html="toReviewStatusHtml(item.funding_step_review_status)"></div>
        </template>
        <template v-slot:item.partnership_step_review_status="{ item }">
          <div style="display: flex; justify-content: center" v-html="toReviewStatusHtml(item.partnership_step_review_status)"></div>
        </template>
        <template v-slot:item.invoice_step_review_status="{ item }">
          <div style="display: flex; justify-content: center" v-html="toReviewStatusHtml(item.invoice_step_review_status)"></div>
        </template>
        <template v-slot:item.revenue_step_review_status="{ item }">
          <div style="display: flex; justify-content: center" v-html="toReviewStatusHtml(item.revenue_step_review_status)"></div>
        </template>
        <template v-slot:item.reviewed_at="{ item }">
          {{ formatDate(item.reviewed_at) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <ActionModalDropdown
            :user="item.student_id"
            :groupMembershipId="item.student_group_membership_id"
            :groupId="item.group_id"
            :triage-request-ids="[item.id]"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from '@dataServices/axios'
import { debounce } from 'lodash-es'
import FlashMessages from '@services/FlashMessageService';
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import { VDataTable } from 'vuetify/lib'
import BulkActionModalDropdown from "@components/BulkActionModalDropdown.vue";
import {WindowVariables} from "@utils";
import ModalService from "@services/ModalService";
import dayjs from "@services/dayjsWrapper";
import EventBus from "@services/GlobalEventBus";
import BulkActionModalDropdownNew from "@components/BulkActionModalDropdownNew.vue";

const baseUrl = window.location.origin

const tableHeaders = [
  { text: 'Actions', value: 'actions', sortable: false },
  { text: 'Student Name', value: 'student_full_name', sortable: true },
  { text: 'Student Phase', value: 'student_current_phase_name', sortable: true },
  { text: 'Current Coach', value: 'student_current_coach_name', sortable: true },
  { text: 'LTR', value: 'how_likely_to_recommend', sortable: true },
  { text: 'Current Goal', value: 'venture_90_day_goal', sortable: true },
  { text: '% Workshops Attended', value: 'gm_pct_workshops_attended', sortable: true },
  { text: 'Have Acc. Partner', value: 'venture_have_accountability_partner', sortable: true },
  { text: 'Contacts in Phase', value: 'user_contacts_made_in_phase', sortable: true },
  { text: 'Skills Needed Advisors', value: 'what_skills_advisor_team', sortable: true },
  { text: 'Have Mentor', value: 'have_mentor', sortable: true },
  { text: '(SM) Business Mentor', value: 'mentor_step_review_status', sortable: true },
  { text: '(SM) Received Funding', value: 'funding_step_review_status', sortable: true },
  { text: '(SM) Strategic Partnerships', value: 'partnership_step_review_status', sortable: true },
  { text: '(SM) Received Intent', value: 'invoice_step_review_status', sortable: true },
  { text: '(SM) Earned Revenue', value: 'revenue_step_review_status', sortable: true },
  { text: 'Earned Revenue Amount', value: 'revenue_step_total', sortable: true },
  { text: 'Proto. Meet. Count', value: 'prototype_feedback_meetings_count', sortable: true },
  { text: 'Proto. Meet. Score', value: 'prototype_feedback_score', sortable: true },
  { text: 'Reviewed At', value: 'reviewed_at', sortable: true },
  // { text: 'Discarded At', value: 'discarded_at', sortable: true },
  { text: 'Opened Tasks', value: 'num_open_tasks', sortable: true },
]

export default {
  name: 'assigned-students-table',
  components: {
    BulkActionModalDropdownNew,
    ActionModalDropdown,
    Multiselect,
    VDataTable,
    BulkActionModalDropdown,
  },
  props: {

  },
  data () {
    return {
      tableHeaders,
      allItems: [],
      selectedStudents: [],
      tablePagination: {
        total: 0,
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        total_pages: 0,
        is_first_page: true,
        is_last_page: true,
        is_out_of_range: false,
        records_per_page: 0
      },
      tableLoading: false,
      tableFilter: {
        student: null,
        studentPhase: null,
        studentCurrentCoach: null,
        currentGoal: null,
        haveAccountabilityPartner: null,
        haveMentor: null,
        businessMentorCompleted: null,
        receivedFundingCompleted: null,
        strategicPartnershipsCompleted: null,
        receivedIntentCompleted: null,
        earnedRevenueCompleted: null,
        reviewed: null,
      },
      filterOptions: {
        phases: [],
        coaches: [],
        currentGoal: [],
        haveAccountabilityPartner: [],
        haveMentor: [],
        businessMentorCompleted: [],
        receivedFundingCompleted: [],
        strategicPartnershipsCompleted: [],
        receivedIntentCompleted: [],
        earnedRevenueCompleted: [],
        reviewed: [],
      },
      completedItems: [],
      isLoading: false,
      perPage: 10,
      sortColumn: ['student_full_name'],
      sortDesc: [false],
      unsubscribeListenerFunctions: [],
    }
  },
  mounted(){
    this.initFilters();
    this.fetchTableDataDebounced(1);
    this.unsubscribeListenerFunctions = [
      EventBus.subscribe('triage-engagement-request-modal', (data) => this.handleTriageReviewEvent(data))
    ]
  },
  async beforeDestroy () {
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
  },
  computed: {
    currentUserId(){
      return WindowVariables.currentUser.id;
    },
    shouldDisableManualEngagementButton (){
      return this.selectedStudents.length === 0;
    },
    totalNumberOfItems () {
      return this.tablePagination && this.tablePagination.total?
             this.tablePagination.total:
             0
    },
    getSortBy(){
      return Array.isArray(this.sortColumn) && this.sortColumn.length > 0 ?
          this.sortColumn[0] :
          null;
    },
    getSortDir(){
      const sortDescFirstElement = Array.isArray(this.sortDesc) && this.sortDesc.length > 0 ? this.sortDesc[0] : null;
      return sortDescFirstElement === true ? 'desc' :
             sortDescFirstElement === false ? 'asc' :
             null;
    },
  },
  methods: {
    toPercentage(value){
      return value != null ?
             `${Math.round(value)}%`:
              null;
    },
    handleTriageReviewEvent(data){
      console.info("Received triage review event:", data)
      if (data.type === 'closed' && data.updated) {
        this.fetchTableDataDebounced(1);
      }
    },
    formatDate(dateString) {
      return dateString ?
              dayjs(dateString).tz(WindowVariables.timezone).format('M/D/YY'):
              null;
    },
    async initFilters(){
      this.filterOptions.coaches = await this.fetchCoachesOptions();
      this.filterOptions.phases = this.getHardcoredPhasesOptions(); //TODO: create API to fetch phases names
      this.filterOptions.currentGoal = this.getHardcodedCurrentGoalsOptions() //TODO: create API to fetch possible goals
      this.filterOptions.haveAccountabilityPartner = this.generateTrueFalseOptions()
      this.filterOptions.haveMentor = this.generateTrueFalseOptions()
      this.filterOptions.businessMentorCompleted = this.generateSuccessMarkerReviewStatusOptions()
      this.filterOptions.receivedFundingCompleted = this.generateSuccessMarkerReviewStatusOptions()
      this.filterOptions.strategicPartnershipsCompleted = this.generateSuccessMarkerReviewStatusOptions()
      this.filterOptions.receivedIntentCompleted = this.generateSuccessMarkerReviewStatusOptions()
      this.filterOptions.earnedRevenueCompleted = this.generateSuccessMarkerReviewStatusOptions()
      this.filterOptions.reviewed = this.generateTrueFalseOptions()

    },
    async fetchCoachesOptions () {
      try {
        const response = await axios.get(baseUrl + '/api/coaching/task_management/coaches', {params: {per_page: 100}}) //defining a high perPage number for now. If necessary in the future, implement recursive fetching later to fetch everyone
        return response.data.records.map(coach => ({id: coach.id, name: coach.name}));
      } catch (error) {
        console.error("Failed to fetch coaches:", error);
        FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    fetchTableDataDebounced: debounce(function(pageToLoad) { this.fetchTableData(pageToLoad) }, 250),
    fetchTableData (pageToLoad = 1) {
      this.tableLoading = true
      const request = this.toRequest(pageToLoad)

      return axios
            .get(baseUrl + '/api/coaching/dashboard/triage_students', {params: request})
            .then(response => this.processResponse(response))
            .catch(error => this.processResponseError(request, error))
    },
    toRequest(pageToLoad = 1) {
      return {
        search_term: this.tableFilter.student,
        student_current_phase_name: this.tableFilter.studentPhase?.name,
        student_current_coach_id: this.tableFilter.studentCurrentCoach?.id,
        current_goal: this.tableFilter.currentGoal?.value,
        have_accountability_partner: this.tableFilter.haveAccountabilityPartner?.value,
        have_mentor: this.tableFilter.haveMentor?.value,
        business_mentor_completed: this.tableFilter.businessMentorCompleted?.value,
        received_funding_completed: this.tableFilter.receivedFundingCompleted?.value,
        strategic_partnerships_completed: this.tableFilter.strategicPartnershipsCompleted?.value,
        received_intent_completed: this.tableFilter.receivedIntentCompleted?.value,
        earned_revenue_completed: this.tableFilter.earnedRevenueCompleted?.value,
        program_ids: [20,26], //TODO: add a filter for programs
        discarded: false, //may add as a filter in the future
        reviewed: this.tableFilter.reviewed?.value,
        // has_manual_engagements: this.tableFilter.hasManualEngagements?.value,
        page: pageToLoad,
        per_page: this.perPage,
        sort_by: this.sortColumn,
        sort_direction: this.getSortDir,
      }
    },
    processResponse (response) {
      this.allItems = response.data.records
      this.tablePagination = response.data.pagination
      this.tableLoading = false
    },
    processResponseError (request, error) {
      this.tableLoading = false
      console.error("Failed to fetch table data:", request, error);
      FlashMessages.error("Failed to fetch table data! " + error); //it's ok to show the real error because the coaches are the users
    },
    handleSortColumn(column) {
      this.sortColumn = column;
      console.info("Sorting by column:", column, this.getSortBy)
      this.fetchTableDataDebounced(1);
    },
    handleSortDirection(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchTableDataDebounced(1);
    },
    toReviewStatusHtml(backendReviewStatus) {
      switch(backendReviewStatus){
        case 0: return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" class="size-6"><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" /></svg>`;
        case 1: return `<svg viewBox="0,0,100,100" width="20" height="20"><g fill="#168c69" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(2,2)"><path d="M43.171,10.925l-19.086,22.521l-9.667,-9.015l1.363,-1.463l8.134,7.585l17.946,-21.175c-4.204,-4.534 -10.205,-7.378 -16.861,-7.378c-12.683,0 -23,10.317 -23,23c0,12.683 10.317,23 23,23c12.683,0 23,-10.317 23,-23c0,-5.299 -1.806,-10.182 -4.829,-14.075z"/></g></g></svg>`;
        case 2: return "UR";
        case null:
        case undefined:
        default: return "N/A";
      }
    },
    getHardcoredPhasesOptions(){
      return [
        { id: 1, name: 'Getting Started'},
        { id: 2, name: 'Phase 1'},
        { id: 3, name: 'Phase 2'},
        { id: 4, name: 'Phase 3'},
        { id: 5, name: 'Phase 4'},
        { id: 6, name: 'Phase 5'},
        { id: 7, name: 'Phase 6'},
        { id: 8, name: 'Phase 7'},
      ]
    },
    getHardcodedCurrentGoalsOptions(){
      return [
        { id: 0, value: 'acquire-funding', name: 'Acquire funding or resources necessary to go to market'},
        { id: 1, value: 'add-accountability-partner', name: 'Add an accountability partner'},
        { id: 2, value: 'add-non-paid-advisors', name: 'Add non-paid advisors to my team'},
        { id: 3, value: 'add-strategic-partners', name: 'Add strategic partners to my team'},
        { id: 4, value: 'find-mentor', name: 'Find a business mentor or advisor'},
        { id: 5, value: 'get-my-first-client-or-donor', name: 'Get my first client sale or donor donation'},
        { id: 6, value: 'get-unstuck', name: 'Get unstuck'},
        { id: 7, value: 'grow-my-venture', name: "Grow my venture now that I've made my first sale"},
        { id: 8, value: 'have-more-curiosity-conversations', name: 'Have more curiosity conversations'},
        { id: 9, value: 'pivot-or-change', name: 'Pivot or change my idea'},
        { id: 10, value: 'other', name: 'Other'}
      ]
    },
    translateCurrentGoal(goalValue){
      const foundGoal = this.getHardcodedCurrentGoalsOptions().find(g => g.value === goalValue);
      return foundGoal ? foundGoal.name : goalValue;
    },
    generateTrueFalseOptions(){
      return [
        { id: 1, name: 'True', value: true},
        { id: 0, name: 'False', value: false},
      ]
    },
    generateSuccessMarkerReviewStatusOptions(){
      return [
        { id: 0, name: 'Approved', value: 1},
        { id: 1, name: 'Questions Remaining', value: 0},
        { id: 2, name: 'Under Review', value: 2},
        { id: 3, name: 'N/A', value: -1}, //hack to allow the user to filter by no review status
      ]
    },
  },
  watch: {
    tableFilter: {
      deep: true,
      handler(newVal, oldVal) {
        this.fetchTableDataDebounced(1);
      },
    }
  },
}

</script>

<style lang="scss">
  .table-filter-label {
    display: block;
    font-size: 15px;
  }

  .table-filter-row {
    margin: 15px 0 15px 0;
  }
</style>
