<template>
  <div class="upcoming-meeting-container justify-center border-[1px] rounded-lg !border-[#2F80ED] text-[#1367D8] p-[0.75rem] bg-[#DEECFC] mx-auto max-w-[22rem] flex flex-column h-full" :class="{ hidden: !meetingToPresent, about_to_start: isMeetingAboutToStart }">
    <template v-if="isMeetingInSession">
      <!-- there is a meeting in session now! -->
      <div class="animate-text bg-gradient-to-r from-indigo-500 via-teal-500 to-orange-500 bg-clip-text font-bold text-htss-purple text-transparent">{{$t('application.common.in_progress')}}</div>
      <div class="text-left mb-1">  <span class="font-semibold ml-4">{{ meetingToPresent.name }}</span><span class=""> @ {{formattedMeetingStartTime}}</span>  </div>
      <a class="ml-4 mb-0 text-white px-[0.75rem] py-2 bg-[#1367D8] rounded-sm text-center" :href="`/new_meetings/${meetingToPresent.id}/join`">{{ $t('application.common.join_meeting') }}</a>
    </template>

    <template v-else-if="meetingToPresent && isMeetingAboutToStart">
    <!-- a meeting is about to start (within an hour) -->

      <div class="mb-0">
        <div class="block font-semibold pb-1 text-left">
          <span class="pr-1 fa fa-calendar"></span>
          <span class="">{{ meetingToPresent.name }}</span>
        </div>
        <div class="mb-1 text-left animate-text bg-gradient-to-r from-indigo-500 via-teal-500 to-orange-500 bg-clip-text font-bold text-htss-purple text-transparent">{{ $t('application.common.starts_in') }} {{countdownText}}</div>
        <a class="block mb-0 text-white px-[0.75rem] py-2 bg-[#1367D8] rounded-sm text-center" :href="`/new_meetings/${meetingToPresent.id}/join`">{{ $t('application.common.join_meeting') }}</a>
      </div>
    </template>

    <template v-else-if="meetingToPresent && isMeetingStartingInNextTwentyFourHours && !isMeetingAboutToStart">
      <!-- There is a meeting to present, within 24hrs, but not within an hour -->

      <div class="mb-0 ">
        <div class="block font-semibold pb-1 text-left">
          <span class="pr-1 fa fa-calendar"></span>
          <span class="">{{ meetingToPresent.name }}</span>
        </div>
        <div class="text-left">
          <span class="animate-text bg-gradient-to-r from-indigo-500 via-teal-500 to-orange-500 bg-clip-text font-bold text-htss-purple text-transparent">{{ $t('application.common.starts_in') }} {{countdownText}}</span><span class=""> @ {{formattedMeetingStartTime}}</span>
        </div>
      </div>
    </template>

    <template v-else-if="meetingToPresent && !isMeetingAboutToStart">
      <!-- There is a meeting to present, but it's not within an hour -->

      <div class="text-left">
        <div class="block pb-1 underline">{{ $t('application.common.next_event') }}</div>
        <div class="block font-semibold pb-1">
          <span class="pr-1 fa fa-calendar"></span>
          <span class="">{{ meetingToPresent.name }}</span>
        </div>
        <div class="">{{ formattedMeetingStartTime }}</div>
      </div>
    </template>

  </div>
</template>

<script>
  import { WindowVariables } from '@utils'

  import dayjs from '@services/dayjsWrapper'

  function secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60))
    const divisor_for_minutes = secs % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)
    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)

    return {
      hours,
      minutes,
      seconds
    }
  }

  export default {
    data () {
      return {
        // these are ordered start_time ASC already
        upcomingMeetings: WindowVariables.upcomingMeetings,
        currentUserId: WindowVariables.userId,
        secondsUntilMeetingToPresent: null,
        tz: WindowVariables.timezone,
      }
    },
    computed: {
      formattedMeetingStartTime () {
        if (this.meetingToPresent) {
          return dayjs(this.meetingToPresent.start_time).tz(this.tz).format('ddd, MMMM D - h:mm a')
        }
      },
      meetingToPresent () {
        let m = this.upcomingMeetings.find(m => {
          const startMoment = dayjs(m.start_time).tz(this.tz)
          const endMoment = dayjs(startMoment).add(1, 'hours')
          const now = dayjs()

          return endMoment.isAfter(now)
        })

        // for testing
        // m.start_time = "2022-07-11T24:00:00.000Z"

        return m
      },
      isMeetingInSession () {
        if (this.meetingToPresent) {
          const startMoment = dayjs(this.meetingToPresent.start_time).tz(this.tz)
          const endMoment = dayjs(startMoment).add(1, 'hours')
          const now = dayjs()
          return startMoment.isBefore(now) && endMoment.isAfter(now)
        }
      },
      isMeetingAboutToStart () {
        if (this.meetingToPresent) {
          const startMoment = dayjs(this.meetingToPresent.start_time).tz(this.tz)
          const hourBefore = dayjs(startMoment).subtract(1, 'hours')
          const now = dayjs()
          return now.isAfter(hourBefore) && !this.isMeetingInSession
        }
      },
      isMeetingStartingInNextTwentyFourHours () {
         if (this.meetingToPresent) {
          const startMoment = dayjs(this.meetingToPresent.start_time).tz(this.tz)
          const hourBefore = dayjs(startMoment).subtract(24, 'hours')
          const now = dayjs()
          return now.isAfter(hourBefore) && !this.isMeetingInSession
        }
      },
      countdownText () {
        if (this.secondsUntilMeetingToPresent > 0) {
          const res = secondsToTime(this.secondsUntilMeetingToPresent)
          if (this.secondsUntilMeetingToPresent < (60 * 60)) {
            return `${res.minutes}m ${res.seconds}s`
          } else if (this.secondsUntilMeetingToPresent < (60 * 60 * 24)) {
            return `${res.hours}h ${res.minutes}m`
          } else {
            return 'here min' + this.secondsUntilMeetingToPresent / 60
          }
        }
      }
    },
    created () {
      setInterval(() => {
        if (this.meetingToPresent) {
          this.secondsUntilMeetingToPresent = dayjs(this.meetingToPresent.start_time).diff(dayjs(), 'seconds')
        }
      }, 1000)
    }
  }

</script>

<style lang="scss">

.upcoming-meeting-container {
  box-shadow: 2px 2px 0px 0px #2F80ED;

}


</style>