<template>
  <div @click="openModal">{{ text }}</div>
</template>

<script>
import ModalService from '@services/ModalService'

export default {
  name: 'open-modal-button',
  props: {
    modalName: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openModal () {
      ModalService.open(this.modalName, this.params)
    },
  },
}
</script>
