<template>
  <div class="coach-action-table">
    <div class="configuration flex flex-row">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Program</label>
        <multiselect
          id="program-dropdown"
          v-model="tableFilter.program"
          track-by="value"
          label="label"
          :options="filterOptions.programs">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Student</label>
        <input
          id="student-filter"
          type="text"
          placeholder="Filter students"
          v-model="tableFilter.student"
          class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out"
        />
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Course</label>
        <multiselect
          id="course-dropdown"
          v-model="tableFilter.courses"
          track-by="id"
          label="name"
          :multiple="true"
          :options="filterOptions.courses">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="group-dropdown" class="block">Group</label>
        <multiselect
          id="group-dropdown"
          v-model="tableFilter.group"
          track-by="id"
          label="name"
          :options="filterOptions.groups">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="phase-dropdown" class="block">Phase</label>
        <multiselect
          id="phase-dropdown"
          v-model="tableFilter.phase"
          track-by="value"
          label="name"
          :options="filterOptions.phases">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs" v-if="showCoachFilter">
        <label for="coach-dropdown" class="block">Coach</label>
        <multiselect
          id="coach-dropdown"
          v-model="tableFilter.coach"
          track-by="id"
          label="name"
          :options="filterOptions.coaches">
        </multiselect>
      </div>
    </div>
    <div class="table-wrapper">
      <v-data-table
        :headers="tableHeaders"
        :items="tableData[currentPage]"
        item-key="uid"
        :server-items-length="serverItemsLength"
        :items-per-page="perPage"
        :loading="tableLoading"
        loading-text="Loading..."
        :disable-filtering="false"
        :page="currentPage"
        :must-sort="true"
        :sort-by="sortColumn"
        :sort-desc="sortDir === 'desc'"
        @update:page="changePage"
        @click:row="openModal"
        @update:sort-by="handleSortColumn"
        @update:sort-desc="handleSortDirection">
        <template v-slot:item.course_step_name="{ item }">
          <i :class="{fa: true, [item.faIcon]: true}" class="pr-2" />
          {{ item.course_step_name }}
        </template>
        <template v-slot:item.time_waiting_clock="{ item }">
          {{ item.absoluteDaysWaiting }}
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionModalDropdown
            :user="item.user_id"
            :groupMembershipId="item.group_membership_id"
            :groupId="item.group_id"
            :courseStepId="item.courseStepId"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import dayjs from '@services/dayjsWrapper'
import axios from '@dataServices/axios'
import { debounce } from 'lodash-es'

import TimelineService from '@services/timelineService/Service'
import ModalService from '@services/ModalService'
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import EventBus from '@services/GlobalEventBus'
import { WindowVariables } from '@utils'

import { VDataTable } from 'vuetify/lib'

const tableHeaders = [
  { text: 'Student Name', value: 'student_name', sortable: false },
  { text: 'Group', value: 'group_name', sortable: false },
  { text: 'Coach', value: 'coach_name', sortable: false },
  { text: 'Phase', value: 'sequence_name', sortable: false },
  { text: 'Step Name', value: 'course_step_name', sortable: false },
  { text: 'Days Since Reviewed', value: 'time_waiting_clock', sortable: false },
  { text: 'Actions', value: 'actions', sortable: false },
]

class CoachActionRow {
  constructor (data) {
    Object.assign(this, data)
    this.waiting_since = this.waiting_since && dayjs(this.waiting_since)
    this.completed = false
  }

  get isUnderReview () {
    return parseInt(this.course_step_review_status) === 2
  }

  get isCompleted () {
    return this.completed
  }

  get waitingSince () {
    return this.waiting_since
  }

  get absoluteDaysWaiting () {
    return this.waiting_for_absolute_hours && (this.waiting_for_absolute_hours / 24.0).toFixed(1)
  }

  get isStep () {
    return this.course_step_type === 'CourseStep::Step'
  }

  get isBenchmark () {
    return this.course_step_type === 'CourseStep::Benchmark'
  }

  get isEvent () {
    return this.course_step_type === 'CourseStep::Event'
  }

  get faIcon () {
    if (this.isEvent) {
      return (
        'fa-calendar'
      )
    } else if (this.isBenchmark) {
      return 'fa-lock'
    } else if (this.isStep) {
      return 'fa-desktop'
    }
  }
}

export default {
  name: 'questions-remaining-table',
  components: {
    ActionModalDropdown,
    Multiselect,
    VDataTable,
  },
  props: {
    partnerId: { type: Number, required: true },
    courseId: { type: Number, required: false },
  },
  data () {
    return {
      activeTab: null,
      tableHeaders,
      tableData: {},
      tablePagination: null,
      tableLoading: true,
      currentPage: 1,
      tableFilter: {
        student: '',
        group: null,
        phase: null,
        coach: null,
        actionType: null,
        courses: null,
        program: null,
      },
      filterOptions: {
        groups: [],
        phases: [],
        coaches: [],
        actionTypes: [],
        courses: [],
        programs: [],
      },
      completedItems: [],
      isLoading: false,
      perPage: 15,
      sortColumn: 'time_waiting_clock',
      sortDir: 'desc',
    }
  },
  created () {
    this.requestDataDebounced = debounce(this.requestData, 250)
    this.requestData()
      .then(() => {
        // this.tableFilter.coach = this.filterOptions.coaches.find(c => c.id === WindowVariables.currentUser.id) || null
        this.$watch('tableFilter', () => {
          if (this.tableLoading) {
            this.requestDataDebounced(1, true)
          } else {
            this.requestData(1, true)
          }
        }, { deep: true, immediate: false })
      })

    const dereg = this.$watch('filterOptions.programs', (newVal, oldVal) => {
      if (oldVal.length === 0 && newVal.length > 1) {
        // disabling automatic program selection due to weird performance
        //   behavior when program and coach is selected at the same time
        // this.tableFilter.program = this.filterOptions.programs[0]
        dereg()
      }
    })

    EventBus.subscribe('interaction-timeline-modal-closing', (d) => {
      this.requestData(null, true)
    })
  },
  computed: {
    serverItemsLength () {
      return this.tablePagination && this.tablePagination.total
        ? this.tablePagination.total
        : null
    },
    showCoachFilter () {
      return this.filterOptions.coaches && this.filterOptions.coaches.length > 0
    },
  },
  methods: {
    openModal (item) {
      ModalService.open('interaction-timeline-modal', {
        groupId: item.group_id,
        userId: item.user_id,
        courseStepId: item.course_step_id,
        hideNavigation: true,
      })
    },
    handleSortColumn (sort) {
      this.handleSort(sort, this.sortDir)
    },
    handleSortDirection (isDesc) {
      this.handleSort(this.sortColumn, isDesc ? 'desc' : 'asc')
    },
    handleSort (sort, dir) {
      if (sort && this.sortColumn !== sort || this.sortDir !== dir) {
        this.sortColumn = sort
        this.sortDir = dir
        this.requestData(1, true)
      }
    },
    changePage (opts) {
      this.requestData(opts)
    },
    // this.requestData() - Load the same current page, but only if not cached
    // this.requestData(1) - Load page 1, but only if not cached
    // this.requestData(1, true) - Load page 1, override the cache
    // this.requestData(null, true) - Load the same current page, override the cache
    // Note: There is no timeout on the cache currently.
    requestData (pageToLoad, force = false) {
      let pageRequesting = pageToLoad || this.currentPage
      if (!force && this.tableData[pageRequesting]) {
        this.currentPage = pageRequesting
        return true
      } else if (force) {
        this.tableData = {}
      }
      this.tableLoading = true

      // For the 'time_waiting' field, the sort direction needs to be flipped.
      // The time waiting column is represented as "Time Waiting" in the table, but
      // in the query it is actually "Time Since".
      // So a DESC sort by "Time Waiting" is actually an ASC sort by "Time Since".
      let sortColumn = this.sortColumn
      let flipSort = tableHeaders.find(h => h.value === sortColumn).flipSort
      let sortDirection = flipSort
        ? (this.sortDir === 'desc' ? 'asc' : 'desc')
        : this.sortDir

      return axios.get('/queries/questions_remaining', {
        params: {
          partner_id: this.partnerId,
          program_id: (this.tableFilter.program && this.tableFilter.program.value) || null,
          course_ids: (this.tableFilter.courses && this.tableFilter.courses.map(c => c.id)) || null,
          page: pageRequesting,
          student_name: (this.tableFilter.student && this.tableFilter.student.length > 0) ? this.tableFilter.student : null,
          group_id: (this.tableFilter.group && this.tableFilter.group.id) || null,
          phase_name: (this.tableFilter.phase && this.tableFilter.phase.value) || null,
          coach_id: (this.tableFilter.coach && this.tableFilter.coach.id) || null,
          action_type: (this.tableFilter.actionType && this.tableFilter.actionType.value) || null,
          per_page: this.perPage,
          sort: `${sortColumn}|${sortDirection}`,
        },
      }).then(resp => {
        this.tableData[pageRequesting] = resp.data.records.map(r => (new CoachActionRow(r)))
        this.tablePagination = resp.data.pagination
        this.tableLoading = false
        this.currentPage = pageRequesting

        this.filterOptions.programs = (resp.data.filters && resp.data.filters.programs) || []
        this.filterOptions.groups = (resp.data.filters && resp.data.filters.groups) || []
        this.filterOptions.phases = (resp.data.filters && resp.data.filters.phases) || []
        this.filterOptions.coaches = (resp.data.filters && resp.data.filters.coaches) || []
        this.filterOptions.courses = (resp.data.filters && resp.data.filters.courses) || []
        this.filterOptions.actionTypes = (resp.data.filters && resp.data.filters.action_types) || []

        if (!this.tableFilter.actionType) {
          this.tableFilter.actionType = this.filterOptions.actionTypes[0]
        }
      })
    },
  },
}
</script>

<style lang="scss">
.coach-action-table .v-data-footer__select {
  display: none;
}

.coach-action-table .v-window {
  overflow: initial;
}

.coach-action-table .v-data-table__wrapper {
  overflow: initial;
}
</style>
