import axios from './axios'

const baseUrlString = '/course_step_feedbacks'

const CourseStepFeedbacks = {
  create: async function(groupId, courseStepId, courseId, message, iHelpfulness) {
    return axios.post(baseUrlString, {
      course_step_feedback: {
        group_id: groupId,
        course_step_id: courseStepId,
        course_id: courseId,
        message: message || null,
        i_helpfulness: iHelpfulness
      }
    })
        .then(function (response) {
          return response.data
        }).catch(function (error) {
          console.error('Error', error.message)
        })
  }
}

export default CourseStepFeedbacks
