<template>
  <div class="coaches-dashboard">
    <v-tabs v-model="activeTab">
      <v-tabs-slider color="purple"></v-tabs-slider>
      <v-tab v-for="tab in ['Actions', 'Questions Remaining', 'Tasks', 'Launchers', 'Assigned Launchers', 'Triage Launchers']" :key="tab" active-class="text-htss-purple">{{ tab }}</v-tab>
      <v-tabs-items v-model="activeTab" class="pt-3">
        <v-tab-item key="Actions" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div class="px-4 py-4 sm:px-6 text-xl">Available Coach Actions</div>
            <div class="px-4 py-4 sm:p-6">
              <CoachActionTable :partnerId="partnerId" :courseId="courseId" />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="Questions Remaining" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div class="px-4 py-4 sm:px-6 text-xl">Questions Remaining</div>
            <div class="px-4 py-4 sm:p-6">
              <QuestionsRemainingTable :partnerId="partnerId" :courseId="courseId" />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="Tasks" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg">
            <div class="px-4 sm:p-6">
              <TasksTable :partnerId="partnerId" :courseId="courseId" />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="Launchers" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div class="px-4 py-4 sm:px-6 text-xl">Launchers Time in Phase</div>
            <div class="px-4 py-4 sm:p-6">
              <LaunchersTab />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="Assigned Launchers" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div class="px-4 py-4 sm:px-6 text-xl">Assigned Launchers</div>
            <div class="px-4 py-4 sm:p-6">
              <AssignedStudentsTab />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="Triage Launchers" :transition="false">
          <div class="border-2 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div class="px-4 py-4 sm:px-6 text-xl">Triage Launchers</div>
            <div class="px-4 py-4 sm:p-6">
              <TriageStudentsTab />
            </div>
          </div>
        </v-tab-item>

      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import CoachActionTable from '@components/CoachActionTable.vue'
import LaunchersTab from '@components/coachesDashboard/LaunchersTab.vue'
import AssignedStudentsTab from '@components/coachesDashboard/AssignedStudentsTab.vue'
import TriageStudentsTab from '@components/coachesDashboard/TriageStudentsTab.vue'
import TasksTable from '@components/tasks/TasksTable.vue'
import QuestionsRemainingTable from '@components/QuestionsRemainingTable.vue'

import { VTab, VTabItem, VTabsItems, VTabs, VTabsSlider } from 'vuetify/lib'

export default {
  name: 'coach-action-table',
  components: {
    CoachActionTable,
    LaunchersTab,
    AssignedStudentsTab,
    TriageStudentsTab,
    TasksTable,
    QuestionsRemainingTable,
    VTab,
    VTabItem,
    VTabsItems,
    VTabs,
    VTabsSlider,
  },
  props: {
    partnerId: { type: Number, required: true },
    courseId: { type: Number, required: false },
  },
  data () {
    return {
      activeTab: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.v-tabs {
  ::v-deep {
    .v-tabs-slider-wrapper {
      transition: none;
    }
  }
}

.coaches-dashboard .v-window {
  overflow: initial !important;
}
</style>
