<template>
  <div class="sidebar-button-wrapper" :class="buttonStyleClasses" @click="goToDirectMessagesPage">
    <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
    </svg>
    <span :class="spanStyleClasses">Find & Connect</span>
    <span class="unread-messages-count" :class="{ 'hidden-occupy-space': !shouldShowUnreadMessagesCount }" style="margin-right: 8px" aria-label="{{ totalMessagesUnreadCount }} total unread messages">
        {{ totalMessagesUnreadCount }}
    </span>
  </div>
</template>

<script>

import { WindowVariables } from '@utils'
import axios from '@dataServices/axios'
import DirectMessagingPanel from "./DirectMessagingPanel.vue";
import DirectMessagingOptedOutPanel from "./DirectMessagingOptedOutPanel.vue";
import GetStreamClient from "@services/directMessaging/getStreamClient";
import User from "@services/directMessaging/user";
import Vue from "vue";
import DirectMessagingSidebar from "@components/directMessaging/DirectMessagingSidebar.vue";
import {debounce} from "lodash-es";

const baseUrl = window.location.origin

const directMessagingRoute = "direct_messages";

export default {
  name: 'direct-messaging',
  components: {
    DirectMessagingPanel,
  },
  props: {
    buttonStyleClasses: { type: String, default: '' },
    spanStyleClasses: { type: String, default: '' },
  },
  data: () => ({
    currentUser: null,
    getStreamClient: null,

    unsubscribeListenerFunctions: [],
    retryAbortController: new AbortController(),
    isConnected: false,
    connectedAtLeastOnce: false, //if connected at least once into getstream, then their sdk takes over the reconnection logic, so no more "reconnect now" button on the alert
    totalMessagesUnreadCount: null,

    sidebarElement: null,
    panelElement: null,
    mainElement: null,

    sidebarVueComponent: null,
    panelVueComponent: null,
  }),
  mounted () {
    this.currentUser = new User(WindowVariables.currentUser.id, WindowVariables.currentUser.current_dm_user?.id, WindowVariables.currentUser.name, WindowVariables.currentUser.community_username, WindowVariables.currentUser.current_dm_user?.active)

    this.sidebarElement = document.getElementById('vue-sidenav');
    this.panelElement = document.getElementById('direct-messaging-panel-wrapper');
    this.mainElement = document.querySelector('main');

    if (this.shouldShowOptedOutPanel()) {
      this.mountOptedOutPanel(this.panelElement);
    }
    else {
      this.initiateConnection();
      if (this.isCurrentlyAtDirectMessagesPage) {
        this.mountDirectMessagingSidebar(this.sidebarElement)
        this.mountDirectMessagingPanel(this.panelElement);
      }
    }

    // this.getStreamClient.client.on(event => console.info('client.event:', event)); //FIXME: remove all console.info
    // this.getStreamClient.client.on("notification.added_to_channel", event => console.info('client.event:', event)); //FIXME: remove all console.info
  },
  async beforeDestroy () {
    this.retryAbortController.abort(new Error("Aborting current retry attempt and stopping the connection!"));
    await this.disconnect();
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
  },
  computed: {
    shouldShowUnreadMessagesCount() {
      return this.totalMessagesUnreadCount && this.totalMessagesUnreadCount > 0;
    },
    isCurrentlyAtDirectMessagesPage() {
      return this.getCurrentUrl().includes(directMessagingRoute);
    },
  },
  methods: {
    shouldShowOptedOutPanel(){
      const isStudent = WindowVariables.currentUser.is_student;
      const hasDmUser = WindowVariables.currentUser.current_dm_user;
      const isOptedOut = WindowVariables.currentUser.current_dm_user?.active === false;
      return isStudent && hasDmUser && isOptedOut;
    },
    async initiateConnection() {
      const fetchAuthTokenResponse = await this.fetchAuthToken();
      const getStreamApiKey = fetchAuthTokenResponse.data.api_key;
      const getStreamAuthToken = fetchAuthTokenResponse.data.token;

      await this.disconnect();
      this.getStreamClient = new GetStreamClient(getStreamApiKey)
      this.getStreamClient.startListeningToConnectionChanged(this.handleConnectionChangedEvent);

      const connectionResponse = await this.getStreamClient.connect(this.currentUser, getStreamAuthToken, this.getErrorHandlingOptions())
      this.totalMessagesUnreadCount = connectionResponse.me.total_unread_count;
    },
    async disconnect(){
      if (this.getStreamClient){
        this.getStreamClient.disconnect().catch(error => console.error('Error disconnecting from GetStream!', error));
      }
    },
    async reconnectNow(){
      this.retryAbortController.abort(new Error("Aborting current retry attempt and starting a new one!"));
      this.retryAbortController = new AbortController();
      await this.initiateConnection();
    },
    async handleConnectionChangedEvent(event) {
      if (event.online === true) {
        this.isConnected = true;
        this.connectedAtLeastOnce = true;
      }
      else {
        this.isConnected = false;
      }
    },
    getCurrentUrl(){
      return window.location.href;
    },
    goToDirectMessagesPage(){
      window.location.replace(`${baseUrl}/${directMessagingRoute}`)
    },
    mountOptedOutPanel(panelContainerElement) {
      const panelVueComponent = this.instantiateOptedOutPanelComponent();
      panelVueComponent.$on('destroyed', () => this.showMainElementScroll(true));
      panelVueComponent.$mount();
      this.clearContainerElement(panelContainerElement);
      this.showMainElementScroll(false);
      panelContainerElement.appendChild(panelVueComponent.$el);
      this.panelVueComponent = panelVueComponent;
    },
    mountDirectMessagingPanel(panelContainerElement) {
      // this.$router.push({ path: `/direct_messages` })
      const panelVueComponent = this.instantiatePanelComponent();
      panelVueComponent.$on('destroyed', () => this.showMainElementScroll(true));
      panelVueComponent.$mount();
      this.clearContainerElement(panelContainerElement);
      this.showMainElementScroll(false);
      panelContainerElement.appendChild(panelVueComponent.$el);
      this.panelVueComponent = panelVueComponent;
    },
    mountDirectMessagingSidebar(sidebarContainerElement) {
      const sidebarVueComponent = this.instantiateSidebarComponent();
      sidebarVueComponent.$on('destroyed', () => this.showVueSidebar(false));
      sidebarVueComponent.$mount();
      this.clearContainerElement(sidebarContainerElement);
      sidebarContainerElement.appendChild(sidebarVueComponent.$el);
      this.showVueSidebar(true);
      this.sidebarVueComponent = sidebarVueComponent;
    },
    instantiateOptedOutPanelComponent() {
      const ComponentClass = Vue.extend(DirectMessagingOptedOutPanel);
      return new ComponentClass({
        parent: this,
        propsData: {
          currentUser: this.currentUser,
        }
      });
    },
    instantiatePanelComponent() {
      const ComponentClass = Vue.extend(DirectMessagingPanel);
      return new ComponentClass({
        parent: this,
        propsData: {
          currentUser: this.currentUser,
          connected: this.isConnected,
        }
      });
    },
    instantiateSidebarComponent() {
      const ComponentClass = Vue.extend(DirectMessagingSidebar);
      return new ComponentClass({
        parent: this,
        propsData: {
          currentUser: this.currentUser,
          getStreamClient: this.getStreamClient,
          connected: this.isConnected,
          connectedAtLeastOnce: this.connectedAtLeastOnce,
          reconnectCountDown: this.reconnectCountDown,
          reconnectNowCallback: this.reconnectNow,
        }
      });
    },
    clearContainerElement(containerElement){
      while (containerElement.lastElementChild) {
        containerElement.removeChild(containerElement.lastElementChild);
      }
    },
    showVueSidebar(value) {
      if (value === true){
        this.sidebarElement.style.display = 'block';
      }
      else {
        this.sidebarElement.style.display = 'none';
      }
    },
    showMainElementScroll(value) { //this is a hacky way to hide the sidescroll of the main element as we do not use it on direct messaging and do not want a second scroll.
      if (value === true){
        this.mainElement.style.overflowY = 'auto';
      }
      else {
        this.mainElement.style.overflowY = 'hidden';
      }
    },
    async fetchAuthToken() {
      try {
        return await axios.post(baseUrl + `/api/direct_messages/authentication`)
      } catch (error) {
        this.processResponseError(error)
      }
    },
    processResponseError (error) {
      this.errorMessage = error.response? error.response.data.message : error.message;
      this.alertVisible = true;
      console.error('Backend Error:', this.currentUser, this.errorMessage, error.response?.data, error.response?.status);
    },
    getErrorHandlingOptions() {
      return {
        retries: Infinity,
        timeoutInMs: 2000,
        minTimeout: 1000,
        maxTimeout: 60000,
        factor: 2,
        signal: this.retryAbortController.signal
      }
    },
    updateTotalMessagesUnreadCountDebounced: debounce(function(newValue) { this.totalMessagesUnreadCount = newValue; }, 500),
  },
  watch: {
    'getStreamClient.connected'(newValue, oldValue) {
      this.isConnected = newValue;

      if (newValue === true && oldValue === false) {
        console.warn("Direct Messaging Connected!")
      }
      else if (newValue === false && oldValue === true) {
        console.warn("Direct Messaging Disconnected!")
      }
    },
    'getStreamClient.totalUnreadMessagesCount'(newValue, oldValue) {
      if (newValue !== undefined && newValue !== null){
        this.updateTotalMessagesUnreadCountDebounced(newValue); //so it does not blink on the UI by receiving multiple updates in a short period of time
      }
    },
    'getStreamClient.reconnectCountDown'(newValue, oldValue) { //TODO: Because this component mounts its children (sidebarVueComponent and panelVueComponent) outside of its hierarchy their props are not reactive by default. This is a hacky way to make them be reactive. Improve this in the future using Vuex, Mobx, Composition Api, etc
      if (this.sidebarVueComponent){
        this.sidebarVueComponent.reconnectCountDown = newValue;
      }
    },
    getStreamClient(newValue, oldValue) {
      if (this.sidebarVueComponent){
        this.sidebarVueComponent.getStreamClient = newValue;
      }
    },
    currentUser(newValue, oldValue) {
      if (this.sidebarVueComponent){
        this.sidebarVueComponent.currentUser = newValue;
      }

      if (this.panelVueComponent){
        this.panelVueComponent.currentUser = newValue;
      }
    },
    isConnected(newValue, oldValue) {
      if (this.sidebarVueComponent){
        this.sidebarVueComponent.connected = newValue;
      }

      if (this.panelVueComponent){
        this.panelVueComponent.connected = newValue;
      }
    },
    connectedAtLeastOnce(newValue, oldValue) {
      if (this.sidebarVueComponent){
        this.sidebarVueComponent.connectedAtLeastOnce = newValue;
      }
    },
  },
}

</script>

<style lang="scss" scoped>


</style>
