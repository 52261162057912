<template>
  <div class="chart-container">
    <div class="chart-inner-container">
      <div id="histogram-for-student-completion" ref="histogram-for-student-completion" align="center"></div>
    </div>
  </div>
</template>

<script>
import C3 from 'c3/c3.esm'
import { WindowVariables } from '@utils'
import { chain } from 'lodash-es'
import {getUsersStoreFor} from '@stores/UsersStore'

export default {
  fromMobx: {
    users() {
      return this.usersStore.allRecords
    }
  },
  data() {
    return {
      groupedData: {},
      group: WindowVariables.group,
      usersStore: getUsersStoreFor({
        group_id: WindowVariables.groupId,
        state: 1,
      }),
    }
  },
  methods: {
    initialize() {
      const data = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90]
        .map(key => (this.groupedData[key] && this.groupedData[key].length) || 0)

      var histogramForStudentCompletion = C3.generate({
        title: {
          text: `${this.group.studentDisplayName} Completion Histogram`,
        },
        bindto: this.$refs['histogram-for-student-completion'],
        size: {
          height: 380,
          width: 555,
        },
        padding: {
          right: 40,
          bottom: 20,
        },
        data: {
          columns: [
            ['# of students', ...data],
          ],
          type: 'bar',
          groups: [
            ['# of students']
          ],
        },
        bar: {
          width: {
            ratio: 0.8,
          },
        },
        axis: {
          x: {
            label: {
              text: 'Percent Completed',
              position: 'outer-center',
            },
            type: 'category',
            categories: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],

            tick: {
              multiline: true,
              multilineMax: 2,
              centered: true,
            },
          },
          y: {
            label: {
              text: `# of ${this.group.studentDisplayName}s`,
              position: 'outer-center',
            },
            tick: {
              format: function(x) {
                return (x == Math.floor(x)) ? x : "";
              }
            },
          },
        },
        legend: { show: false },
        tooltip: {
          grouped: false,
          format: {
            title: function (d) { return undefined },
            // value: function (val, ratio, id) { return val },
          }
        },
        grid : {
          y: {
            lines: [{value:0}]
          }
        }

      })

      setInterval(() => {
        const e = $(this.$refs['histogram-for-student-completion']).find('.c3-axis.c3-axis-x')
        const transform = e.attr('transform')
        e.attr('transform', transform.replace('0,', '25,'))
        $(this.$refs['histogram-for-student-completion']).find('.c3-axis.c3-axis-x path.domain').attr('transform', 'translate(-25,0)')
      }, 250)


      function sum(a, b) {
        return a + b
      }

      let numStudents = sum(...data)

      if (numStudents <= 5) {
        histogramForStudentCompletion.axis.range({ max: { y: 5 } })
      }

    }
  },
  watch: {
    users (newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.groupedData = chain(this.users)
          .map(user => {
            user._relevant_group_membership = user.group_memberships.find(gm => gm.group_id === WindowVariables.groupId)
            return user
          })
          .groupBy(user => {
            const val = user._relevant_group_membership.cached_metadata.overall_completion_ratio || 0
            return Math.floor((val * 100) / 10) * 10
          })
          .value()

        this.initialize()
      }

    }
  },
  mounted() {
    // users()

  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  padding: 10px 0px 20px 0px;

  .chart-inner-container {
    padding-top: 10px;
    background-color: white;
  }
}

.c3-tooltip-container {
  z-index: 10;
}
.c3-tooltip {
  border-collapse:collapse;
  border-spacing:0;
  background-color:#fff;
  empty-cells:show;
  -webkit-box-shadow: 7px 7px 12px -9px rgb(119,119,119);
     -moz-box-shadow: 7px 7px 12px -9px rgb(119,119,119);
          box-shadow: 7px 7px 12px -9px rgb(119,119,119);
  opacity: 0.9;
}
.c3-tooltip tr {
  border:1px solid #CCC;
}
.c3-tooltip th {
  background-color: #aaa;
  font-size:14px;
  padding:2px 5px;
  text-align:left;
  color:#FFF;
}
.c3-tooltip td {
  font-size:13px;
  padding: 3px 6px;
  background-color:#fff;
  border-left:1px dotted #999;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value{
  text-align: right;
}
// .histogram-for-student-completion {
//   text-align: center;
// }
</style>
