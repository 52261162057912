<template>
  <div class="course-step-feedback-app">
    <div class="htss-helpfulness-input-container">
      <div class="htss-ranking-container htss-helpfulness-container">
        <div class="row">

          <div class="col-sm-12 directions"> {{ $t('application.course_step_feedback.scale_1_to_5') }} <span class="subheading"> {{ $t('application.course_step_feedback.scale_1_to_5_part2') }} </span></div>
        </div>
        <div class="row">
          <div class="col-sm-12 circles-container">
            <div v-for="circle in helpfulnessCircles" :class="{ active: circle.selected, hoverable: !hasSubmittedFeedback }" :key="circle.num"
              class="htss-ranking-circle htss-helpfulness-circle" @click="!hasSubmittedFeedback && selectCircle(circle)">
              {{circle.num}}
            </div>
          </div>
        </div>
        <div class="row more-helpful-directions">
          <div class="col-sm-12 htss-label"> {{ $t('application.course_step_feedback.learn') }} </div>
        </div>
        <div class="row">
          <div class="col-sm-12" v-if="hasSubmittedFeedback">
            <div class="htss-feedback-message-content">
              <span class="message">{{ courseStepFeedback.message }}</span>
            </div>
          </div>
          <div class="col-sm-12" v-else>
            <textarea class="htss-feedback-textarea" aria-label="Feedback textarea" placeholder="" :class="{ show: showMessageAndSubmitButton }" v-model="message" name="htss_feedback_textarea" style="width:100%;" rows="2"></textarea>
            <button class="btn btn-primary btn-lg htss-submit-feedback" :disabled="feedbackDisabled" :class="{ show: showMessageAndSubmitButton }" v-on:click="sendFeedback()"> {{ $t('application.course_step_feedback.send_feedback') }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CourseStepFeedbacks from '@dataServices/CourseStepFeedbacks'
  import { WindowVariables } from '@utils'
  import FlashMessages from '@services/FlashMessageService'
  import { range, map } from 'lodash-es'

  export default {
    computed: {
      helpfulnessCircles () {
        return map(range(5), (x) => {
          return {
            type: 'helpfulness',
            num: x + 1,
            selected: this.courseStepFeedback && this.courseStepFeedback.i_helpfulness === (x + 1) || this.iHelpfulness === (x + 1)
          }
        })
      },
      hasSubmittedFeedback () {
        return !!this.courseStepFeedback
      },
      feedbackDisabled () {
        return this.hasSubmittedFeedback || (!this.iHelpfulness && !this.message)
      },
    },
    data () {
      return {
        groupId: window.courseVars.group_id,
        courseStepId: window.courseVars.course_step_id,
        courseId: window.courseVars.course_id,
        courseStepFeedback: window.courseStepFeedback,
        message: '',
        iHelpfulness: null,
        showMessageAndSubmitButton: false
      }
    },
    methods: {
      selectCircle (circle) {
        if (circle.type === 'helpfulness') {
          this.iHelpfulness = circle.num
        }
        this.showMessageAndSubmitButton = true
      },
      sendFeedback () {
        CourseStepFeedbacks.create(this.groupId, this.courseStepId, this.courseId, this.message, this.iHelpfulness)
          .then(data => {
            FlashMessages.success('Your feedback has been submitted. Thanks so much!')
            this.courseStepFeedback = data
            this.showMessageAndSubmitButton = false
          })
          .catch(() => {
            FlashMessages.error('We were unable to submit your feedback. Please try again.')
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  $htss-purple: #5d3c7f;

  .htss-helpfulness-input-container {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid gainsboro;
    background-color: #F3F4F6;
    color: #030712;
  }

  textarea {
    padding: 4px;
    margin-top: -10px;
    font-size: 1.2rem;
    line-height: 1.2;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background-color: #fff;
  }

  .htss-ranking-circle {
    display: inline-block;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    line-height: 28px;
    margin-right: 8px;
    text-align: center;
    border: 1px solid #D1D5DB;
    background-color: #fff;

    &.active {
      background-color: $htss-purple;
      color: white;
    }

    &.hoverable:hover {
      cursor: pointer;
      background-color: rgba($htss-purple, 0.8);
      color: white;
    }
  }

  .message-label {
    font-size: 1.1rem;
  }

  .htss-feedback-message-content {
    margin-top: 5px;

    .message {
      color: #404040;
    }
  }

  .circles-container {
    margin-top: -10px;
  }

  .btn {
    margin-top: 5px;
  }

  .more-helpful-directions {
    margin-top: 10px;
  }
</style>
