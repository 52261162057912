class User {
    constructor(userId, dmUserId, name, username, active) {
        this.userId = userId;
        this.dmUserId = dmUserId;
        this.name = name;
        this.username = username;
        this.active = active;
    }
}

export default User;