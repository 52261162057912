<template>
  <div class="current-students-table">
    <div class="configuration flex flex-row">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Student</label>
        <input
          id="student-filter"
          type="text"
          placeholder="Filter students"
          v-model="tableFilter.student"
          class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out"
        />
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="group-dropdown" class="block">Group</label>
        <multiselect
          id="group-dropdown"
          v-model="tableFilter.group"
          track-by="id"
          label="name"
          :options="filterOptions.groups">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="phase-dropdown" class="block">Current Phase</label>
        <multiselect
          id="phase-dropdown"
          v-model="tableFilter.phase"
          track-by="value"
          label="name"
          :options="filterOptions.phases">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="coach-dropdown" class="block">Current Phase Coach</label>
        <multiselect
          id="coach-dropdown"
          v-model="tableFilter.coach"
          track-by="user_id"
          label="user_name"
          :options="filterOptions.coaches">
        </multiselect>
      </div>
    </div>
    <div class="table-wrapper">
      <v-data-table
        :headers="tableHeaders"
        :items="allItems"
        item-key="id"
        :loading="tableLoading"
        loading-text="Loading..."
        :server-items-length="totalNumberOfItems"
        :items-per-page="perPage"
        :page="tablePagination.current_page"
        :sort-by.sync="sortColumn"
        :sort-desc.sync="sortDesc"
        @update:page="fetchTableDataDebounced"
        @update:sort-by="handleSortColumn"
        @update:sort-desc="handleSortDirection"
      >
        <template v-slot:item.current_module_started_at="{ item }">
          {{ startedAgo(item.current_module_started_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionModalDropdown
            :user="item.user_id"
            :groupMembershipId="item.id"
            :groupId="item.group_id"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import dayjs from '@services/dayjsWrapper'
import axios from '@dataServices/axios'
import { debounce } from 'lodash-es'

import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import { WindowVariables } from '@utils'

import { VDataTable } from 'vuetify/lib'
import FlashMessages from "@services/FlashMessageService";

const baseUrl = window.location.origin

const tableHeaders = [
  { text: 'Student Name', value: 'user_name', sortable: true },
  { text: 'Group', value: 'group_name', sortable: false },
  { text: 'Current Phase Coach', value: 'current_coach_name', sortable: false },
  { text: 'Current Phase', value: 'current_module_name', sortable: false },
  { text: 'Phase Started Ago', value: 'current_module_started_at', sortable: true, flipSort: true },
  { text: 'Actions', value: 'actions', sortable: false },
]

export default {
  name: 'launchers-tab',
  components: {
    ActionModalDropdown,
    Multiselect,
    VDataTable,
  },
  props: {

  },
  data () {
    return {
      tableHeaders,
      allItems: [],
      tablePagination: {
        total: 0,
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        total_pages: 0,
        is_first_page: true,
        is_last_page: true,
        is_out_of_range: false,
        records_per_page: 0
      },
      tableLoading: false,
      tableFilter: {
        student: null,
        group: null,
        phase: null,
        coach: null,
        program_ids: null,
      },
      filterOptions: {
        groups: [],
        phases: [],
        coaches: [],
      },
      perPage: 15,
      sortColumn: ['current_module_started_at'],
      sortDesc: [false],

    }
  },
  mounted(){
    this.initFilters();
    this.fetchTableDataDebounced(1);
  },
  computed: {
    totalNumberOfItems () {
      return this.tablePagination && this.tablePagination.total?
          this.tablePagination.total:
          0
    },
    getSortBy(){
      return Array.isArray(this.sortColumn) && this.sortColumn.length > 0 ?
          this.sortColumn[0] :
          null;
    },
    getSortDir(){
      const sortDescFirstElement = Array.isArray(this.sortDesc) && this.sortDesc.length > 0 ? this.sortDesc[0] : null;
      return sortDescFirstElement === true ? 'desc' :
          sortDescFirstElement === false ? 'asc' :
              null;
    },
  },
  methods: {
    async initFilters () {
      try {
        this.tableFilter.program_ids = [19, 20, 21, 26] //TODO: add a filter for programs instead of hardcoding in here

        const response = await axios.get(baseUrl + '/api/v2/bff/launchers_tab/init_data', {params: {program_ids: this.tableFilter.program_ids}})
        this.filterOptions.coaches = response.data.filter_options.coaches.map(coach => ({user_id: coach.user_id, user_name: coach.user_name}));
        this.filterOptions.groups = response.data.filter_options.groups.map(group => ({id: group.id, name: group.name}));
        this.filterOptions.phases = response.data.filter_options.phase_names.map(phase => ({value: phase.name, name: phase.name}));
        this.tableFilter.coach = this.filterOptions.coaches.find(c => c.user_id === WindowVariables.currentUser.id) || null
      } catch (error) {
        console.error("Failed to fetch coaches:", error);
        FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    fetchTableDataDebounced: debounce(function(pageToLoad) { this.fetchTableData(pageToLoad) }, 250),
    fetchTableData (pageToLoad = 1) {
      this.tableLoading = true
      const request = this.toRequest(pageToLoad)

      return axios
            .get(baseUrl + '/api/v2/group_memberships', {params: request})
            .then(response => this.processResponse(response))
            .catch(error => this.processResponseError(request, error))
    },
    toRequest(pageToLoad = 1) {
      return {
        user_name_search: this.tableFilter.student,
        roles: ['student'],
        states: [1],
        group_ids: [this.tableFilter.group?.id],
        current_module_names: [this.tableFilter.phase?.name],
        current_coach_user_ids: [this.tableFilter.coach?.user_id],
        program_ids: this.tableFilter.program_ids,
        page: pageToLoad,
        per_page: this.perPage,
        sort_by: this.getSortBy,
        sort_direction: this.getSortDir,
      }
    },
    processResponse (response) {
      this.allItems = response.data.records
      this.tablePagination = response.data.pagination
      this.tableLoading = false
    },
    processResponseError (request, error) {
      this.tableLoading = false
      console.error("Failed to fetch table data:", request, error);
      FlashMessages.error("Failed to fetch table data! " + error); //it's ok to show the real error because the coaches are the users
    },
    handleSortColumn(column) {
      this.sortColumn = column;
      this.fetchTableDataDebounced(1);
    },
    handleSortDirection(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchTableDataDebounced(1);
    },
    startedAgo (started_at) {
      return started_at ? dayjs(started_at).fromNow(true) : 'n/a'
    }
  },
  watch: {
    tableFilter: {
      deep: true,
      handler(newVal, oldVal) {
        this.fetchTableDataDebounced(1);
      },
    }
  },
}
</script>

<style lang="scss">
.coach-action-table .v-data-footer__select {
  display: none;
}

.coach-action-table .v-window {
  overflow: initial;
}

.coach-action-table .v-data-table__wrapper {
  overflow: initial;
}
</style>
