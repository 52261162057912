<template>
  <div class="kpi-groups-and-meetings-pivot-table">
    <PivotTable :dimensions="dimensions" :metrics="metrics" :dataFetcher="dataFetcher"
      :initialRowDimensionValues="initialRowDimensionValues"
      :initialColumnDimensionValue="initialColumnDimensionValue"
      :initialMetricValues="initialMetricValues"
      :allowSetupChanges="false"
      ></PivotTable>
  </div>
</template>

<script>

  import axios from '@dataServices/axios'
  import { WindowVariables } from '@utils'

  import PivotTable from '@components/analytics/PivotTable.vue'

  export default {
    name: 'kpi-groups-and-meetings-pivot-table',
    props: ['initialRowDimensions', 'initialMetrics', 'initialColumnDimension'],
    data () {
      const kpiGroups = WindowVariables.partner.kpi_groups.map(g => {
        return Object.assign({}, g, {
          sql_column: `kpi_group_${g.slug.replace(/[^A-z0-9_]/g, '_')}`,
        })
      })

      const allAvailableMetrics = ['num_meetings', 'events_total'].concat(kpiGroups.map(g => g.sql_column))

      const metrics = [
        {
          text: '# Meetings',
          value: 'num_meetings',
      },
      {
        text: 'All Events',
        value: 'events_total',
      }].concat(kpiGroups.map(g => ({
        text: g.title,
        value: g.sql_column,
      })))

      let initialMetricValues = this.initialMetrics || []
      if (this.initialMetrics === true) {
        initialMetricValues = allAvailableMetrics
      }

      return {
        initialRowDimensionValues: this.initialRowDimensions || ['event_month_year'],
        initialColumnDimensionValue: this.initialColumnDimension || null,
        initialMetricValues,
        dimensions: [
          {
            value: 'sequence_name',
            text: 'Phase',
            sortable: true,
            sort (a, b) {
              return a.counter_of_sequences_in_group < b.counter_of_sequences_in_group
            }
          },
          {
            value: 'event_month_year',
            text: 'Month/Year',
            sortable: true,
            sort (a,b) {
              return a.event_month_year_date < b.event_month_year_date
            }
          },
          {
            value: 'student_name',
            text: 'Student Name',
            sortable: true,
          },
          {
            value: 'course_step_coach_name',
            text: 'Coach Name',
            sortable: true,
          },
          {
            value: 'event_action_user_name',
            text: 'Action User Name',
            sortable: true,
          },
          {
            value: 'course_step_name',
            text: 'Step Name',
            sortable: true,
            sort (a, b) {
              return a.sequence_order < b.sequence_order
            }
          },
          { value: 'group_name',
            text: 'Group',
            sortable: true,
          }
        ],
        metrics,
      }
    },
    components: {
      PivotTable,
    },
    methods: {
      dataFetcher(selectedRowDimensions, selectedColumnDimension) {
        let dimensions = selectedRowDimensions.map(x => x.value)
        if (selectedColumnDimension) {
          dimensions.push(selectedColumnDimension.value)
        }
        return axios.get('/reports/kpi_groups_and_meetings', {
          params: {
            course_ids: WindowVariables.currentCourseId,
            dimension_names: dimensions.join(',')
          }
        })
          .then(resp => resp.data.data)
      }
    }
  }
</script>

<style lang="scss">

</style>
