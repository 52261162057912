<template>
  <div class="starter-tracking-pack">
    <StarterTracking :userId="userId" :hideCourseStepIds="kpiCourseStepIds" />

    <div class="starter-tracking-pack-kpi" v-if="allowKpiOnlyTrackerToShow && kpiCourseStepIds.length > 0">  
      <h1 class="starter-tracking-pack-kpi-title">{{ $t('application.starter_tracking.success_markers') }}</h1>
      <StarterTracking
        :userId="userId"
        :onlyShowCourseStepIds="kpiCourseStepIds"
        v-bind:hasSearchBox="false"
        v-bind:hasPagination="false"
        v-bind:hasIconLegend="false"
      />
    </div>
  </div>
</template>

<script>
import StarterTracking from '@components/StarterTracking.vue'
import { WindowVariables } from '@utils'

export default {
  name: 'starter-tracking-dashboard',
  components: {
    StarterTracking,
  },
  data() {
    return {
      kpiCourseStepIds: WindowVariables.kpiCourseStepIds || [],
      allowKpiOnlyTrackerToShow: false,
    }
  },
  props: {
    userId: {
      type: Number,
    },
  },
}
</script>

<style>
.starter-tracking-pack-kpi {
  margin-top: 2rem;
}

.starter-tracking-pack-kpi-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>
