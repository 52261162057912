import { merge } from 'lodash-es'
import axios from './axios'

const baseUrlString = '/courses'

const Course = function (initialData) {
  var course = {}

  course.$reinitialize = function (data) {
    merge(course, data)
  }

  course.$reinitialize(initialData)

  return course
}

const Courses = {
  getAll: async function (opts) {
    return axios.get(baseUrlString, { params: opts })
      .then(resp => resp.data.map(Courses.initWithData))
      .catch(error => {
        console.error('Error', error.message)
      })
  },
  getById: async function (courseId) {
    return axios.get(`${baseUrlString}/${courseId}`)
      .then(function (response) {
        return Courses.initWithData(response.data)
      }).catch(function (error) {
        console.error('Error', error.message)
      })
  },
  initWithData: function (data) {
    return new Course(data)
  }
}

export default Courses
