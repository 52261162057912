<template>
  <div class="timeline-page-app">
    <div class="interaction-timeline-page-container">
      <interaction-timeline
        :groupId="groupId"
        :userId="selectedStudentId"
        :courseStepId="courseStepId"
        :key="uniqueKey"
        :servicePreset="servicePreset"
        v-if="groupId && selectedStudentId && isStudent"
      />
      <br />
    </div>
  </div>
</template>

<script>
const NAME = "timeline-page-app"

import InteractionTimeline from "@components/interactionTimeline/InteractionTimeline.vue"
import { WindowVariables } from "@utils"

export default {
  name: NAME,
  components: {
    InteractionTimeline
  },
  data() {
    return {
      pageName: NAME,
      groupId: WindowVariables.groupId,
      userId: null,
      courseStepId: parseInt(WindowVariables.courseStepId),
      selectedStudentId: null,
      isStudent: true,
    }
  },

  computed: {
    uniqueKey() {
      return this.selectedStudentId + "-" + this.courseStepId
    },
    servicePreset () {
      return {
        groupId: WindowVariables.groupId,
        userId: this.selectedStudentId,
        courseStepId: this.courseStepId,
      }
    },
  },
  created() {
    this.isStudent = WindowVariables.currentUser.studentForGroupId(this.groupId)
    if (this.isStudent) {
      this.userId = WindowVariables.currentUser.id
      this.selectedStudentId = this.userId
      this.isStudent = true
    } else {
      this.isStudent = false
    }
  },
}
</script>

<style lang="scss">
.interaction-timeline-page {
  .v--page {
    max-width: 600px;
    display: flex;
  }
}

.interaction-timeline-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .user-select-wrapper {
  }
}
</style>
