<template>
  <div class="direct-messaging-container">
    <DirectMessagingCurrentConversation v-if="selectedConversation" :conversation="selectedConversation" :currentUser="currentUser" :connected="connected" />
    <DirectMessagingSearchUsers v-if="showSearchUsersToggle" :connected="connected" :currentUser="currentUser" />
  </div>
</template>

<script>

import GlobalEventBus from '@services/GlobalEventBus'
import DirectMessagingCurrentConversation from "./DirectMessagingCurrentConversation.vue";
import DirectMessagingSearchUsers from "./DirectMessagingSearchUsers.vue";
import User from "@services/directMessaging/user";
import { VContainer, VRow, VCol } from 'vuetify/lib'

const baseUrl = window.location.origin

export default {
  name: 'direct-messaging-main',
  components: {
    DirectMessagingCurrentConversation,
    DirectMessagingSearchUsers,
    VContainer,
    VRow,
    VCol,
  },
  props: {
    currentUser: { type: User, required: true },
    connected: { type: Boolean, required: true },
  },
  data: () => ({
    selectedConversation: null,
    showSearchUsersToggle: false,
    unsubscribeListenerFunctions: [],
  }),
  async mounted () {
    if (this.selectedConversation === null) {
      this.showSearchUsersToggle = true;
    }
    this.unsubscribeListenerFunctions = [
      GlobalEventBus.subscribe('direct-messaging-sidebar', event => this.handleDirectMessagingSidebarEvent(event)),
      GlobalEventBus.subscribe('direct-messaging-current-conversation', event => this.handleDirectMessagingCurrentConversationEvent(event)),
      GlobalEventBus.subscribe('direct-messaging-search-users', event => this.handleDirectMessagingSearchUsersEvent(event)),
    ]
  },
  async beforeDestroy () {
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
    this.$emit('destroyed');
  },
  computed: {

  },
  methods: {
    handleDirectMessagingSidebarEvent (event) {
      this.showSearchUsersToggle = event.showSearchUsersToggle;
      this.selectedConversation = event.selectedConversation;
    },
    handleDirectMessagingCurrentConversationEvent (event) {
      console.info('direct-messaging-main.handleDirectMessagingCurrentConversationEvent:', event);
    },
    handleDirectMessagingSearchUsersEvent (event) {
      console.info('direct-messaging-main.handleDirectMessagingSearchUsersEvent:', event);
    },
  },
  watch: {

  },
}

</script>

<style lang="scss" scoped>

</style>
