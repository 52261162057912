<template>
  <div>
    <b-dropdown id="dropdown-1" text="Bulk Actions" class="">
      <b-dropdown-item v-for="action in actionOptionsForUserId"
        :key="action.actionType"
        @click.stop="openActionModal(action.actionType)">{{action.label}}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { every, some } from 'lodash-es'
import ModalService from '@services/ModalService'
import { WindowVariables } from '@utils'

const rawActions = [
  { actionType: 'bulkCreateManualEngagementTask', label: 'Bulk Manual Eng. Req.', permitted: ['er_admin', 'teacher'], requiredProps: ['selectedGroupMembershipIds'] },
  { actionType: 'bulkReviewTriageRequest', label: 'Bulk Review Triage Req.', permitted: ['er_admin', 'teacher'], requiredProps: ['selectedTriageRequestIds'] },
]

export default {
  name: 'bulk-action-modal-dropdown-new',
  props: {
    selectedGroupMembershipIds: { type: Array, required: false, validator(array) { return array.length > 0 && array.every(item => typeof item === 'number'); }},
    selectedTriageRequestIds: { type: Array, required: false, validator(array) { return array.length > 0 && array.every(item => typeof item === 'number'); }},
  },
  data () {
    return {}
  },
  computed: {
    currentUserId(){
      return WindowVariables.currentUser.id;
    },
    isPartnerAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'partner_admin')
    },
    isERAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'er_admin')
    },
    isTeacher () {
      return some(WindowVariables.currentUser.group_memberships, gm => gm.role === 'teacher' && gm.state === 1)
    },
    actionOptionsForUserId () {
      const permittedPredicate = (permittedRole) =>
          (permittedRole === 'er_admin' && this.isERAdmin)
          || (permittedRole === 'partner_admin' && this.isPartnerAdmin)
          || (permittedRole === 'teacher' && this.isTeacher)

      return rawActions
          .filter(action => some(action.permitted, permittedPredicate))
          .filter(action => every(action.requiredProps, v => this[v]))
    },
  },
  methods: {
    openActionModal (actionType) {
      if (actionType === 'bulkCreateManualEngagementTask') {
        ModalService.open('bulk-action-modal', {
          ids: this.selectedGroupMembershipIds,
          bulkAction: "bulk-manual-engagement",
        })
      }
      else if (actionType === 'bulkReviewTriageRequest') {
        ModalService.open('triage-engagement-request-modal', {
          userId: this.currentUserId,
          triageRequestIds: this.selectedTriageRequestIds,
        })
      }
    },
  }
}
</script>
