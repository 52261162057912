<template>
  <div class="group-roster">
    <div class="group-roster-add-buttons">
      <b-button variant="primary" @click="openAddUser('student', group.studentDisplayName)">Add {{ group.studentDisplayName }}</b-button>
      <b-button v-if="group.hasMentoringFeature" variant="primary" @click="openAddUser('mentor', group.mentorDisplayName)">Add {{ group.mentorDisplayName }}</b-button>
      <b-button variant="primary" @click="openAddUser('teacher', group.teacherDisplayName)">Add {{ group.teacherDisplayName }}</b-button>
    </div>

    <div class="group-roster-list">
      <b-tabs>
        <b-tab :title="`${group.studentDisplayName}s`" @click="selectRole('student')"></b-tab>
        <b-tab :title="`${group.mentorDisplayName}s`" @click="selectRole('mentor')" v-if="group.hasMentoringFeature"></b-tab>
        <b-tab :title="`${group.teacherDisplayName}s`" @click="selectRole('teacher')"></b-tab>
      </b-tabs>
      <users-list :data="vm.currentUsers" :userRole="this.vm.selectedRole" />
    </div>

    <b-modal v-model="showAddModal"
             size="lg"
             :title="modalTitle"
             :hide-footer="true">
      <b-form @submit="onSubmit">
        <b-form-group id="emailInputGroup"
                      label="Email address:"
                      label-for="emailInput"
                      :description="emailExists ? 'An account with this email address already exists' : 'We\'ll never share this email with anyone else.'">
          <b-form-input id="emailInput"
                        type="email"
                        size="lg"
                        v-model="form.email"
                        required
                        placeholder="Enter email">
          </b-form-input>
        </b-form-group>
        <b-form-group id="firstNameGroup"
                      label="First Name:"
                      label-for="firstNameInput">
          <b-form-input id="firstNameInput"
                        type="text"
                        size="lg"
                        v-model="form.first_name"
                        required
                        placeholder="Enter first name">
          </b-form-input>
        </b-form-group>
        <b-form-group id="lastNameGroup"
                      label="Last Name:"
                      label-for="lastNameInput">
          <b-form-input id="lastNameInput"
                        type="text"
                        size="lg"
                        v-model="form.last_name"
                        required
                        placeholder="Enter last name">
          </b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" :block="true" :disabled="!!emailExists">Add {{ addRoleText | capitalize }}</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import { filter, find } from 'lodash-es'
  import { WindowVariables } from '@utils'
  import UsersList from '@components/UsersList.vue'
  import { getUsersStoreFor } from '@stores/UsersStore'
  import FlashMessages from '@services/FlashMessageService'

  import { action, computed, makeObservable, observable } from 'mobx'
  import { observer } from 'mobx-vue'

  class ViewModel {
    selectedRole = null
    groupId = null

    constructor(params) {
      makeObservable(this, {
        selectedRole: observable,
        allUsers: computed,
        usersLoading: computed,
        currentUsers: computed,
        setSelectedRole: action,
      })

      this.usersStore = getUsersStoreFor(
        params.initialUsersFilter,
        { paginate: true }
      )

      this.groupId = params.groupId
      this.selectedRole = params.selectedRole
    }

    setSelectedRole (role) {
      this.selectedRole = role
      this.getData()
    }

    getData () {
      const filter = {
          ...this.groupId && { groupId: this.groupId },
          state: 1,
        }
      this.usersStore.setFilter(filter)
    }

    get allUsers () {
      return this.usersStore.allRecords
    }

    get usersLoading () {
      return this.usersStore.loading
    }

    get currentUsers () {
      return filter(this.allUsers, u => {
        if (this.groupId) {
          const gm = find(u.group_memberships, { group_id: this.groupId })
          return gm && (!this.selectedRole || gm.role === this.selectedRole)
        } else {
          return !this.selectedRole || u.role === this.selectedRole
        }
      })
    }

  }

  export default observer({
    name: 'group-roster',
    components: {
      UsersList,
    },
    data () {
      return {
        vm: new ViewModel({
          initialUsersFilter: {
            group_id: this.groupId,
            state: 1,
          },
          selectedRole: 'student',
          groupId: this.groupId,
        }),
        selectedRole: 'student',
        showAddModal: false,
        addModalRole: '',
        addRoleText: '',
        userIsCreating: false,
        group: WindowVariables.group,
        form: {
          first_name: '',
          last_name: '',
          email: '',
          group_id: this.groupId
        },
      }
    },
    computed: {
      modalTitle () {
        return `Add ${this.addRoleText}`
      },
      emailExists () {
        return find(this.vm.allUsers, { email: this.form.email })
      },
    },
    methods: {
      onSubmit (evt) {
        evt.preventDefault()
        this.userIsCreating = true
        const data = {
          user: {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email,
            role: this.addModalRole,
            group_membership: {
              group_id: this.groupId,
              role: this.addModalRole,
            },
          },
        }
        this.vm.usersStore.create(data)
          .then(u => {
            this.showAddModal = false
            this.resetAddUser()
            FlashMessages.success('User created successfully!')
          })
          .catch(resp => {
            console.error(resp)
            if (resp.error.error_code === 1002) {
              FlashMessages.error(`A user with email ${this.form.email} already exists. If you need to add this student to a different class go to their profile and manage their classes.`)
            } else {
              FlashMessages.error('We had an issue creating your new user')
            }
          })
          .finally(() => {
            this.userIsCreating = false
          })
      },
      resetAddUser () {
        this.userIsCreating = false
        this.form.first_name = ''
        this.form.last_name = ''
        this.form.email = ''
      },
      openAddUser (role, text) {
        this.addModalRole = role
        this.addRoleText = text
        this.showAddModal = true
      },
      selectRole (role) {
        this.vm.setSelectedRole(role)
      },
    },
    props: {
      groupId: {
        type: Number,
        default: () => {
          return WindowVariables.groupId
        } ,

      },
    },
    created () {
      this.vm.getData()
    },
  })
</script>

<style lang="scss" scoped>
  .group-roster .group-roster-add-buttons {
    text-align: right;
  }

  .error-message {
    text-align: center;
    font-size: 0.9rem;
    margin: 10px 10px;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 4px;

    &.show {
      border-color: #cc4b37;
    }
  }
</style>
