<template>
  <iframe
      v-if="shouldRenderIframe"
      :src="this.videoEmbedUrl"
      frameborder="0"
      allowfullscreen
      @load="onMediaLoaded"
  ></iframe>
</template>

<script>
import cheerio from "cheerio";
import toEmbed from "@services/urlEmbed";

const baseUrl = window.location.origin

export default {
  name: 'direct-messaging-iframe-video',
  components: {

  },
  props: {
    attachment: { type: Object, required: true },
  },
  data: () => ({
    videoEmbedUrl: null,
    isLoading: true,
  }),
  mounted () {
    this.processEmbedUrl(this.attachment);
  },
  destroy() {

  },
  computed: {
    shouldRenderIframe() {
      return this.isLoading === false
              && this.isVideoAttachment(this.attachment)
              && this.videoEmbedUrl !== null;
    },
  },
  methods: {
    isVideoAttachment(attachment) {
      return attachment.type === 'video';
    },
    async toEmbeddableUrl(attachment) {
      const embeddableUrl = await toEmbed(attachment.asset_url);
      return embeddableUrl? embeddableUrl : attachment.asset_url;
    },
    processEmbedUrl(attachment){
      if (this.isVideoAttachment(attachment)) {
        this.toEmbeddableUrl(attachment).then(embeddableUrl => {
          this.videoEmbedUrl = embeddableUrl;
          this.isLoading = false;
        })
      }
    },
    onMediaLoaded(event) {
      this.$emit('load', event);
    },
  },
  watch: {

  }

}

</script>

<style lang="scss" scoped>

</style>
