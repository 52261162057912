<template>
  <div class="partner-analytics">
    <div class="row">
      <div class="col-lg-6">
        <graph-for-benchmark/>
        <histogram-for-student-completion/>
      </div>
      <div class="col-lg-6">
        <graph-for-event/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6" style="margin:auto">
      </div>
    </div>
  </div>
</template>

<script>

  import GraphForBenchmark from '@components/GraphForBenchmark.vue'
  import GraphForEvent from '@components/GraphForEvent.vue'
  import HistogramForStudentCompletion from '@components/HistogramForStudentCompletion.vue'

  export default {
    name: 'partner-analytics',
    components: {
      GraphForBenchmark,
      GraphForEvent,
      HistogramForStudentCompletion,
    },
  }
</script>

<style lang="scss">

.partner-analytics {
  margin-bottom: 20px;
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent; }
.c3 path, .c3 line {
  fill: none;
  stroke: #000; }
.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }
.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges; }
.c3-chart-arc path {
  stroke: #fff; }
.c3-chart-arc text {
  fill: #fff;
  font-size: 13px; }
/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa; }
.c3-grid text {
  fill: #aaa; }
.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3; }
/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em; }
/*-- Line --*/
.c3-line {
  stroke-width: 1px; }
/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white; }
.c3-selected-circle {
  fill: white;
  stroke-width: 2px; }
/*-- Bar --*/
.c3-bar {
  stroke-width: 0; }
.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75; }
/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1; }
.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px; }
.c3-target.c3-defocused {
  opacity: 0.3 !important; }
/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: .1; }
/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: .1; }
/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px; }
.c3-legend-item-hidden {
  opacity: 0.15; }
.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1; }
/*-- Title --*/
.c3-title {
  font: 18px sans-serif; }
/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10; }
.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9; }
.c3-tooltip tr {
  border: 1px solid #CCC; }
.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF; }
.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999; }
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px; }
.c3-tooltip td.value {
  text-align: right; }
/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2; }
/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em; }
.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none; }
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px; }
.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777; }
.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777; }
.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/ }
.c3-chart-arc.c3-target g path {
  opacity: 1; }
.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1; }
/* WRAPPER */
.keen-dataviz {
  box-sizing: border-box;
}
.keen-dataviz .keen-dataviz-title {
  color: #cccccc; //#4D4D4D;
  background-color: #43474c;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 32px;
}
.keen-dataviz .keen-dataviz-stage {
  display: block;
}
.keen-dataviz .keen-dataviz-notes {
  color: #808080;
  display: block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 32px;
}
/* MESSAGE */
.keen-dataviz .keen-dataviz-message {
  color: #808080;
  display: block;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 21px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
}
/* METRIC */
.keen-dataviz .keen-dataviz-metric {
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
}
.keen-dataviz .keen-dataviz-metric-value {
  display: block;
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;
}
.keen-dataviz .keen-dataviz-metric-title {
  display: block;
  font-size: 24px;
  font-weight: 200;
  line-height: 24px;
  padding-top: 8px;
}
.keen-dataviz .keen-dataviz-metric-prefix {}
.keen-dataviz .keen-dataviz-metric-suffix {}
/* MESSAGE */
.keen-dataviz .keen-dataviz-message {
  box-sizing: border-box;
}
/* TABLE */
.keen-dataviz .keen-dataviz-table {
  border-bottom: 1px solid #E7E7E7;
  box-sizing: border-box;
  overflow: scroll;
  position: relative;
}
.keen-dataviz .keen-dataviz-table table {
  background: #FFFFFF;
  border-collapse: collapse;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  position: relative;
  width: 100%;
}
.keen-dataviz .keen-dataviz-table th,
.keen-dataviz .keen-dataviz-table td {
  border-collapse: collapse;
  font-size: 13px;
  padding: 10px;
  text-align: left;
  white-space: nowrap;
}
.keen-dataviz .keen-dataviz-table th {
  border-bottom: 1px solid #E7E7E7;
  color: #4D4D4D;
}
.keen-dataviz .keen-dataviz-table td {
  background-color: #FFFFFF;
  color: #4D4D4D;
  transition: background-color 0.1s, color 0.05s;
}
.keen-dataviz .keen-dataviz-table tr:nth-child(even) td {
  background-color: #F3F5F6;
}
.keen-dataviz .keen-dataviz-table tr:hover td {
  background-color: #E7E7E7;
  color: #4D4D4D;
}
.keen-dataviz .keen-dataviz-table .keen-dataviz-table-dataset thead {
  visibility: hidden;
}
.keen-dataviz .keen-dataviz-table .keen-dataviz-table-fixed-header {
  background: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  position: absolute;
  top: 0;
  transition: top .1s;
}
.keen-dataviz .keen-spinner-container {
  box-sizing: border-box;
  display: block;
  height: 50px;
  margin: 0 auto;
  position: relative;
  width: 35px;
}
.keen-dataviz .keen-spinner-indicator {
  border-radius: 100%;
  border: 3px solid rgba(0, 0, 0, .1);
  border-top-color: rgba(0, 0, 0, .45);
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  -webkit-animation:spin .7s linear infinite;
  animation:spin .7s linear infinite;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
/* C3 CUSTOMIZATIONS */
.keen-dataviz .c3 svg,
.keen-dataviz .c3-tooltip-container {
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.keen-dataviz .c3-tooltip-container th {
  font-size: 12px;
  max-width: 280px;
  word-wrap: break-word;
}
.keen-dataviz .c3-tooltip-container td {
  font-size: 11px;
  max-width: 280px;
  word-wrap: break-word;
}
.keen-dataviz .c3-tooltip-container td.name span {
  border-radius: 50%;
}
.keen-dataviz .c3-chart-arc text {
  font-size: 11px;
}
/* CUSTOM LEGEND */
.keen-c3-legend {
  position: absolute;
  top: 0;
}
.keen-c3-legend-label-overlay {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 1px rgba(26, 26, 26, 0.09999999999999998);
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 11px;
  padding: 10px;
  position: absolute;
  word-wrap: break-word;
  z-index: 2;
}
.keen-c3-legend-label-overlay-pointer {
  border-color: transparent transparent #FFFFFF transparent;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  height: 0;
  position: absolute;
  right: 90px;
  top: -10px;
  width: 0;
}


.keen-dashboard {
  background: #f2f2f2;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.keen-dataviz {
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  box-sizing: border-box;
}
.keen-dataviz-title {
  border-bottom: 1px solid #e7e7e7;
  border-radius: 2px 2px 0 0;
  font-size: 13px;
  padding: 2px 10px 0;
  text-transform: uppercase;
}
.keen-dataviz-stage {
  padding: 10px;
}

.keen-dataviz-notes {
  background: #fbfbfb;
  border-radius: 0 0 2px 2px;
  border-top: 1px solid #e7e7e7;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 11px;
  padding: 0 10px;
}

.keen-dataviz .keen-dataviz-metric {
  border-radius: 2px;
}

.keen-dataviz .keen-spinner-indicator {
  border-top-color: rgba(0, 187, 222, .4);
}

.keen-dashboard .chart-wrapper {
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  margin-bottom: 10px;
}
.keen-dashboard .chart-wrapper .chart-title {
  border-bottom: 1px solid #d7d7d7;
  color: #666;
  font-size: 14px;
  font-weight: 200;
  padding: 7px 10px 4px;
}

.keen-dashboard .chart-wrapper .chart-stage {
  overflow: hidden;
  padding: 5px 10px;
  position: relative;
}

.keen-dashboard .chart-wrapper .chart-notes {
  background: #fbfbfb;
  border-top: 1px solid #e2e2e2;
  color: #808080;
  font-size: 12px;
  padding: 8px 10px 5px;
}

.keen-dashboard .chart-wrapper .keen-dataviz,
.keen-dashboard .chart-wrapper .keen-dataviz-title,
.keen-dashboard .chart-stage .chart-title {
  border: medium none;
}
</style>
