function urlFromIdAndAction (base, entityId, action) {
  let url = `/${base}`
  if (entityId !== undefined) {
    url += `/${entityId}`
  }
  if (action !== undefined) {
    url += `/${action}`
  }
  return url
}
  
export function url ({ entityType, entityId, action, prefix = '' }) {
  const lowerEntityType = entityType.toLowerCase()

  if (lowerEntityType === 'user') {
    return urlFromIdAndAction(prefix + 'users', entityId, action)
  } else {
    console.error(`${entityType} does not have a known url.`)
  }
}

export function goToUrl ({ entityType, entityId, action, prefix }) {
  document.location.href = url({ entityType, entityId, action, prefix })
}
