<template>
  <video ref="videoElement" class="video-js vjs-default-skin vjs-16-9" crossorigin="anonymous"></video>
</template>

<script>
import videojs from 'video.js';
import axios from "@dataServices/axios";
import throttle from 'lodash-es/throttle'

const NAME = 'attachment-video'

const baseUrl = window.location.origin

export default {
  name: NAME,
  props: {
    thumbnail_url: { type: String, required: true },
    video_url: { type: String, required: true },

    subtitles: { type: Array, required: false, default: () => [] },
    chapters: { type: Object, required: false, default: null },

    attachment_id: { type: Number, required: false, default: null },
    group_id: { type: Number, required: false, default: null },
    course_step_id: { type: Number, required: false, default: null },
    course_id: { type: Number, required: false, default: null },
  },
  data: () => ({
    player: null,
  }),
  mounted () {
    this.player = videojs(this.$refs.videoElement, this.defaultOptions, () => {
      this.addChaptersTrack();
      this.addSubtitleTracks();
    });

    this.initAnalyticEventsTracking();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
    isLinkedToStep(){
      return this.group_id != null
             && this.course_step_id != null
             && this.course_id != null
    },
    defaultOptions() {
      return {
        autoplay: false,
        controls: true,
        preload: 'auto',
        playbackRates: [0.5, 1, 1.5, 2],
        poster: this.thumbnail_url,
        sources: [
          { src: this.video_url, type: 'application/x-mpegURL' }
        ]
      }
    },
  },
  methods: {
    initAnalyticEventsTracking() {
      if (this.isLinkedToStep === true){
        this.player.on('play', (eventData) => this.createAnalyticEvent('video-play', this.player.currentTime()))
        this.player.on('pause', (eventData) => this.createAnalyticEvent('video-pause', this.player.currentTime()))
        this.player.on('seeked', (eventData) => this.createAnalyticEvent('video-seeked', this.player.currentTime()))
        this.player.on('ended', (eventData) => this.createAnalyticEvent('video-end', this.player.currentTime()))

        const throttledProgressEvent = throttle(() => {this.createAnalyticEvent('video-progress', this.player.currentTime())}, 5000)
        this.player.on('timeupdate', throttledProgressEvent )
      }
    },
    async createAnalyticEvent(eventName, currentTime) {
      try {
        const request = this.instantiateCreateAnalyticEventRequest(eventName, currentTime)
        const response = await axios.post( baseUrl + '/api/analytic_events/track', request)
        return response.data;
      } catch (error) {
        console.error("Error creating analytic event:", error);
      }
    },
    instantiateCreateAnalyticEventRequest(eventName, currentTime){
      const currentTimeInSeconds = Math.floor(currentTime)
      return {
        analytic_event: {
          group_id: this.group_id,
          course_step_id: this.course_step_id,
          course_id: this.course_id,
          event_name: eventName,
          metadata: { current_time: currentTimeInSeconds, attachment_id: this.attachment_id }
        }
      }
    },
    addChaptersTrack(){
      if (this.chapters) {
        this.player.addRemoteTextTrack({
          kind: 'chapters',
          src: this.chapters.src,
          srclang: this.chapters.srclang,
          label: this.chapters.label,
          default: true,
        }, false);
      }
    },
    addSubtitleTracks(){
      this.subtitles
        .map(subtitle => this.toSubtitleTrack(subtitle))
        .forEach(subtitleTrack => this.player.addRemoteTextTrack(subtitleTrack, false))
        ;
    },
    toSubtitleTrack(subtitle){
      return {
        kind: 'captions',
        src: subtitle.src,
        srclang: subtitle.srclang,
        label: subtitle.label,
      }
    },
  },
  watch: {

  },
}

</script>

<style lang="scss" scoped>

</style>
