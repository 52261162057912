<template>
  <div class="student-status-pivot-table">
    <PivotTable :dimensions="dimensions" :metrics="metrics" :dataFetcher="dataFetcher"
      :initialRowDimensionValues="initialRowDimensionValues"
      :initialColumnDimensionValue="initialColumnDimensionValue"
      :initialMetricValues="initialMetricValues"
      :allowSetupChanges="false"
      ></PivotTable>
  </div>
</template>

<script>

  import axios from '@dataServices/axios'

  import PivotTable from '@components/analytics/PivotTable.vue'
  import { WindowVariables } from '@utils'

  export default {
    name: 'student-status-pivot-table',
    props: ['initialRowDimensions', 'initialMetrics', 'initialColumnDimension'],
    data () {
      let initialMetricValues = this.initialMetrics || []
      if (this.initialMetrics === true) {
        initialMetricValues = ['started', 'currently_in', 'discontinued', 'completed', 'pct_completed_from_started', 'pct_active_from_started', 'pct_active_or_completed_from_started', 'pct_discontinued_from_started']
      }

      return {
        initialRowDimensionValues: this.initialRowDimensions,
        initialColumnDimensionValue: this.initialColumnDimension || null,
        initialMetricValues,
        dimensions: [
          {
            value: 'sequence_analytics_name',
            text: 'Phase',
            sortable: true,
            sort (a, b) {
              return a.counter_of_sequences_in_group < b.counter_of_sequences_in_group
            }
          },
          {
            value: 'student_name',
            text: 'Student Name',
            sortable: true,
          },
          {
            value: 'course_step_coach_name',
            text: 'Coach Name',
            sortable: true,
          },
          { value: 'group_name',
            text: 'Group',
            sortable: true,
          }
        ],
        metrics: [
          {
            text: 'Started',
            value: 'started',
          },
          {
            text: 'Discontinued',
            value: 'discontinued',
          },
          {
            text: 'Currently In',
            value: 'currently_in',
          },
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: '% Compl. / Start',
            value: 'pct_completed_from_started',
          },
          {
            text: '% Active / Start',
            value: 'pct_active_from_started',
          },
          {
            text: '% Act + Compl. / Start',
            value: 'pct_active_or_completed_from_started',
          },
          {
            text: '% Discont. / Start',
            value: 'pct_discontinued_from_started',
          },
        ],
      }
    },
    components: {
      PivotTable,
    },
    methods: {
      dataFetcher(selectedRowDimensions, selectedColumnDimension) {
        let dimensions = selectedRowDimensions.map(x => x.value)
        if (selectedColumnDimension) {
          dimensions.push(selectedColumnDimension.value)
        }
        return axios.get('/reports/student_statuses', {
          params: {
            course_ids: WindowVariables.currentCourseId,
            dimension_names: dimensions.join(',')
          }
        })
          .then(resp => resp.data.data)
      }
    }
  }
</script>

<style lang="scss">

</style>
