<template>
  <div class="current-students-table">
    <div class="configuration flex flex-row">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Student</label>
        <input
          id="student-filter"
          type="text"
          placeholder="Filter students"
          v-model="tableFilter.student"
          class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out"
        />
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="coach-dropdown" class="block">Assigned Coach</label>
        <multiselect
            id="coach-dropdown"
            v-model="tableFilter.coach"
            track-by="id"
            label="name"
            :options="filterOptions.coaches">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="phase-dropdown" class="block">Assigned Phase</label>
        <multiselect
          id="phase-dropdown"
          v-model="tableFilter.phase"
          track-by="id"
          label="name"
          :options="filterOptions.phases">
        </multiselect>
      </div>
    </div>
    <div class="table-wrapper">
      <v-data-table
        :headers="tableHeaders"
        :items="allItems"
        item-key="id"
        :loading="tableLoading"
        loading-text="Loading..."
        :server-items-length="totalNumberOfItems"
        :items-per-page="perPage"
        :page="tablePagination.current_page"
        :sort-desc.sync="sortDesc"
        @update:page="fetchTableDataDebounced"
        @update:sort-desc="handleSortDirection"
      >
        <template v-slot:item.started_at="{ item }">
          {{ item.startedAgo }}
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionModalDropdown
            :user="item.student_id"
            :groupMembershipId="item.group_membership_id"
            :groupId="item.group_id"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from '@dataServices/axios'
import { debounce } from 'lodash-es'
import FlashMessages from '@services/FlashMessageService';
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import { VDataTable } from 'vuetify/lib'

const baseUrl = window.location.origin

const tableHeaders = [
  { text: 'Student Name', value: 'student_name', sortable: true },
  { text: 'Current Phase', value: 'current_phase_name', sortable: false },
  { text: 'Current Group', value: 'group_name', sortable: false },
  { text: 'Assigned Coach', value: 'coach_name', sortable: false },
  { text: 'Assigned Phase', value: 'assigned_phase_name', sortable: false },
  { text: 'Actions', value: 'actions', sortable: false },
]

export default {
  name: 'assigned-students-table',
  components: {
    ActionModalDropdown,
    Multiselect,
    VDataTable,
  },
  props: {

  },
  data () {
    return {
      tableHeaders,
      allItems: [],
      tablePagination: {
        total: 0,
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        total_pages: 0,
        is_first_page: true,
        is_last_page: true,
        is_out_of_range: false,
        records_per_page: 0
      },
      tableLoading: false,
      tableFilter: {
        student: null,
        group: null,
        phase: null,
        coach: null,
      },
      filterOptions: {
        phases: [],
        coaches: [],
      },
      completedItems: [],
      perPage: 15,
      sortDesc: [false],
    }
  },
  mounted(){
    this.initFilters();
    this.fetchTableDataDebounced(1);
  },
  computed: {
    totalNumberOfItems () {
      return this.tablePagination && this.tablePagination.total?
             this.tablePagination.total:
             0
    },
    getSortDir(){
      const sortDescFirstElement = Array.isArray(this.sortDesc) && this.sortDesc.length > 0 ? this.sortDesc[0] : null;
      return sortDescFirstElement === true ? 'desc' :
             sortDescFirstElement === false ? 'asc' :
             null;
    },
  },
  methods: {
    async initFilters(){
      this.fetchCoachesOptions();

      this.filterOptions.phases = [ //TODO: create API to fetch phases names
        { id: 1, name: 'Getting Started'},
        { id: 2, name: 'Phase 1'},
        { id: 3, name: 'Phase 2'},
        { id: 4, name: 'Phase 3'},
        { id: 5, name: 'Phase 4'},
        { id: 6, name: 'Phase 5'},
        { id: 7, name: 'Phase 6'},
        { id: 8, name: 'Phase 7'},
      ]
    },
    async fetchCoachesOptions () {
      try {
        const response = await axios.get(baseUrl + '/api/coaching/task_management/coaches', {params: {per_page: 100}}) //defining a high perPage number for now. If necessary in the future, implement recursive fetching later to fetch everyone
        this.filterOptions.coaches = response.data.records.map(coach => ({id: coach.id, name: coach.name}));
      } catch (error) {
        console.error("Failed to fetch coaches:", error);
        FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    fetchTableDataDebounced: debounce(function(pageToLoad) { this.fetchTableData(pageToLoad) }, 250),
    fetchTableData (pageToLoad = 1) {
      this.tableLoading = true
      const request = this.toRequest(pageToLoad)

      return axios
            .get(baseUrl + '/api/coaching/dashboard/assigned_students', {params: request})
            .then(response => this.processResponse(response))
            .catch(error => this.processResponseError(request, error))
    },
    toRequest(pageToLoad = 1) {
      return {
        search_term: this.tableFilter.student,
        assigned_coach_id: this.tableFilter.coach?.id,
        assigned_phase_name: this.tableFilter.phase?.name,
        program_ids: [20,26], //TODO: add a filter for programs
        page: pageToLoad,
        per_page: this.perPage,
        sort_direction: this.getSortDir,
      }
    },
    processResponse (response) {
      this.allItems = response.data.records
      this.tablePagination = response.data.pagination
      this.tableLoading = false
    },
    processResponseError (request, error) {
      this.tableLoading = false
      console.error("Failed to fetch table data:", request, error);
      FlashMessages.error("Failed to fetch table data! " + error); //it's ok to show the real error because the coaches are the users
    },
    handleSortDirection(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchTableDataDebounced(1);
    },
  },
  watch: {
    tableFilter: {
      deep: true,
      handler(newVal, oldVal) {
        this.fetchTableDataDebounced(1);
      },
    }
  },
}
</script>

<style lang="scss">
.coach-action-table .v-data-footer__select {
  display: none;
}

.coach-action-table .v-window {
  overflow: initial;
}

.coach-action-table .v-data-table__wrapper {
  overflow: initial;
}
</style>
