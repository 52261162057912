<template>
  <div class="direct-messaging-panel-container">
    <div class="direct-messaging-panel-title">Find & Connect</div>
    <div class="direct-messaging-panel-content">

      <v-row>
        <v-col cols="12">
          <div class="search-users-instruction-text clear-base-el">
            <p>The <span class="find-and-connect-highlight">Find & Connect</span> database is your ticket to building your network while in the program.</p>
            <br>
            <p>Use this feature to locate other entrepreneurs in the program who may have the expertise and advice you seek. You can also search for others working in the same industry as you. Once you’ve located potential connections, send them a private message or make a group chat and get the conversation started!</p>
            <br>
            <p>Please ensure that your <a :href="userProfileLink">Community Profile</a> has been updated so other Launchers can also find you.</p>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <button @click="stepsVisible = !stepsVisible" class="toggle-steps-btn" aria-label="Expand instructions on how to get connected">
            <span>Click Here For Video and Instructions</span><span :class="['chevron-icon', { 'is-expanded': stepsVisible }]"></span>
          </button>
        </v-col>
      </v-row>

      <div v-if="stepsVisible">
        <v-row v-if="trainingVideoData">
          <div class="attachment-video-container">
            <AttachmentVideo :thumbnail_url="trainingVideoData.thumbnail_url" :video_url="trainingVideoData.video_url" />
          </div>
        </v-row>

        <v-row>
          <v-col cols="1" class="search-users-step-col">
            <div class="search-users-step-title">Step 1:</div>
          </v-col>
          <v-col cols="11">
            <div class="search-users-step-text clear-base-el">
              <p>Enter the skill, expertise, or superpower in the search bar to filter and find relevant Launchers based on their <a :href="userProfileLink">Community Profiles</a>. If you already know the name of the Launcher you wish to connect with, directly type their name instead.</p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" class="search-users-step-col">
            <div class="search-users-step-title">Step 2:</div>
          </v-col>
          <v-col cols="11">
            <div class="search-users-step-text">
              To add a Launcher to your conversation list, click the <span class="find-and-connect-highlight">+</span> button next to their name in the search results. If you want to initiate a group conversation, you can continue to search and add multiple Launchers using the <span class="find-and-connect-highlight">+</span> button.
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" class="search-users-step-col">
            <div class="search-users-step-title">Step 3:</div>
          </v-col>
          <v-col cols="11">
            <div class="search-users-step-text">
              If you need to remove a Launcher from your list before starting the message thread, click the <span class="find-and-connect-highlight">x</span> next to their name in the search bar.
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" class="search-users-step-col">
            <div class="search-users-step-title">Step 4:</div>
          </v-col>
          <v-col cols="11">
            <div class="search-users-step-text">
              When you are ready to connect, click on the <span class="find-and-connect-highlight">Start Conversation</span> button.
            </div>
          </v-col>
        </v-row>
      </div>

      <v-row class="search-users-row">
        <v-col cols="6">
          <div class="search-input-container">
            <label for="search-label" style="display:block; color:black; font-weight: semibold;">Enter keywords or names to search our current launcher database:</label>
            <input id="search-label" type="text" v-model="searchTerm" placeholder="Enter your search terms" class="direct-messaging-search-users-text-field" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="closable-chip-row">
            <v-chip
                v-for="(item, index) in selectedUsers"
                :key="index"
                class="closable-chip-custom">
              {{ item.community_username }} <button class="closable-chip-custom-icon" @click="removeFromConversation(item)" :disabled="startingConversation">x</button>
            </v-chip>
            <button v-if="showStartConversationButton" class="btn-primary start-new-conversation-btn" @click="startNewConversation" :disabled="shouldDisableStartConversationButton">Start Conversation</button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="allItems"
              item-key="id"
              :loading="tableLoading"
              loading-text="Loading..."
              no-data-text="No Launchers Yet"
              :page="tablePagination.currentPage"
              :items-per-page="perPage"
              :server-items-length="totalNumberOfItems"
              :sort-by.sync="sortColumn"
              :sort-desc.sync="sortDesc"
              @update:page="fetchTableDataDebounced"
              @update:sort-by="handleSortColumn"
              @update:sort-desc="handleSortDirection"
              :footer-props="{'items-per-page-options': [perPage]}"
              :disable-pagination="!connected"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <button v-if="isSelected(item)" class="btn-secondary" @click="removeFromConversation(item)" :disabled="startingConversation" tabindex="0" aria-label="Remove this user from who you want to talk to"><span class="add-remove-button">-</span></button>
                  <button v-else class="btn-primary" @click="addToConversation(item)" :disabled="startingConversation" tabindex="0" aria-label="Start a conversation with this user"><span class="add-remove-button">+</span></button>
                </td>
                <td tabindex="0">{{ item.community_username }}<span v-if="isCurrentUser(item)"> (you)</span></td>
                <td tabindex="0">{{ toLastMessageEventDate(item.last_message_event_date) }}</td>
                <td tabindex="0">{{ item.community_location }}</td>
                <td tabindex="0">{{ item.idea_industry }}</td>
                <td tabindex="0">{{ item.idea_category }}</td>
                <td tabindex="0">{{ item.community_idea_description }}</td>
                <td tabindex="0">{{ item.community_superpowers }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import EventBus from '@services/GlobalEventBus'
import axios from '@dataServices/axios'
import AttachmentVideo from '@components/AttachmentVideo.vue'
import _ from "lodash-es";
import { VCol, VRow, VAutocomplete, VDataTable, VChip, VIcon, VTextField } from 'vuetify/lib'
import User from "@services/directMessaging/user";
import dayjs from "dayjs";

const baseUrl = window.location.origin

const tableHeaders = [
  { text: '', value: 'actions', sortable: false },
  { text: 'Username', value: 'community_username', sortable: false },
  { text: 'Last Active on Find&Connect', value: 'last_message_event_date', sortable: false },
  { text: 'Location', value: 'community_location', sortable: false },
  { text: 'Industry', value: 'idea_industry', sortable: false },
  { text: 'Idea Type', value: 'idea_category', sortable: false },
  { text: 'Venture Idea', value: 'community_idea_description', sortable: false },
  { text: 'Superpowers', value: 'community_superpowers', sortable: false },
]

const NAME = 'direct-messaging-search-users'

export default {
  name: NAME,
  components: {
    VTextField,
    VCol,
    VRow,
    VAutocomplete,
    VDataTable,
    VChip,
    VIcon,
    AttachmentVideo
  },
  props: {
    connected: { type: Boolean, required: true },
    currentUser: { type: User, required: true },
  },
  data: () => ({
    filteredUsers: [],
    searchTerm: null,
    headers: tableHeaders,
    allItems: [],
    selectedUsers: [],
    tablePagination: {
      total: 0,
      current_page: 1,
      next_page: 1,
      previous_page: 1,
      total_pages: 0,
      is_first_page: true,
      is_last_page: true,
      is_out_of_range: false,
      records_per_page: 0
    },
    tableLoading: true,
    perPage: 15,
    sortColumn: ['community_username'],
    sortDesc: [false],

    startingConversation: false,
    stepsVisible: false,

    alertVisible: false,
    errorMessage: '',
    userProfileLink: '',

    trainingVideoData: null,
  }),
  mounted () {
    const currentGroupId = window?.courseVars?.group_id

    if (currentGroupId){ this.fetchTrainingVideoData(currentGroupId) }

    this.userProfileLink = baseUrl + '/users/' + this.currentUser.userId + '?from_community=true'
    this.fetchTableDataDebounced(1);
  },
  beforeDestroy() {

  },
  computed: {
    showStartConversationButton() {
      return this.selectedUsers.length > 0;
    },
    totalNumberOfItems () {
      return this.tablePagination && this.tablePagination.total?
          this.tablePagination.total:
          0
    },
    getSortBy(){
      return Array.isArray(this.sortColumn) && this.sortColumn.length > 0 ?
          this.sortColumn[0] :
          null;
    },
    getSortDir(){
      const sortDescFirstElement = Array.isArray(this.sortDesc) && this.sortDesc.length > 0 ? this.sortDesc[0] : null;
      return sortDescFirstElement === true ? 'desc' :
          sortDescFirstElement === false ? 'asc' :
              null;
    },
    shouldDisableStartConversationButton() {
      return this.selectedUsers.length === 0 || !this.connected;
    },
  },
  methods: {
    isCurrentUser(user){
      return this.currentUser.userId === user.id;
    },
    addToConversation (userRow) {
      if (!this.selectedUsers.some(user => user.id === userRow.id)) {
        this.selectedUsers.push(userRow);
      }
    },
    removeFromConversation (userRow) {
      this.selectedUsers = this.selectedUsers.filter(user => user.id !== userRow.id);
    },
    handleSortColumn(column) {
      this.sortColumn = column;
      this.fetchTableDataDebounced(1);
    },
    handleSortDirection(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchTableDataDebounced(1);
    },
    fetchTrainingVideoData(groupId){
      const request = { params: { group_id: groupId } }
      return axios
            .get(baseUrl + `/api/direct_messages/training_video`, request)
            .then(response => this.processTrainingVideoResponse(response))
            .catch(error => this.processResponseError(request, error))
    },
    fetchTableData (pageToLoad = 1) {
      this.tableLoading = true
      const request = new FetchTableDataRequest(this.searchTerm, pageToLoad, this.perPage, this.getSortBy, this.getSortDir)
      return axios
          .get(baseUrl + `/api/direct_messages/users`, request)
          .then(response => this.processResponse(response))
          .catch(error => this.processResponseError(request, error))
    },
    fetchTableDataDebounced: _.debounce(function(pageToLoad) { this.fetchTableData(pageToLoad); }, 250),
    processResponse (response) {
      this.allItems = response.data.records.map(r => new UserRow(r))
      this.tablePagination = response.data.pagination
      this.tableLoading = false
    },
    processTrainingVideoResponse (response) {
      this.trainingVideoData = response.data
    },
    searchUsers() {
      this.fetchTableDataDebounced(1);
    },
    startNewConversation() {
      this.startingConversation = true;
      const userIds = this.selectedUsers.map(user => user.id);
      const request = new CreateNewConversationRequest(userIds);
      return axios
          .post(baseUrl + `/api/direct_messages/channels`, request)
          .then(response => this.processStartNewConversationResponse(response))
          .catch(error => this.processResponseError(error))
          .finally(() => this.startingConversation = false)
    },
    processStartNewConversationResponse (response) {
      const createdChannel = response.data;
      const eventPayload = { type: 'createdNewConversation', createdChannel: createdChannel }
      EventBus.dispatch(NAME, eventPayload)
    },
    processResponseError (request, error) {
      this.errorMessage = error.response? error.response.data.message : error.message;
      this.alertVisible = true;
      this.tableLoading = false
      console.error('Backend Error:', request, this.errorMessage, error.response?.data, error.response?.status);
    },
    isSelected (userRow) {
      return this.selectedUsers.some(user => user.id === userRow.id);
    },
    toLastMessageEventDate(lastMessageEventDate){
      return lastMessageEventDate ?
              this.formatLastMessageEventDate(lastMessageEventDate) :
              null;
    },
    formatLastMessageEventDate(lastMessageEventDate) {
      const now = new Date();
      const eventDate = new Date(lastMessageEventDate);

      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const dayDifference = Math.floor((now - eventDate) / millisecondsPerDay);

      if (dayDifference === 0) {
        return "today";
      } else if (dayDifference === 1) {
        return "yesterday";
      } else if (dayDifference === 2) {
        return "2 days ago";
      } else if (dayDifference <= 7) {
        return "this week";
      } else if (dayDifference <= 14) {
        return "last week";
      } else {
        return "more than 2 weeks";
      }
    },

  },
  watch: {
    searchTerm(newSearchTerm, oldSearchTerm) {
      this.fetchTableDataDebounced(1);
    },
  },
}

class FetchTableDataRequest {
  constructor (searchTerm, pageToLoad, usersPerPage, sortBy, sortDirection) {
    this.params = {
      search_term: searchTerm,
      page: pageToLoad,
      per_page: usersPerPage,
      sort_by: sortBy,
      sort_direction: sortDirection
    }
  }
}

class CreateNewConversationRequest {
  constructor (userIds) {
    this.channel = {
      user_ids: userIds
    };
  }
}

class UserRow {
  constructor (data) {
    Object.assign(this, data)
  }
}

</script>

<style lang="scss" scoped>

.search-users-instruction-text {
  margin: 20px 0;
  padding-right: 24px;
}

.search-users-step-title {
  padding-right: 0;
  font-weight: bold;
  white-space: nowrap;
}

.search-users-step-col {  //FIXME: delete this style if is not being used
  //padding-right: 0;
}

.search-users-step-text {
  //margin: 10px 0;
  text-align: justify;
  padding-right: 24px;
}

.closable-chip-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-users-row {
  margin-top: 30px;
}

.find-and-connect-highlight {
  font-weight: bold;
  color: black;
}

.add-remove-button {
  font-weight: bold;
  font-size: 20px;
}

.toggle-steps-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent; // Example styling, adjust as needed
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: #f78e1e;
}

.direct-messaging-search-users-text-field {
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  padding:  12px;
  width: 100%;
  margin-top: 4px;
}

.chevron-icon {
  color: #6B7280;
}

.attachment-video-container {
  width: 80%; /* Makes the video width responsive to the container width */
  height: auto; /* Maintains the aspect ratio of the video */
  max-width: 80%; /* Ensures the video is not larger than its container */
  min-width: 400px;
}

@media (max-width: 600px) {
  .attachment-video-container {
    /* Adjustments for small screens, if necessary */
  }
}
</style>
