<template>
  <div class="assignment-submitter">
    <div class="top-bar clearfix">
      <div class="assignment-name pull-left"><span class="label">{{ $t('application.common.action_step') }} </span>{{ assignment.name }}</div>
      <template v-if="assignmentResponse && assignmentResponse.assignment_response_grade">
        <div class="points-received pull-right">
          <span class="label">{{ $t('application.assignment_submitter.score') }} </span>
          {{ assignmentResponse.assignment_response_grade.points}} of {{ assignment.total_points }} pts
        </div>
      </template>
      <template v-else>
        <div class="assignment-total-points pull-right">{{ $t('application.assignment_submitter.worth') }} {{ assignment.total_points }} pts</div>
      </template>
    </div>
    <div class="inner clearfix">
      <div class="assignment-description pull-left">
        {{ firstUpload.description }} <span style="display: none" v-if="firstUpload.required">({{ $t('application.assignment_submitter.required') }})</span>
      </div>
      <template v-if="assignmentResponse">
        <button class="btn pull-right" :title="$t('application.assignment_submitter.remove_file')" v-if="!assignmentResponse.assignment_response_grade"
                @click="removeResponse()">
          <i class="fa fa-minus-circle" aria-hidden="true"></i>
        </button>

        <form enc-type="multipart/form-data" novalidate class="pull-right" v-if="!assignmentResponse.assignment_response_grade">
          <label class="file-container pull-right" @click="clearVal()">
            <button class="btn pull-right"
                    :title="$t('application.assignment_submitted.replace_current_file')">
              <span v-if="!creatingAssignmentResponse">{{ $t('application.assignment_submitter.replace_file') }}</span>
              <span v-if="creatingAssignmentResponse">{{ $t('application.assignment_submitter.uploading') }}</span>
            </button>
            <input aria-labelledBy="add-attachment" type="file" name="attachment_file" accept="application/pdf" class="pull-right"
                   @change="filesChange($event.target.name, $event.target.files)">
            <span id="add-attachment" class="sr-only">Add attachment</span>
          </label>
        </form>

        <div class="assignment-attachment pull-right">
          <a v-bind:href="assignmentResponse.attachments[0].presigned_url" target="_blank">
            {{ assignmentResponse.attachments[0].file_name }}
          </a>
        </div>
      </template>
      <template v-else>
        <form enc-type="multipart/form-data" novalidate class="pull-right">
          <label class="file-container pull-right" @click="clearVal()">
            <button class="btn pull-right">
              <span v-if="!creatingAssignmentResponse">{{ $t('application.assignment_submitter.choose_file') }}</span>
              <span v-if="creatingAssignmentResponse">{{ $t('application.assignment_submitter.uploading') }}</span>
            </button>
            <input type="file" name="attachment_file" accept="application/pdf" class="pull-right"
                   @change="filesChange($event.target.name, $event.target.files)">
          </label>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
  import { find } from 'lodash-es'
  import { getAssignmentsStoreFor } from '@stores/AssignmentsStore'
  import { getAssignmentResponsesStoreFor } from '@stores/AssignmentResponsesStore'
  import { WindowVariables } from '@utils'
  import FlashMessages from '@services/FlashMessageService'

  import { observer } from "mobx-vue"

  export default observer({
    name: 'assignment-submitter',
    components: {
    },
    data () {
      return {
        creatingAssignmentResponse: false,
        assignmentsStore: getAssignmentsStoreFor(this.assignmentsStoreId),
        assignmentResponsesStore: getAssignmentResponsesStoreFor({ assignmentId: parseInt(this.assignmentId), groupId: WindowVariables.groupId })
      }
    },
    computed: {
      assignment () {
        return find(this.assignmentsStore.allRecords, { id: parseInt(this.assignmentId) })
      },
      assignmentResponse () {
        return find(this.assignmentResponsesStore.allRecords, { assignment_id: parseInt(this.assignmentId) })
      },
      firstUpload () {
        return this.assignment.uploads[0]
      },
    },
    methods: {
      removeResponse() {
        this.assignmentResponsesStore.remove(this.assignmentResponse.id)
          .then(() => {
            FlashMessages.success(this.$t('application.assignment_submitter.flash.remove_response_success'))
          })
          .catch(() => {
            FlashMessages.error(this.$t('application.assignment_submitter.flash.remove_response_error'))
          })
      },
      clearVal() {
        $('input[name=attachment_file]').val('')
      },
      save(formData) {
        formData.set('assignment_response[assignment_id]', this.assignmentId)
        formData.set('assignment_response[group_id]', WindowVariables.groupId)
        formData.set('assignment_response[file_type]', 'PDF')
        formData.set('assignment_response[file_identifier]', this.firstUpload.file_identifier)

        this.creatingAssignmentResponse = true
        this.assignmentResponsesStore.create(formData)
          .then((resp) => {
            this.creatingAssignmentResponse = false
            FlashMessages.success(this.$t('application.assignment_submitter.flash.submit_success'))
          })
          .catch((resp) => {
            this.creatingAssignmentResponse = false
            if (resp.kind === 'error') {
              let txt = resp.message
              let idx = txt.indexOf('|')
              if ( idx > -1) {
                txt = txt.substring(idx + 1)
              }
              FlashMessages.error(txt)
            } else {
              FlashMessages.error(this.$t('application.assignment_submitter.flash.submit_error'))
            }
          })
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData()

        if (!fileList.length) return

        // append the files to FormData
        Array
            .from(Array(fileList.length).keys())
            .map(x => {
              formData.append(fieldName, fileList[x], fileList[x].name)
            })

        // save it
        this.save(formData)
      }
    },
    props: {
      assignmentId: {
        type: Number,
        required: true
      },
      assignmentsStoreId: {
        type: String,
        required: true
      },
    }
  })
</script>

<style lang="scss" scoped>
  .assignment-submitter {
    padding: 0 5px;

    .assignment-total-points {
      display: none;
    }

    .btn {
      background-color: #fff;
      padding: 12px 24px;
      cursor: pointer;
      color: #333;
      border: 1px solid #e8e8e8;

      &:hover {
        background-color: #A0A0A0;
      }
    }
  }

  .top-bar {
    background-color: #F6F6F6;
    width: 100%;
    color: $medium-gray;
    padding: 5px 10px;

    .label {
      text-transform: uppercase;
      font-size: 1.0rem;
    }
  }

  .assignment-attachment {
    padding-right: 10px;
  }

  .inner {
    padding: 5px 10px;
    background-color: #F0F0F0;
    color: $medium-gray;
    width: 100%;
  }

  .file-container {
    overflow: hidden;
    position: relative;
  }

  .file-container [type=file] {
    cursor: pointer;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }

</style>