import User from "./user";

/**
 * This class is a wrapper around the GetStream.io channel object, while also representing the conversations on the frontend.
 */

class Conversation {

    constructor(currentUser, backendChannel, getStreamChannel) {
        this.getStreamChannel = getStreamChannel;
        this.currentUser = currentUser;
        this.getStreamUserId = String(currentUser.dmUserId);

        this.id = backendChannel.id;
        this.members = this.#toUsers(backendChannel); //the members do not include the user itself
        this.selected = false;
    }

    get userId() {
        return this.currentUser.userId;
    }

    get name() {
        return this.isConversationWithMyself ?
               this.currentUser.username + ' (you)':
               this.members.map(member => member.username).join(', ');
    }

    get allMembers() {
        return this.members.concat([this.currentUser]);
    }

    get unreadMessagesCount() {
        return this.getStreamChannel.state.read[this.getStreamUserId]?.unread_messages ?? null;
    }

    get isGroupChat() {
        return this.members.length > 1;
    }

    get isConversationWithMyself() {
        return this.members.length === 0;
    }

    getMemberOnlineStatus(memberId) {
        const member = this.getStreamChannel.state.members[memberId];
        return member?
               member.user.online:
               null;
    }

    select() {
        this.selected = true;
    }

    deselect() {
        this.selected = false;
    }

    destroy(){

    }

    async markMessagesAsRead(){
        await this.getStreamChannel.markRead();
    }

    async loadOlderMessages(quantity){
        const messageCount = this.getStreamChannel.state.messages.length;
        const olderLoadedMessage = messageCount > 0? this.getStreamChannel.state.messages[0]: null;
        await this.queryMessagesOlderThan(olderLoadedMessage, quantity) //just by querying, the messages are loaded into the channel
    }

    async queryMessagesOlderThan(olderMessage, quantity){
        const filters = { messages: { id_lt: olderMessage.id, limit: quantity } };
        return await this.getStreamChannel.query(filters).then(response => response.messages);
    }

    async queryMessagesNewerThan(newestMessage, quantity){
        const filters = { messages: { id_gt: newestMessage.id, limit: quantity } };
        return await this.getStreamChannel.query(filters).then(response => response.messages);
    }

    #toUsers(backendChannel){
        return backendChannel
            .members
            .filter(m => m.user_id !== this.userId)
            .map(m => new User(m.user_id, m.dm_user_id, m.community_username, m.community_username, m.active));
    }

}

export default Conversation;