<template>
  <div>
    <b-dropdown id="dropdown-1" text="Bulk Actions" class="">
      <b-dropdown-item v-for="action in actionOptionsForUserId"
        :key="action.actionType"
        @click.stop="openActionModal(action.actionType)">{{action.label}}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { every, some } from 'lodash-es'
import ModalService from '@services/ModalService'
import { WindowVariables } from '@utils'

const rawActions = [
  // { actionType: 'openInteractionTimelineModal', label: 'Open Step', permitted: ['er_admin', 'gm_teacher', 'partner_admin'], requiredValues: ['courseStepId'] },
  // { actionType: 'openStarterTracker', label: 'Open Tracker', permitted: ['er_admin', 'gm_teacher', 'partner_admin'] },
  // { actionType: 'viewActionHistory', label: 'View Action History', permitted: ['er_admin', 'partner_admin', 'gm_teacher', 'gm_mentor'] },
  // { actionType: 'addLauncherUpdateInternal', label: 'Add Launcher Update', permitted: ['er_admin', 'gm_teacher', 'gm_mentor'], exclude: ['partner_admin'] },
  // { actionType: 'addLauncherUpdatePartner', label: 'Add Launcher Update', permitted: ['partner_admin'] },
  // { actionType: 'addPhoneCall', label: 'Add Call Notes', permitted: ['er_admin', 'gm_teacher', 'gm_mentor'] },
  // { actionType: 'markDiscontinued', label: 'Mark Discontinued', permitted: ['er_admin'] },
  // { actionType: 'recordAssignedMentorGroup', label: 'Record Assigned Mentor Group', permitted: ['er_admin'] },
  // { actionType: 'addDisengagementNotes', label: 'Add Disengagement Notes', permitted: ['er_admin'] },
  // { actionType: 'updateUserPhoneNumber', label: 'Update Phone Number', permitted: ['er_admin'] },
  // { actionType: 'editUserTags', label: 'Edit Tags', permitted: ['er_admin', 'gm_teacher'] },
  { actionType: 'bulk-manual-engagement', label: 'Bulk Manual Eng. Req', permitted: ['er_admin', 'gm_teacher'] },
]

export default {
  name: 'bulk-action-modal-dropdown',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    courseStepId: {
      type: Number,
      required: false,
    }
  },
  data () {
    return {}
  },
  computed: {
    isPartnerAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'partner_admin')
    },
    isERAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'er_admin')
    },
    actionOptionsForUserId () {
      return rawActions.filter(action => {
        return every(action.exclude, permType => {
          return (permType === 'er_admin' && !this.isERAdmin) ||
            (permType === 'partner_admin' && !this.isPartnerAdmin) ||
            (permType === 'gm_teacher' && !some(
              WindowVariables.currentUser.group_memberships,
              gm => gm.group_id === this.groupId && gm.role === 'teacher' && gm.state === 1
            )) ||
            (permType === 'gm_mentor' && !some(
              WindowVariables.currentUser.group_memberships,
              gm => gm.group_id === this.groupId && gm.role === 'mentor' && gm.state === 1
            ))
        }) && some(action.permitted, permType => {
          return (permType === 'er_admin' && this.isERAdmin) ||
            (permType === 'partner_admin' && this.isPartnerAdmin) ||
            (permType === 'gm_teacher' && some(
              WindowVariables.currentUser.group_memberships,
              gm => gm.group_id === this.groupId && gm.role === 'teacher' && gm.state === 1
            )) ||
            (permType === 'gm_mentor' && some(
              WindowVariables.currentUser.group_memberships,
              gm => gm.group_id === this.groupId && gm.role === 'mentor' && gm.state === 1
            ))
        }) && (!action.requiredValues || every(action.requiredValues, v => this[v]))
      })
    },
  },
  methods: {
    openActionModal (actionType) {
      const selectedIds = [];

      if (actionType === 'bulk-manual-engagement') {
        const selectedItems = document.querySelectorAll('[data-bulk-action-id]:checked');

        selectedItems.forEach(row => {
          selectedIds.push(parseInt(row.value))
        });
      }

      ModalService.open('bulk-action-modal', {
        ids: selectedIds,
        bulkAction: actionType,
      })
    },
  }
}
</script>
