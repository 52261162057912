<template>
  <div class="pivot-table-wrapper">
    <div class="configuration flex flex-row" v-if="allowSetupChanges">
      <div class="flex-1 pl-2">
        <label for="row-dimensions-dropdown" class="block">Row Dimensions</label>
        <multiselect
          id="row-dimensions-dropdown"
          v-model="selectedRowDimensions"
          label="text"
          track-by="text"
          :multiple="true"
          placeholder="Select Row Dimensions"
          :options="selectableRowDimensions">
        </multiselect>
      </div>
      <div class="flex-1 pl-2">
        <label for="column-dimension-dropdown" class="block">Column Dimension (Optional)</label>
        <multiselect
          id="column-dimension-dropdown"
          v-model="selectedColumnDimension"
          label="text"
          track-by="text"
          placeholder="Select Column Dimension (optional)"
          :options="selectableRowDimensions">
        </multiselect>
      </div>
      <div class="flex-1 pl-2">
        <label for="selected-metrics" class="block">Metrics</label>
        <multiselect
          id="selected-metrics"
          v-model="selectedMetrics"
          label="text"
          track-by="text"
          :multiple="true"
          placeholder="Select Metrics"
          :options="selectableMetrics">
        </multiselect>
      </div>
    </div>

    <div class="table-wrapper">
      <v-data-table
        :headers="selectedHeaders"
        :items="currentData"
        item-key="name"
        :items-per-page="25"
        :disable-filtering="true" />
    </div>
  </div>
</template>

<script>

import { debounce, each, groupBy, isNull, map, uniq } from 'lodash-es'
import Multiselect from 'vue-multiselect'

import { VDataTable } from 'vuetify/lib'

export default {
  name: 'pivot-table',
  components: {
    Multiselect,
    VDataTable,
  },
  data () {
    return {
      selectedRowDimensions: [],
      selectedColumnDimension: null,
      selectedMetrics: [],
      data: [],
      search: '',
    }
  },
  computed: {
    selectedColumns () {
      // need row dimensions then
      if (isNull(this.selectedColumnDimension)) {
        return this.selectedRowDimensions.concat(this.selectedMetrics)
      } else if (this.selectedMetrics.length === 1) {
        return this.selectedRowDimensions.concat(
          uniq(this.data.map(d => d[this.selectedColumnDimension.value])).map(x => ({
            value: x,
            text: x,
          }))
        )
      } else {
        return this.selectedRowDimensions
      }
    },
    selectedHeaders () {
      return this.selectedColumns
    },
    selectableMetrics () {
      return this.metrics
    },
    selectableRowDimensions () {
      return this.dimensions
    },
    currentData () {
      if (this.isCohortMode) {
        var that = this
        let cohortData = groupBy(that.data, (d) => [that.selectedRowDimensions.map(x => d[x.value])])
        let cohortColumns = uniq(that.data.map(d => d[that.selectedColumnDimension.value]))

        const results = map(cohortData, (arr, key) => {
          let d = {}
          each(that.selectedRowDimensions, row => {
            d[row.value] = arr[0][row.value]
          })
          cohortColumns.forEach(col => {
            const v = arr.find(record => {
              return record[that.selectedColumnDimension.value] === col
            })
            if (v) {
              d[col] = v[ that.selectedMetrics[0].value ]
            } else {
              d[col] = null
            }
          })
          return d
        })
        return results
      } else {
        return this.data
      }
    },
    isCohortMode () {
      return !isNull(this.selectedColumnDimension) && this.selectedMetrics
    }
  },
  watch: {
    selectedMetrics () {
      this.debouncedFetchData()
    },
    selectedRowDimensions () {
      this.debouncedFetchData()
    },
    selectedColumnDimension () {
      this.debouncedFetchData()
    }
  },
  methods: {
    fetchData () {
      this.dataFetcher(this.selectedRowDimensions, this.selectedColumnDimension).then((d) => {
        this.data = d
      })
    },
  },
  created () {
    this.debouncedFetchData = debounce(this.fetchData, 500)
    this.selectedRowDimensions = this.initialRowDimensionValues.map(v => this.dimensions.find(d => d.value === v)).filter(x => !!x)
    this.selectedColumnDimension = this.dimensions.find(d => d.value === this.initialColumnDimensionValue) || null
    this.selectedMetrics = this.initialMetricValues.map(v => this.metrics.find(d => d.value === v)).filter(x => !!x)
  },
  props: {
    dimensions: {
      type: Array,
      required: true,
    },
    metrics: {
      type: Array,
      required: true
    },
    dataFetcher: {
      type: Function,
      required: true,
    },
    initialRowDimensionValues: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    initialColumnDimensionValue: {
      type: String,
      required: false,
      default: null
    },
    initialMetricValues: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    allowSetupChanges: {
      type: Boolean,
      required: false,
      default: true,
    }
  }
}
</script>

<style>
</style>