import Bluebird from 'bluebird';
import pRetry from 'p-retry';

/**
 * PromiseHandler is a service offers timeout and retrying capabilites to promises
 */

class PromiseHandler {

  static maxRetries = 999999; // just a big number. Infinity and MAX_SAFE_INTEGER are not supported by p-retry

  static defaultOptions = {
    timeoutInMs: 1000, // promise execution timeout
    retries: 5,
    minTimeout: 1000, // min time between retries
    maxTimeout: 5000, // max time between retries
    factor: 2, // Exponential backoff factor
    shouldRetry: () => true,
    nextDelay: (minTimeout, maxTimeout, factor, attemptNumber) => Math.min(minTimeout * Math.pow(factor, error.attemptNumber - 1), maxTimeout),
    onFailedAttempt: error => PromiseHandler.#defaultOnFailedAttempt(error), // the default p-retry function is being used only to call the onFailedAttemptEnriched function so we have the nextDelay. It is preferable to override onFailedAttemptEnriched instead of this function
    onFailedAttemptEnriched: (error, nextDelay) => console.warn(`Error calling function! error -> ${error} - Retrying in ${nextDelay}...`),
  };

  static async call(asyncFn, options = {}) {

    // Merge default options with user-provided options
    const finalOptions = { ...PromiseHandler.defaultOptions, ...options };

    finalOptions.onFailedAttempt = options.onFailedAttemptEnriched && !options.onFailedAttempt ?
                                    PromiseHandler.#getUpdatedOnFailedAttemptFunction(options, finalOptions) :
                                    finalOptions.onFailedAttempt;

    finalOptions.retries = Math.min(finalOptions.retries, PromiseHandler.maxRetries);
    finalOptions.maxTimeout = Math.max(finalOptions.minTimeout, finalOptions.maxTimeout);

    const asyncFnWithTimeoutFn = async () => Bluebird.resolve(asyncFn()).timeout(finalOptions.timeoutInMs);
    return pRetry(asyncFnWithTimeoutFn, finalOptions)
  }

  static async callMany(asyncFunctions, errorHandlingOptions = {}, concurrency = 5) {
    const lambda = asyncFn => PromiseHandler.call(asyncFn, errorHandlingOptions);
    return Bluebird.map(asyncFunctions, lambda, { concurrency });
  }

  static #nextDelay(minTimeout, maxTimeout, factor, attemptNumber) {
    return Math.min(minTimeout * Math.pow(factor, attemptNumber - 1), maxTimeout);
  }

  static #getUpdatedOnFailedAttemptFunction(options, finalOptions){
    return error => {
      const nextDelay = PromiseHandler.#nextDelay(finalOptions.minTimeout, finalOptions.maxTimeout, finalOptions.factor, error.attemptNumber);
      return options.onFailedAttemptEnriched(error, nextDelay);
    }
  }

  static #defaultOnFailedAttempt(error){
    const minTimeout = PromiseHandler.defaultOptions.minTimeout;
    const maxTimeout = PromiseHandler.defaultOptions.maxTimeout;
    const factor = PromiseHandler.defaultOptions.factor;
    const attemptNumber = error.attemptNumber;
    const nextDelay = PromiseHandler.#nextDelay(minTimeout, maxTimeout, factor, attemptNumber);
    PromiseHandler.defaultOptions.onFailedAttemptEnriched(error, nextDelay);
  }
}

export default PromiseHandler;
