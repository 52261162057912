<template>
  <div class="direct-messaging-container">
    <div class="direct-messaging-panel-container">
      <div class="direct-messaging-panel-title">Sorry, you can't access Find & Connect</div>
      <div class="direct-messaging-panel-content">
        <v-row>
          <v-col cols="12">
            <span>You have opted-out of our Community. If you would like to revert this decision, you can do so by checking the box below and clicking the Submit button.</span>
          </v-col>
        </v-row>
        <v-row v-if="alertVisible">
          <v-col cols="12">
            <v-alert v-model="alertVisible" type="error" dismissible>
              <span class="">There was an unexpected error trying to opt you in. Please try again or contact support</span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <input id="optInCheckbox" type="checkbox" v-model="optInCheckbox" class="w-4 h-4 text-blue-600 mb-2 mr-2 ml-0.5 bg-white border-solid b-1 border-gray-400 rounded focus:ring-blue-500 focus:ring-2">
              <label for="optInCheckbox">Include my Community Profile in Find & Connect</label>
            </div>
            <span>By checking this box, you are opting in, and agree to share the information in this form with other Launchers via Find and Connect.</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <button @click="handleOptInButton" class="btn-primary" :disabled="!optInCheckbox">Submit</button>
            </div>
          </v-col>
        </v-row>
      </div>

    </div>
  </div>
</template>

<script>
import axios from '@dataServices/axios'
import User from "@services/directMessaging/user";
import { VCol, VRow, VAlert, VCheckbox } from 'vuetify/lib'

const baseUrl = window.location.origin

export default {
  name: 'direct-messaging-opted-out',
  components: {
    VAlert,
    VCol,
    VRow,
    VCheckbox,
  },
  props: {
    currentUser: { type: User, required: true },
  },
  data: () => ({
    alertVisible: false,
    optInCheckbox: false,
  }),
  mounted () {

  },
  destroy() {

  },
  computed: {

  },
  methods: {
    handleOptInButton() {
      axios.put(baseUrl + `/api/direct_messages/users/${this.currentUser.userId}/opt_in`)
      .then(response => this.processResponse(response))
      .catch(error => this.processError(error));
    },
    processResponse(response) {
      if (response.status === 200) {
        window.location.reload();
      } else {
        this.alertVisible = true;
      }
    },
    processError(error) {
      console.error(error);
      this.alertVisible = true;
    }
  },
  watch: {

  }

}

</script>

<style lang="scss" scoped>



</style>
