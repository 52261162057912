<template>
  <div class="direct-messaging-container">
    <div class="direct-messaging-panel-container">
      <div class="direct-messaging-panel-title">Announcements</div>
      <div class="direct-messaging-panel-content">
        <div v-for="(announcement, index) in announcements" class="announcement-container">
          <span class="announcement-date">{{formatDate(announcement.created_at)}}</span>
          <MarkdownIt :content="announcement.message" class="announcement-message clear-base-el aligned-list"></MarkdownIt>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@dataServices/axios'
import dayjs from "dayjs";
import MarkdownIt from 'markdown-it-vue'

const baseUrl = window.location.origin

export default {
  name: 'announcements-panel',
  components: {
    MarkdownIt
  },
  props: {
    markAnnouncementsReadCallback: { type: Function, default: () => {}, required: false }
  },
  data: () => ({
    announcements: [],
  }),
  async mounted () {
    this.announcements = await this.fetchAnnouncements();
    this.markAnnouncementsAsRead();
  },
  destroy() {

  },
  computed: {

  },
  methods: {
    async fetchAnnouncements() {
      const request = new FetchAnnouncementsRequest(undefined, 200); //defining a high perPage number for now. Implement a bottomless scroll later. Example on DirectMessagingCurrentConversation.vue
      return axios.get(baseUrl + `/api/announcements/announcements`, request)
      .then(response => response.data.records)
      .catch(error => this.processError(error));
    },
    formatDate(date) {
      return dayjs(date).format('MMMM D');
    },
    markAnnouncementsAsRead() {
      axios.post(baseUrl + `/api/announcements/announcements/mark_as_read`)
      .then(response => this.markAnnouncementsReadCallback())
      .catch(error => this.processError(error));
    },
    processError(error) {
      console.error("Was not able to mark announcements as read!", error);
    }
  },
  watch: {

  }
}

class FetchAnnouncementsRequest {
  constructor(page = 1, perPage = 15, sortBy = 'created_at', sortDirection = 'desc') {
    this.params = {
      page: page,
      per_page: perPage,
      sort_by: sortBy,
      sort_direction: sortDirection
    }
  }
}

</script>

<style lang="scss" scoped>
.announcement-container {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.announcement-date {
  display: block;
  font-size: 12px;
  color: #9e9e9e;
}

.announcement-message {
  display: block;
  font-size: 16px;
  color: #000;
}


</style>
