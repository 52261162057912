<template>
  <div>
    <media-slider
      :data="contents"
      @selected="contentSelected"
    />
  </div>
</template>

<script>
import CourseStepContent from '@models/CourseStepContent'
import ModalService from '@services/ModalService'
import MediaSlider from '@components/MediaSlider.vue'

export default {
  name: 'course-step-content-slider',
  components: {
    MediaSlider,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      contents: this.data.map(d => (new CourseStepContent(d))),
    }
  },
  methods: {
    contentSelected (content) {
      ModalService.open('course-step-content-modal', {
        contents: this.contents,
        initialSelectionId: content.id,
      })
    },
  },
}
</script>
