import axios from '@dataServices/axios'

/*
reasoning for what we are doing here:
use case: users paste links on the chats, and we need to be able to embed the images and videos when they do so
problem: Getstream says they provide embeddable iframes, but they are quite fallible
error case: youtube has at least 2 types of video urls: standard and short. Getstream only returns correctly the embeddable url for the short one.
            Did not even test for other platforms because of the red flag of not working 100% for youtube.

possible solution 1: manually parse the url to verify if it is an standard or embed url. If it is embed, then use it on the iframe, otherwise, convert it to embed before using it.
problems with solution 1:
 - it is not scalable. We would need to manually parse the url for each platform, and for each platform, we would need to manually convert the url to embed.
 - if the platform changes the url format, we would need to update our code.
 - there are platforms that do not have a predictable embed url format (like facebook, twitter and instagram), so we would not be able to support them

possible solution 2: use a library that does the parsing and conversion for us.
problem with solution 2: we would need to find a library that does the parsing and conversion for us. The only one I found was embed-video, which the last update was 6 years ago and does not work with node 20.

possible solution 3: Several platforms follow the oEmbed convention, which is a standard for embedding content. We could call each platform so they convert the url to embed for us.
problems with solution 3:
 - we would need to manually parse the urls to identify the platforms
 - we would need to map all platforms we want to support, and maintain that code

possible solution 4: use a 3rd party service that does the parsing and conversion for us.
problems with solution 4:
 - did not find a free one
 - if we accidentally send an embed url, the api throws an error that is not clear on why it failed. So we have to somehow identify if the url is embed or not before sending it to the api,
   which is fine for platforms with predictable url, but not for platforms without predictable url.

so this is the algorithm that we intend on using for now:
when processing GetStream attachments (which are the links that users paste on the chat that have been identified by Getstream as being videos or images)
if isFromPlatformWithPredictableUrl
then if isStandardUrl
     then call iframely to convert
     else build iframe with embed url
else
  call iframely to convert
  if error, assume the url is embed and build iframe with it
 */

async function toEmbed(url) {
    try {
        const predictablePlatforms = [new Youtube(), new Vimeo(), new Twitch(), new DailyMotion()];
        const predictablePlatform = predictablePlatforms.find(platform => platform.isUrlFromPlatform(url));

        if (predictablePlatform) {
            return predictablePlatform.isStandardUrl(url) ?
                    await fetchEmbed(url) :
                    null;
        }
        else {
            return await fetchEmbed(url);
        }

    }
    catch (error) {
        console.error('Error converting url to embed url!', url, error);
        return null;
    }
}


async function fetchEmbed(standardUrl) {
    const baseUrl = window.location.origin;
    const request = {params: {standard_url: standardUrl }};

    return await axios.get(baseUrl + "/api/direct_messages/media_links", request)
        .then(response => response.data.embeddable_link)
        .catch(error => {
            console.error('Error fetching media links!', request, error);
            throw error;
        })
}


class Platform {
    isUrlFromPlatform(url) {
        throw new Error('This class have to be extended and the method implemented!');
    }

    isStandardUrl(url) {
        throw new Error('This class have to be extended and the method implemented!');
    }
}

class Youtube extends Platform {

    isUrlFromPlatform(url) {
        return url.includes('youtube.com') || url.includes("youtu.be");
    }

    isStandardUrl(url) {
        return this.isUrlFromPlatform(url) && !url.includes('embed');
    }
}

class Vimeo extends Platform {
    isUrlFromPlatform(url) {
        return url.includes('vimeo.com');
    }

    isStandardUrl(url) {
        return this.isUrlFromPlatform(url) && !url.includes('player');
    }
}

class Twitch extends Platform {
    isUrlFromPlatform(url) {
        return url.includes('twitch.tv');
    }

    isStandardUrl(url) {
        return this.isUrlFromPlatform(url) && !url.includes('embed');
    }
}

class DailyMotion extends Platform {
    isUrlFromPlatform(url) {
        return url.includes('dailymotion.com');
    }

    isStandardUrl(url) {
        return this.isUrlFromPlatform(url) && !url.includes('embed');
    }
}

export default toEmbed
