<template>
  <div class="coach-action-table">
    <div class="configuration flex flex-row pt-3">
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Program</label>
        <multiselect
            v-model="tableFilter.program"
            track-by="id"
            label="name"
            :options="filterOptions.programs">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Student</label>
        <input
            id="student-filter"
            type="text"
            placeholder="Filter students"
            v-model="tableFilter.student_name_search"
            class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out"
        />
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="student-filter" class="block">Course</label>
        <multiselect
            v-model="tableFilter.course"
            track-by="id"
            label="name"
            :options="filterOptions.courses">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="group-dropdown" class="block">Group</label>
        <multiselect
            v-model="tableFilter.group"
            track-by="id"
            label="name"
            :options="filterOptions.groups">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="phase-dropdown" class="block">Phase</label>
        <multiselect
            v-model="tableFilter.phase"
            track-by="name"
            label="name"
            :options="filterOptions.phases">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="coach-dropdown" class="block">Coach</label>
        <multiselect
            v-model="tableFilter.coach"
            track-by="id"
            label="name"
            :options="filterOptions.coaches">
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="action-types-dropdown" class="block">Task State</label>
        <multiselect
            v-model="tableFilter.taskStates"
            track-by="type"
            label="name"
            :multiple="true"
            :options="filterOptions.taskStates">
          <template #selection="{ values, isOpen }">
            <span v-if="values.length === 1">{{ values[0].name }}</span>
            <span v-else-if="values.length > 1">{{ values.length }} selected</span>
          </template>
        </multiselect>
      </div>
      <div class="flex-1 pr-2 max-w-xs">
        <label for="action-types-dropdown" class="block">Task Type</label>
        <multiselect
            v-model="tableFilter.taskType"
            track-by="type"
            label="name"
            :options="filterOptions.taskTypes">
        </multiselect>
      </div>
    </div>
    <div class="table-wrapper">
      <v-data-table
          :headers="tableHeaders"
          :items="allItems"
          item-key="id"
          :items-per-page="perPage"
          :loading="loading"
          loading-text="Loading..."
          :page="tablePagination.currentPage"
          :server-items-length="totalNumberOfItems"
          :sort-by="sortColumn"
          :sort-desc="sortDesc"
          @click:row="openModal"
          @update:page="fetchTableDataDebounced"
          @update:sort-by="handleSortColumn"
          @update:sort-desc="handleSortDirection">

        <template v-slot:item.icons="{ item }">
          <div class="w-6 flex">
            <TaskTypeIcon :taskType="item.task_type"/>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.state="{ item }">
          <div class="w-6 flex">
            <TaskStatusIcon :taskState="item.state"/>
          </div>
        </template>
        <template v-slot:item.course_step_name="{ item }">
          <i :class="{fa: true, [item.faIcon]: true}" class="pr-2" />
          {{ item.course_step_name }}
        </template>
        <template v-slot:item.metadata_description="{ item }">
          <div class="w-28 text-ellipsis overflow-hidden text-nowrap">
            {{ item.metadata_description }}
          </div>
        </template>
        <template v-slot:item.coach_user_name="{ item }">
          {{ item.coach_user_name }}
        </template>
        <template v-slot:item.student_user_name="{ item }">
          {{ item.student_user_name }}
        </template>
        <template v-slot:item.waiting_for_business_hours="{ item }">
          {{ formatWaitingHours(item.waiting_for_business_hours) }}
        </template>
        <template v-slot:item.waiting_for_absolute_hours="{ item }">
          {{ formatWaitingHours(item.waiting_for_absolute_hours) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionModalDropdown
              :user="item.student_user_id"
              :groupMembershipId="item.student_group_membership_id"
              :groupId="item.group_id"
              :courseStepId="item.course_step_id"
          />
        </template>

        <template v-slot:no-data>
          <div class="text-center text-lg py-8 text-gray-500">
            No tasks found!
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from '@dataServices/axios'
import dayjs from '@services/dayjsWrapper'
import ModalService from '@services/ModalService'
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import TaskTypeIcon from '@components/tasks/TaskTypeIcon.vue'
import TaskSequenceIcon from '@components/tasks/TaskSequenceIcon.vue'
import EventBus from '@services/GlobalEventBus'
import { WindowVariables } from '@utils'
import TaskStatusIcon from '@components/tasks/TaskStatusIcon.vue'

import { VDataTable, VTextField } from 'vuetify/lib'
import FlashMessages from "@services/FlashMessageService";
import {debounce} from "lodash-es";

const baseUrl = window.location.origin

const tableHeaders = [
  { text: 'Icons', value: 'icons', sortable: false, class: 'pl-2' },
  { text: 'State', value: 'state', sortable: true },
  { text: 'Student Name', value: 'student_user_name', sortable: true },
  { text: 'Group', value: 'group_name', sortable: false },
  { text: 'Coach', value: 'coach_user_name', sortable: false },
  { text: 'Phase', value: 'phase_name', sortable: false },
  { text: 'Step Name', value: 'course_step_name' },
  { text: 'Description', value: 'metadata_description', sortable: false },
  { text: 'Created at', value: 'created_at', sortable: true },
  { text: 'Time Waiting (Biz Hrs)', value: 'waiting_for_business_hours', sortable: false },
  { text: 'Time Waiting (Clock Hrs)', value: 'waiting_for_absolute_hours', sortable: false},
  { text: 'Actions', value: 'actions', sortable: false },
]

export default {
  name: 'tasks-table',
  components: {
    VTextField,
    ActionModalDropdown,
    Multiselect,
    TaskTypeIcon,
    TaskStatusIcon,
    TaskSequenceIcon,
    VDataTable,
  },
  props: {
    partnerId: { type: Number, required: true },
    courseId: { type: Number, required: false },
  },
  data () {
    return {
      commonFiltersResponse: {},
      activeTab: null,
      tableHeaders,
      allItems: [],
      tableFilter: {
        student_name_search: null,
        group: null,
        phase: null,
        coach: null,
        taskType: null,
        course: null,
        program: null,
        taskStates: [],
      },
      filterOptions: {
        groups: [],
        phases: [],
        coaches: [],
        taskStates: [],
        taskTypes: [],
        courses: [],
        programs: [],
      },
      tablePagination: {
        total: 0,
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        total_pages: 0,
        is_first_page: true,
        is_last_page: true,
        is_out_of_range: false,
        records_per_page: 0
      },
      perPage: 15,
      sortColumn: ['created_at'],
      sortDesc: [true],
      loading: true,
      unsubscribeListenerFunctions: [],
    }
  },
  async mounted () {
    const initData = await this.fetchInitData()
    this.filterOptions.taskTypes = initData.filter_options?.task_types
    this.filterOptions.taskStates = initData.filter_options?.task_states
    this.filterOptions.coaches = initData.filter_options?.coaches
    this.filterOptions.phases = initData.filter_options?.phase_names
    this.filterOptions.groups = initData.filter_options?.groups
    this.filterOptions.courses = initData.filter_options?.courses
    this.filterOptions.programs = initData.filter_options?.programs

    this.tableFilter.taskStates = this.filterOptions.taskStates.filter(t => t.type === 'open' || t.type === 'in_progress')

    this.fetchTableDataDebounced(1)
    this.unsubscribeListenerFunctions = [
        EventBus.subscribe('refresh-task-list', (d) => { this.fetchTableDataDebounced(this.tablePagination.currentPage) }),
    ]
    this.loading = false
  },
  async beforeDestroy () {
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
  },
  computed: {
    totalNumberOfItems () {
      return this.tablePagination && this.tablePagination.total?
          this.tablePagination.total:
          0
    },
    getSortBy(){
      return Array.isArray(this.sortColumn) && this.sortColumn.length > 0 ?
          this.sortColumn[0] :
          null;
    },
    getSortDir(){
      const sortDescFirstElement = Array.isArray(this.sortDesc) && this.sortDesc.length > 0 ? this.sortDesc[0] : null;
      return sortDescFirstElement === true ? 'desc' :
          sortDescFirstElement === false ? 'asc' :
              null;
    },
  },
  methods: {
    async fetchInitData() {
      try {
        const response = await axios.get(baseUrl + '/api/v2/bff/coach_tasks_table/init_data', {params: {partner_id: this.partnerId}})
        return response.data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    fetchTableData(pageToLoad = 1) {
      this.loading = true
      const request = this.getRequest(pageToLoad)
      return axios.get(baseUrl + '/api/v2/bff/coach_tasks_table/fetch_table_data', request)
            .then(response => this.processResponse(response))
            .catch(error => this.processResponseError(request, error))
    },
    fetchTableDataDebounced: debounce(function(pageToLoad) { this.fetchTableData(pageToLoad); }, 250),
    processResponse (response) {
      this.allItems = response.data.records
      this.tablePagination = response.data.pagination
      this.loading = false
    },
    processResponseError (request, error) {
      this.loading = false
      console.error('Failed to fetch data:', request, this.errorMessage, error.response?.data, error.response?.status);
      FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
    },
    getRequest(pageToLoad = 1){
      const params = {
        partner_id: this.partnerId,
        page: pageToLoad,
        per_page: this.perPage,
        sort_by: this.getSortBy,
        sort_direction: this.getSortDir,
        task_states: this.tableFilter.taskStates.map(t => t.type),
        task_type: this.tableFilter.taskType?.type,
        group_id: this.tableFilter.group?.id,
        program_id: this.tableFilter.program?.id,
        course_id: this.tableFilter.course?.id,
        coach_id: this.tableFilter.coach?.id,
        phase_name: this.tableFilter.phase?.name,
        student_name_search: this.tableFilter.student_name_search?.trim()?.length > 0 ? this.tableFilter.student_name_search.trim() : null
      };

      const filteredParams = Object.fromEntries(
          Object.entries(params).filter(([key, value]) => value != null)
      )

      return { params: filteredParams }
    },
    formatDate(dateString) {
      return dayjs(dateString).tz(WindowVariables.timezone).format('M/D/YY')
    },
    formatWaitingHours(waitingHours) {
      return waitingHours?.toFixed(1)
    },
    openModal (item) {
      const payload = { studentGroupMembershipId: item.student_group_membership_id, selectedTaskId: item.id }
      ModalService.open('tasks-per-student-modal', payload)
    },
    handleSortColumn(column) {
      this.sortColumn = column;
      this.fetchTableDataDebounced(1);
    },
    handleSortDirection(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchTableDataDebounced(1);
    },
  },
  watch: {
    tableFilter: {
      deep: true,
      handler(newVal) {
        this.fetchTableDataDebounced(1)
      }
    },
  }
}
</script>

<style lang="scss">
.coach-action-table .v-data-footer__select {
  display: none;
}

.coach-action-table .v-window {
  overflow: initial;
}

.coach-action-table .v-data-table__wrapper {
  overflow: initial;

  .description {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
