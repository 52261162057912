<template>
  <div v-if="shouldShowPacingBar">
    <div class="text-center text-lg font-semibold p-4" v-if="nextPhaseOpensFormatted">
      {{ `Next Phase opens on ${nextPhaseOpensFormatted}. Make sure you complete this current phase prior to that.` }}
    </div>
  </div>
</template>

<script>
  import { isNull } from 'lodash-es'
  import { WindowVariables } from '@utils'
  import dayjs from '@services/dayjsWrapper'

  export default {
    name: 'student-pacing-app-v2',
    data () {
      return {
        fgsCourseStep: null,
        studentPhaseProgress: null,
        timezone: null,
        nextPhaseOpensAt: null,
      }
    },
    created() {

      this.timezone = WindowVariables.timezone
      this.fgsCourseStep = WindowVariables.fgsCourseStep
      this.studentPhaseProgress = WindowVariables.latestStudentPhaseProgress && WindowVariables.latestStudentPhaseProgress.phase_closes_at ?
                                  WindowVariables.latestStudentPhaseProgress :
                                  null

      this.nextPhaseOpensAt = WindowVariables.nextPhaseOpensAt

      console.info('student-pacing-app-v2 created', this.studentPhaseProgress, this.fgsCourseStep, this.nextPhaseOpensAt, WindowVariables)
    },
    computed: {
      shouldShowPacingBar() {
        // return this.studentPhaseProgress && this.fgsCourseStep && this.studentPhaseProgress.sequence_id === this.fgsCourseStep.sequence_id
        return true;
      },
      isHidden(){
        // return isNull(this.studentPhaseProgress) || this.isPhaseClosed
        return false;
      },
      topPhrase(){
        return this.getTopPhrase()
      },
      bottomPhrase(){
        return this.getDescriptivePhrase()
      },
      bufferDays(){
        return this.studentPhaseProgress.completed_phase_buffer_days
      },
      relativePace(){
        return this.studentPhaseProgress.completed_pace_days
      },
      numCompletedSteps(){
        return this.studentPhaseProgress.completed_assignments_count
      },
      numStepsRemaining(){
        return this.studentPhaseProgress.total_steps - this.studentPhaseProgress.completed_assignments_count
      },
      numTotalSteps(){
        return this.studentPhaseProgress.total_steps
      },
      phaseClosesAtMoment(){
        return dayjs.utc(this.studentPhaseProgress.phase_closes_at).tz(WindowVariables.timezone)
      },
      nextPhaseOpensAtMoment(){
        return this.studentPhaseProgress.next_phase_opens_at ? dayjs.utc(this.studentPhaseProgress.next_phase_opens_at) : null
      },
      nextPhaseOpensFormatted(){
        return this.nextPhaseOpensAt ? dayjs.utc(this.nextPhaseOpensAt).format('M/D/YY'): null
      },
      isPhaseClosed(){
        return dayjs.utc().isAfter(this.phaseClosesAtMoment)
      },
      isNextPhaseOpen(){
        return this.nextPhaseOpensAtMoment ? dayjs.utc().isAfter(this.nextPhaseOpensAtMoment) : null
      },
      numDaysLeftInPhase(){
        return this.phaseClosesAtMoment && dayjs(this.phaseClosesAtMoment).diff(dayjs().tz(this.timezone), 'days', true)
      },
      computedPercentage(){
        return '0%'
      }
    },
    methods: {
      getDescriptivePhrase() {
        if (this.bufferDays > 10.0 && this.numStepsRemaining <= 3.0) {
          // when bufferDays > 10 && when less than 3 or less steps left
          return this.$t('pacing.descriptive_phrases.phrase1', { num_steps_remaining: Math.floor(this.numStepsRemaining) })
        } else if (this.bufferDays > 10 && this.numStepsRemaining > 3.0) {
          // when bufferDays > 10 && > 3 steps left
          return this.$t('pacing.descriptive_phrases.phrase2', { num_completed_steps: this.numCompletedSteps, num_total_steps: this.numTotalSteps })
        } else if (this.bufferDays < 10.0 && this.bufferDays > -7.0) {
          // when bufferDays < 10 && bufferDays > -7
          return this.$t('pacing.descriptive_phrases.phrase3', { num_completed_steps: this.numCompletedSteps, num_total_steps: this.numTotalSteps, num_days_left_in_phase: Math.floor(this.numDaysLeftInPhase) })
        } else if (this.bufferDays <= -7.0) {
          // when bufferDays <= -7
          return this.$t('pacing.descriptive_phrases.phrase4', { num_steps_remaining: Math.floor(this.numStepsRemaining), num_days_left_in_phase: Math.floor(this.numDaysLeftInPhase) })
        }
      },
      getTopPhrase() {
        if (this.relativePace > 7 && this.bufferDays > 7) {
          let txt = this.$t('pacing.top_phrases.time_to_go_deeper')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        } else if (this.relativePace >= 0 && this.bufferDays > 7) {
          let txt = this.$t('pacing.top_phrases.finish_on_time')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        } else if (this.bufferDays > 7 && this.relativePace < 0) {
          // TODOO: why is there an extra one?
          let txt = this.$t('pacing.top_phrases.finish_on_time')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        } else if (this.bufferDays > 0.0 && this.bufferDays <= 7.0) {
          let txt = this.$t('pacing.top_phrases.finish_just_before', { closes_on: this.phaseClosesAtMoment.format('M/D/YY'), num_days_left_in_phase: Math.floor(this.numDaysLeftInPhase) })
          return txt
        } else if (this.bufferDays > -3.0 && this.bufferDays <= 0.0) {
          let txt = this.$t('pacing.top_phrases.time_to_complete_and_submit')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        } else if (this.bufferDays > -7.0 && this.bufferDays <= -3.0) {
          let txt = this.$t('pacing.top_phrases.time_to_complete_and_submit')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        } else if (this.bufferDays <= -7) {
          let txt = this.$t('pacing.top_phrases.in_danger')
          txt += this.getStartOfPhaseOrEndOfPhaseText()
          return txt
        }
      },
      getStartOfPhaseOrEndOfPhaseText() {
        // don't show any phrase for when there is no next phase w/ open/close dates
        if (isNull(this.isNextPhaseOpen)) {
          return ''
        } else if (this.isNextPhaseOpen) {
          return this.$t('pacing.phase_closes_on_statement', { closes_on: this.phaseClosesAtMoment.format('M/D/YY') })
        } else {
          return this.$t('pacing.phase_opens_on_statement', { opens_on: this.nextPhaseOpensAtMoment.format('M/D/YY') })
        }
      },
    },
  }

</script>

<style lang="scss">

</style>