<template>
  <div class="partner-roster-component">
    <div class="bg-amber-50 border-l-4 border-amber-400 p-4 mb-4">
      <div class="flex">
        <div class="shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5 text-amber-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-amber-700">
            All Launcher data and contact information are to not be exported or copied out of the platform as per the terms and conditions agreement.
          </p>
        </div>
      </div>
    </div>

    <div class="users-list-component">
      <div class="filter-bar">
        <div class="grid grid-flow-row-dense sm:grid-cols-4 gap-3">
          <div class="col-span-1">
            <label for="program-dropdown" class="block">Program</label>
            <multiselect
              id="program-dropdown"
              v-model="filter.program_id"
              label="label"
              track-by="id"
              :options="allPrograms">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="course-dropdown" class="block">Course</label>
            <multiselect
              id="course-dropdown"
              v-model="filter.courses"
              label="name"
              track-by="id"
              :multiple="true"
              :options="allCourses">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="group-dropdown" class="block">Group</label>
            <multiselect
              id="group-dropdown"
              v-model="filter.group_id"
              label="label"
              track-by="id"
              :options="allGroups">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="phase-dropdown" class="block">Phase</label>
            <multiselect
              id="phase-dropdown"
              v-model="filter.phase"
              :options="allPhases">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="state-dropdown" class="block">State</label>
            <multiselect
              id="state-dropdown"
              v-model="filter.state"
              label="name"
              track-by="name"
              :options="stateOptions">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="engagement-status-dropdown" class="block">Engagement Status</label>
            <multiselect
              id="engagement-status-dropdown"
              v-model="filter.engagement_status"
              label="name"
              track-by="name"
              :options="[
                { name: 'Invited', value: 'invited' },
                { name: 'Engaged', value: 'engaged' },
                { name: 'Disengaged', value: 'disengaged' },
                { name: 'Discontinued', value: 'discontinued' },
                { name: 'Completed', value: 'completed' },
              ]">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="industry-dropdown" class="block">Industry</label>
            <multiselect
              id="industry-dropdown"
              v-model="filter.industry"
              label="label"
              track-by="value"
              :options="allIndustries">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="tags-dropdown" class="block">Tags</label>
            <multiselect
              id="tags-dropdown"
              v-model="filter.tags"
              label="label"
              track-by="label"
              :multiple="true"
              :options="allTags">
            </multiselect>
          </div>
          <div class="col-span-1">
            <label for="sort-order" class="block">Sort Order</label>
            <multiselect
              id="sort-order"
              v-model="filter.sort"
              label="label"
              track-by="value"
              :options="sortOrderOptions">
            </multiselect>
          </div>

          <div class="col-span-1">
            <label for="name-filter" class="block">Name</label>
            <input type="text" class="w-full sm:text-sm sm:leading-5 w-full h-10 px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out" id="name-filter" v-model="filter.name" placeholder="Enter name"/>
          </div>
          <div class="col-span-1">
            <label for="phone-number-filter" class="block">Phone Number</label>
            <input type="text" class="w-full sm:text-sm sm:leading-5 w-full h-10 px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out" id="phone-number-filter" v-model="filter.phone_number" placeholder="Enter phone number"/>
          </div>
          <div class="col-span-1">
            <label for="email-filter" class="block">Email</label>
            <input type="text" class="w-full sm:text-sm sm:leading-5 w-full h-10 px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out" id="email-filter" v-model="filter.email" placeholder="Enter email"/>
          </div>
          <div class="col-span-4 text-right items-center mb-3 partner-button-group">
            <a :href="exportUrl" v-if="showExportButton" target="_blank" class="btn btn-primary mb-0 mt-8">Export XLSX</a>
            <button @click="resetFilter" class="btn btn-secondary mt-8">Reset</button>
            <button @click="reloadData" class="btn btn-primary mt-8">Filter</button>
          </div>
        </div>
      </div>
      <b-table striped hover :items="vm.allUsers" :fields="fields">
        <template #cell(first_name)="data">
          <a :href="userUrl(data.item.id)">{{ data.value }}</a>
        </template>

        <template #cell(last_name)="data">
          <a :href="userUrl(data.item.id)">{{ data.value }}</a>
        </template>

        <template #cell(tags)="data">
          <span v-if="data.item.tags">
            <span v-for="tag in data.item.tags.split(',')" :key="tag.name" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">{{ tag }}</span>
          </span>
        </template>

        <template #cell(actions)="data">
          <ActionModalDropdown :user="data.item" :groupMembershipId="data.item.group_membership_id" :groupId="data.item.group_id" />
        </template>
      </b-table>

      <div class="pagination-footer">
        <button class="btn"
          :class="btn.disabled ? 'btn-secondary' : 'btn-primary'"
          v-for="btn in vm.paginationButtonConfiguration"
          :key="btn.type"
          @click="btn.action"
          :disabled="btn.disabled"
        >{{ btn.label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, sortBy } from 'lodash-es'
import Multiselect from 'vue-multiselect'
import Courses from '@dataServices/Courses'
import { getUsersStoreFor } from '@stores/UsersStore'
import { url } from '@services/LinkService'
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import { WindowVariables } from '@utils'

import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  constructor(params) {
    makeObservable(this, {
      allUsers: computed,
      usersLoading: computed,
      pageMetadata: computed,
      paginationButtonConfiguration: computed,
    })

    this.usersStore = getUsersStoreFor(
      params.initialUsersFilter,
      { paginate: true }
    )
    
  }

  get allUsers () {
    return this.usersStore.allRecords
  }

  get usersLoading () {
    return this.usersStore.loading
  }

  get pageMetadata () {
    return this.usersStore.pageMetadata
  }

  get paginationButtonConfiguration () {
    const result = []

    if (this.usersLoading) {
      return []
    }

    if (!this.pageMetadata.is_first_page) {
      result.push({
        type: 'first-page',
        label: 'First',
        action: action(() => {
          return this.usersStore.loadPageNumber(1)
        }),
      })

      result.push({
        type: 'prev-page',
        label: 'Prev',
        action: action(() => {
          return this.usersStore.prevPage()
        })
      })
    }

    const currentPageNum = this.pageMetadata.current_page
    const totalNumbersShown = 5

    let startingIndex = currentPageNum <= parseInt(totalNumbersShown / 2) ? 1 : currentPageNum - 2
    if (this.pageMetadata.total_pages - startingIndex < totalNumbersShown) {
      startingIndex = this.pageMetadata.total_pages - totalNumbersShown + 1
    }
    for (let i = startingIndex; i < startingIndex + totalNumbersShown; i++) {
      if (i > 0 && i <= this.pageMetadata.total_pages) {
        result.push({
          type: `page-number-${i}`,
          label: i,
          action: () => {
            return this.usersStore.loadPageNumber(i)
          },
          disabled: i === currentPageNum,
        })
      }
    }

    if (!this.pageMetadata.is_last_page) {
      result.push({
        type: 'next-page',
        label: 'Next',
        action: action(() => {
          return this.usersStore.nextPage()
        }),
      })

      result.push({
        type: 'last-page',
        label: 'Last',
        action: action(() => {
          return this.usersStore.loadPageNumber(this.pageMetadata.total_pages)
        }),
      })
    }

    return result
  }

}

export default observer({
  name: 'partner-roster-component',
  components: {
    Multiselect,
    ActionModalDropdown,
  },
  data () {
    const defaultProgram = WindowVariables.formSelectOptions.programs.find(p => p.value === 20)
    const baseUsersFilter = {
      role: 'student',
      include_group_memberships_attributes: true,
      sort: 'status|asc,first_name|asc,last_name|asc'
    }

    const stateOptions = [
      { name: 'Active', value: 1 },
      { name: 'Inactive', value: 0 },
    ]

    const sortOrderOptions = [
      { label: 'Status, First/Last Ascending', value: 'status|asc,first_name|asc,last_name|asc', },
      { label: 'Status, First/Last Descending', value: 'status|desc,first_name|desc,last_name|desc', },
      { label: 'Last/First Ascending', value: 'last_name|asc,first_name|asc', },
      { label: 'Last/First Descending', value: 'last_name|desc,first_name|desc', },
      { label: 'First/Last Ascending', value: 'first_name|asc,last_name|asc', },
      { label: 'First/Last Descending', value: 'first_name|desc,last_name|desc', },
      { label: 'Group, First/Last Ascending', value: 'group_name|asc,first_name|asc,last_name|asc', },
      { label: 'Group, Last/First Ascending', value: 'group_name|asc,last_name|asc,first_name|asc', },
      { label: 'Phase, Group, Last/First Ascending', value: 'phase|asc,group_name|asc,last_name|asc,first_name|asc' },
      { label: 'Phase, Group, Last/First Descending', value: 'phase|desc,group_name|desc,last_name|desc,first_name|desc' },
      { label: 'Industry, First/Last Ascending', value: 'industry|asc,first_name|asc,last_name|asc' },
      { label: 'Industry, Last/First Ascending', value: 'industry|asc,last_name|asc,first_name|asc' },
      { label: 'Tags, First/Last Ascending', value: 'tags|desc,first_name|asc,last_name|asc' },
      { label: 'Tags, Last/First Ascending', value: 'tags|desc,last_name|asc,first_name|asc' },
    ]

    const initialFilter = {
      program_id: defaultProgram,
      engagement_status: null,
      group_id: null,
      state: stateOptions[0],
      courses: [],
      phase: null,
      industry: null,
      tags: null,
      sort: sortOrderOptions[0],
    }

    const initialUsersFilter = {
      ...baseUsersFilter,
      state: 1,
      program_id: defaultProgram && defaultProgram.value,
    }

    const viewModelParams = {
      initialFilter: initialFilter,
      initialUsersFilter: initialUsersFilter,
    }

    return {
      baseUsersFilter,
      stateOptions,
      sortOrderOptions,
      allPrograms: WindowVariables.formSelectOptions.programs,
      allCourses: [],
      allPhases: WindowVariables.formSelectOptions.phases,
      allIndustries: WindowVariables.formSelectOptions.user_industries,
      allTags: sortBy(WindowVariables.formSelectOptions.tags, 'label'),
      allGroups: WindowVariables.formSelectOptions.groups,
      filter: initialFilter,
      vm: new ViewModel(viewModelParams),
    }
  },
  computed: {
    showExportButton () {
      return this.isPartnerAdmin || this.isERAdmin
    },
    isPartnerAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'partner_admin')
    },
    isERAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'er_admin')
    },
    fields () {
      let fields = [
        {
          key: 'first_name',
          label: 'First Name',
          sortable: true,
        },
        {
          key: 'last_name',
          label: 'Last Name',
          sortable: true,
        },
        {
          key: 'course_name',
          label: 'Course',
          sortable: false,
        },
        {
          key: 'group_name',
          label: 'Group Name',
          sortable: true,
        },
        {
          key: 'group_membership_status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'current_sequence_name',
          label: 'Current Phase',
          sortable: true,
        },
        {
          key: 'idea_industry_label',
          label: 'Industry',
          sortable: true,
        },
        {
          key: 'tags',
          label: 'Tags',
          sortable: true,
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
        },
        {
          key: 'email',
          label: 'Email',
        },
      ]

      fields.push({
        key: 'actions',
        label: 'Actions',
      })
      return fields
    },
    exportUrl () {
      let params = ''

      if (this.filter.program_id) {
        params = params + '&program_id=' + this.filter.program_id.value
      }

      if (this.filter.courses) {
        params = params + '&course_ids=' + this.filter.courses.map(c => c.id).join(',')
      }

      if (this.filter.engagement_status) {
        params = params + '&engagement_status=' + this.filter.engagement_status.value
      }

      if (this.filter.group_id) {
        params = params + '&group_id=' + this.filter.group_id.value
      }

      if (this.filter.phone_number) {
        params = params + '&phone_number=' + this.filter.phone_number
      }

      if (this.filter.name) {
        params = params + '&name=' + this.filter.name
      }

      if (this.filter.email) {
        params = params + '&email=' + this.filter.email
      }

      if (this.filter.sort) {
        params = params + '&sort=' + this.filter.sort.value
      }

      if (this.filter.state) {
        params = params + '&state=' + this.filter.state.value
      }

      if (this.filter.industry) {
        params = params + '&industry=' + this.filter.industry.value
      }

      if (this.filter.tags) {
        params = params + '&tags=' + this.filter.tags.map(t => t.value).join(',')
      }

      return `/api/partners/${WindowVariables.partnerId}/roster/export?role=student${params}`
    },

  },
  methods: {
    resetFilter () {
      this.filter = {
        engagement_status: null,
        group_id: null,
        state: null,
        phone_number: '',
        email: '',
        name: '',
        industry: null,
        tags: null,
      }
    },
    userUrl (userId) {
      return url({ entityType: 'user', entityId: userId })
    },
    reloadData () {
      const filter = {}

      if (this.filter.program_id) {
        filter.program_id = this.filter.program_id.value
      }

      if (this.filter.courses) {
        filter.course_ids = this.filter.courses.map(c => c.id)
      }

      if (this.filter.engagement_status) {
        filter.engagement_status = this.filter.engagement_status.value
      }

      if (this.filter.group_id) {
        filter.group_id = this.filter.group_id.value
      }

      if (this.filter.state) {
        filter.state = this.filter.state.value
      }

      if (this.filter.phone_number) {
        filter.phone_number = this.filter.phone_number
      }

      if (this.filter.name) {
        filter.name = this.filter.name
      }

      if (this.filter.email) {
        filter.email = this.filter.email
      }

      if (this.filter.sort) {
        filter.sort = this.filter.sort.value
      }

      if (this.filter.industry) {
        filter.industry = this.filter.industry.value
      }

      if (this.filter.tags) {
        filter.tags = this.filter.tags.map(t => t.value).join(',')
      }

      if (this.filter.phase) {
        filter.phase = this.filter.phase
      }

      return this.vm.usersStore.setFilter(Object.assign({}, this.baseUsersFilter, filter))
    },
    requestCourseData () {
      return Courses.getAll({ partner_id: WindowVariables.partnerId })
        .then(c => { this.allCourses = c })
    },
  },
  created () {
    this.requestCourseData()
    this.reloadDataDebounced = debounce(this.reloadData, 100)
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.reloadDataDebounced()
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.pagination-footer {
  display: flex;
  justify-content: center;
  > .btn {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.partner-button-group {
  display: flex;
  gap: .5rem;
  width: 100%;
  justify-content: end;
}

</style>
