<template>
  <div class="community-app-component">
    <div class="users-list-component border-2 border-htss-purple rounded-md p-4">
      <div class="filter-bar">
        <div class="grid grid-cols-1 md:grid-cols-1 gap-3">
          <div class="col-span-1">
            <label for="search-box-filter" class="font-bold block"> {{ $t('community.find_and_connect.search_text') }} </label>
            <input type="text" class="w-full block py-2 border border-gray-300 bg-white rounded-md transition duration-150 ease-in-out" id="search-box-filter" v-model="filter.search_text"/>
          </div>
        </div>

        <div class="col-span-3 text-right items-center mb-3">
          <button @click="resetFilter" class="btn btn-secondary mt-2"> {{ $t('community.find_and_connect.reset') }} </button>
          <button @click="reloadData" class="btn btn-primary mt-2"> {{ $t('community.find_and_connect.filter') }} </button>
        </div>
      </div>

      <div class="border border-gray h-0 mx-auto w-full"></div>

      <h2 class="text-2xl font-bold pt-2 pb-2 mb-0">{{ $t('community.find_and_connect.search_results') }}</h2>
      <b-table responsive striped hover :items="users" :fields="fields" show-empty>
        <template #cell(name)="data">
          <template v-if="mattermostUrlForUser(data.item)">
            <a @click="trackFcConnection(data)" :href="mattermostUrlForUser(data.item)" target="_blank">{{ data.value }}</a>
          </template>
          <template v-else>
            <span>{{ data.value }}</span>
          </template>
        </template>
        <template #empty="">
          <h4 class="p-4 text-center text-base">{{ $t('community.find_and_connect.no_results') }}</h4>
        </template>
      </b-table>

      <div v-if="searching" class="pagination-footer">
        <button class="btn"
          :class="btn.disabled ? 'btn-secondary' : 'btn-primary'"
          v-for="btn in paginationButtonConfiguration"
          :key="btn.type"
          @click="btn.action"
          :disabled="btn.disabled"
        >{{ btn.label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
// import { debounce, flatten, sortBy, uniqBy } from 'lodash-es'
import Multiselect from 'vue-multiselect'
import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import axios from '@dataServices/axios'
import { getGroupIdForAnalytics, getProgramIdForAnalytics } from '@utils'

export default {
  name: 'community-app',
  components: {
    Multiselect,
    ActionModalDropdown,
  },
  data () {
    const sortOrderOptions = [
      { label: 'Status, First/Last Ascending', value: 'status|asc,first_name|asc,last_name|asc', },
      { label: 'Status, First/Last Descending', value: 'status|desc,first_name|desc,last_name|desc', },
      { label: 'Last/First Ascending', value: 'last_name|asc,first_name|asc', },
      { label: 'Last/First Descending', value: 'last_name|desc,first_name|desc', },
      { label: 'First/Last Ascending', value: 'first_name|asc,last_name|asc', },
      { label: 'First/Last Descending', value: 'first_name|desc,last_name|desc', },
      { label: 'Group, First/Last Ascending', value: 'group_name|asc,first_name|asc,last_name|asc', },
      { label: 'Group, Last/First Ascending', value: 'group_name|asc,last_name|asc,first_name|asc', },
      { label: 'Industry, First/Last Ascending', value: 'industry|asc,first_name|asc,last_name|asc' },
      { label: 'Industry, Last/First Ascending', value: 'industry|asc,last_name|asc,first_name|asc' },
    ]

    return {
      sortOrderOptions,
      users: [],
      searching: false,
      paginatedUsers: null,
      paginationButtonConfiguration: [],

      pageMetadata: {
        is_first_page: true,
        current_page: 1,
        total_pages: null,
        is_last_page: false,
      },

      filter: {
        search_text: null,
      },
      commonEventTrackingData: {
        group_id: getGroupIdForAnalytics(),
        program_id: getProgramIdForAnalytics(),
      }
    }
  },
  computed: {
    fields () {
      let fields = [
        {
          key: 'name',
          label: this.$t('community.find_and_connect.table_fields.name'),
          sortable: true,
        },
        {
          key: 'community_location',
          label: this.$t('community.find_and_connect.table_fields.location'),
          sortable: true,
        },
        {
          key: 'idea_industry',
          label: this.$t('community.find_and_connect.table_fields.industry'),
          sortable: false,
        },
        {
          key: 'idea_category',
          label: this.$t('community.find_and_connect.table_fields.idea_type'),
          sortable: true,
        },
        {
          key: 'community_idea_description',
          label: this.$t('community.find_and_connect.table_fields.venture_idea'),
          sortable: true,
        },
        {
          key: 'community_superpowers',
          label: this.$t('community.find_and_connect.table_fields.superpowers'),
          sortable: true,
        },
      ]

      return fields
    },
  },
  methods: {
    trackFcConnection (data) {
      ahoy.track("fc_connect_link", {
        connect_link_student_id: data.item.id,
        connect_link_student_full_name: data.item.name,
        connect_link_student_mattermost_id: this.mattermostUrlForUser(data.item),
        ...this.commonEventTrackingData,
      })
    },
    resetFilter () {
      this.filter = {
        search_text: null,
      }

      this.users = null
      this.searching = false
    },
    reloadData () {
      const filter = {}

      if (this.filter.search_text) {
        filter.search_text = this.filter.search_text
      }

      this.fetchUsers(filter)
    },
    mattermostUrlForUser (user) {
      if (user.mattermost_community_team && user.mattermost_user_id) {
        const teamSlug = user.mattermost_community_team.split('[')[1].replace(']', '')
        return `https://community.howtostartsomething.com/${teamSlug}/messages/${user.mattermost_user_id}`
      } else {
        return null
      }
    },
    async fetchUsers (filter) {
      axios.get('fetch_community_users', { params: filter })
        .then(response => {
          this.configurePagination(response.data)

          ahoy.track("fc_filter", {
            query_params: filter,
            connect_filter_results_count: response.data.length,
            ...this.commonEventTrackingData,
          })
        }, response => {
          console.error('error: ', response)
        })
    },
    configurePagination (data) {
      this.searching = true

      // lets set ~25 items per page
      let size = 25
      this.pageMetadata.total_pages = Math.ceil(data.length / size)
      let arrayOfArrays = []

      for (let i=0; i<data.length; i+=size) {
        arrayOfArrays.push(data.slice(i,i+size))
      }

      this.paginatedUsers = arrayOfArrays
      this.users = this.paginatedUsers[0]

      this.setUpPaginationButtons()
    },
    setUpPaginationButtons () {
      this.paginationButtonConfiguration = []

      this.paginationButtonConfiguration.push({
        type: 'first-page',
        label: 'First',
        action: () => {
          this.pageMetadata.current_page = 1
          this.pageMetadata.is_first_page = true
          this.pageMetadata.is_last_page = false

          this.users = this.paginatedUsers[0]

          this.setUpPaginationButtons()

          return this.pageMetadata.current_page
        },
      })

      const currentPageNum = this.pageMetadata.current_page
      const totalNumbersShown = 5

      let startingIndex = currentPageNum <= parseInt(totalNumbersShown / 2) ? 1 : currentPageNum - 2
      if (this.pageMetadata.total_pages - startingIndex < totalNumbersShown) {
        startingIndex = this.pageMetadata.total_pages - totalNumbersShown + 1
      }
      for (let i = startingIndex; i < startingIndex + totalNumbersShown; i++) {
        if (i > 0 && i <= this.pageMetadata.total_pages) {
          this.paginationButtonConfiguration.push({
            type: `page-number-${i}`,
            label: i,
            action: () => {
              this.pageMetadata.current_page = i
              this.pageMetadata.current_page === 1 ? this.pageMetadata.is_first_page = true : this.pageMetadata.is_first_page = false
              this.pageMetadata.current_page === this.pageMetadata.total_pages ? this.pageMetadata.is_last_page = true : this.pageMetadata.is_last_page = false

              this.users = this.paginatedUsers[i - 1]

              this.setUpPaginationButtons()

              return this.pageMetadata.current_page
            },
            disabled: i === currentPageNum,
          })
        }
      }

      this.paginationButtonConfiguration.push({
        type: 'last-page',
        label: 'Last',
        action: () => {
          this.pageMetadata.current_page = this.pageMetadata.total_pages
          this.pageMetadata.is_first_page = false
          this.pageMetadata.is_last_page = true

          this.users = this.paginatedUsers[this.pageMetadata.current_page - 1]

          this.setUpPaginationButtons()

          return this.pageMetadata.current_page
        },
      })
    }
  },
  created () {
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        return true
      },
    },
  },
  props: {
    industries: {
      type: Array,
      required: true,
    },
    ideaCategories: {
      type: Array,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.pagination-footer {
  display: flex;
  justify-content: center;
  > .btn {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
