<template>
  <div class="sidebar-button-wrapper" :class="buttonStyleClasses" @click="goToAnnouncementsPage">
    <svg :class="iconStyleClasses" :fill="iconStyleFillClasses" viewBox="0 0 24 24" stroke-width="1.5" :stroke="iconStrokeStyle" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
    </svg>
    <span :class="spanStyleClasses">Announcements</span>
    <span class="unread-messages-count" :class="{ 'hidden-occupy-space': !shouldShowUnreadMessagesCount }">
        {{ totalMessagesUnreadCount }}
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import axios from '@dataServices/axios'
import AnnouncementsPanel from './AnnouncementsPanel.vue'

const baseUrl = window.location.origin

const announcementsRoute = "announcements";

export default {
  name: 'direct-messaging',
  components: {
    AnnouncementsPanel,
  },
  props: {
    buttonStyleClasses: { type: String, default: '' },
    spanStyleClasses: { type: String, default: '' },
    iconStyleClasses: { type: String, default: '' },
    iconStyleFillClasses: { type: String, default: '' },
    iconStrokeStyle: { type: String, default: '' },
  },
  data: () => ({
    unsubscribeListenerFunctions: [],
    totalMessagesUnreadCount: null,

    panelElement: null,
    mainElement: null,

    panelVueComponent: null,
  }),
  mounted () {
    this.panelElement = document.getElementById('announcements-panel-wrapper');
    this.mainElement = document.querySelector('main');

    this.fetchTotalMessagesUnreadCount();

    if (this.isCurrentlyAtAnnouncementsPage){
      this.mountAnnouncementsPanel(this.panelElement);
    }

    this.$nextTick(() => {
      document.getElementById('announcements-app-container-wrapper').classList.remove('hidden');
    });
  },
  async beforeDestroy () {
    this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
  },
  computed: {
    shouldShowUnreadMessagesCount() {
      return this.totalMessagesUnreadCount && this.totalMessagesUnreadCount > 0;
    },
    isCurrentlyAtAnnouncementsPage() {
      return this.getCurrentUrl().includes(announcementsRoute);
    },
  },
  methods: {
    async fetchTotalMessagesUnreadCount() {
      return axios.get(baseUrl + `/api/announcements/announcements`)
             .then(response => { this.totalMessagesUnreadCount = response.data.user.unread_announcements_count })
             .catch(error => console.error('Was not able to fetch announcements unread messages count!', error));
    },
    getCurrentUrl(){
      return window.location.href;
    },
    goToAnnouncementsPage(){
      if (!this.isCurrentlyAtAnnouncementsPage){
        window.location.replace(`${baseUrl}/${announcementsRoute}`)
      }
    },
    mountAnnouncementsPanel(panelContainerElement) {
      const panelVueComponent = this.instantiatePanelComponent();
      panelVueComponent.$on('destroyed', () => this.showMainElementScroll(true));
      panelVueComponent.$mount();
      this.clearContainerElement(panelContainerElement);
      this.showMainElementScroll(false);
      panelContainerElement.appendChild(panelVueComponent.$el);
      this.panelVueComponent = panelVueComponent;
    },
    instantiatePanelComponent() {
      const ComponentClass = Vue.extend(AnnouncementsPanel);
      return new ComponentClass({
        parent: this,
        propsData: {
          markAnnouncementsReadCallback: this.clearUnreadMessagesCounter
        }
      });
    },
    clearUnreadMessagesCounter() {
      this.totalMessagesUnreadCount = 0;
    },
    clearContainerElement(containerElement){
      while (containerElement.lastElementChild) {
        containerElement.removeChild(containerElement.lastElementChild);
      }
    },
    showMainElementScroll(value) { //this is a hacky way to hide the sidescroll of the main element as we do not use it on direct messaging and do not want a second scroll.
      if (value === true){
        this.mainElement.style.overflowY = 'auto';
      }
      else {
        this.mainElement.style.overflowY = 'hidden';
      }
    },

  },
  watch: {

  },
}

</script>

<style lang="scss" scoped>

</style>
