<template>
  <div class="chart-container">
    <div class="chart-inner-container">
      <div id="graph-for-event" ref="graph-for-event"></div>
    </div>
  </div>
</template>

<script>
  import C3 from 'c3/c3.esm'
  import { WindowVariables } from '@utils'
  import { every, zipWith } from 'lodash-es'

  // Width of x-axis column
  const xAxisColumnWidth = 125
  // Adjusts the total height of the chart by adjusting the space between ticks (labels)
  // Multiplies the longest string of the column by it's self to increase linearly
  const xAxisTextHeightMultiplier = 4.5
  // Sets the minimum height of each string on the x-axis
  const xAxisTextMinumumHeight = 30
  // Sets the multilineMax property of the chart, e.g. the max amount of lines a label can have
  const xAxisTextLineMax = 2
  // Sets the max amount of lines a label can have before split
  const xAxisTextMaxCharCount = 50

  export default {
    data () {
      return {
        group: WindowVariables.group,
      }
    },
    mounted () {
      // 40 pixels per element
      function setHeight(names) {
        const amount = names.map(name => {
          const len = name.split(" ")
        })

        const max = names.reduce((acc, val) => {
          const splitLength = val.split(' ').length
          return splitLength > acc ? splitLength : acc
        }, 0) * xAxisTextHeightMultiplier + xAxisTextMinumumHeight

        return names.length * max
      }

      // ********** EVENT BAR GRAPH *****************
      let eventChartData = WindowVariables.eventChartData
      let names = eventChartData.map(x => x.name.length > xAxisTextMaxCharCount ? x.name.substring(0, xAxisTextMaxCharCount) + '...' : `    ${x.name}`)
      let allTimeTotal = eventChartData.map(x => x.all_time_total)
      let last7daysTotal = eventChartData.map(x => x.last_7days_total)
      let allWithout7days = eventChartData.map(x => x.all_without_7days)

      const isAllLessThanFive = every(zipWith(last7daysTotal, allWithout7days, (a,b) => a+b), el => el < 5)

      var attendanceByEventGraph = C3.generate({
        title: {
          text: 'Attendance by Event',
        },
        bindto: this.$refs['graph-for-event'],
        size: {
          height: setHeight(names),
        },
        data: {
            columns: [
                ['Last 7 days', ...last7daysTotal],
                ['Prior Time', ...allWithout7days],
            ],
            type: 'bar',
            groups: [
                ['AllTime-minus-7Days', 'Last7Days']
            ]
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
        axis: {
          rotated: true,
          x: {
            type: 'category',
            categories: [...names],

            tick: {
              multiline: true,
              multilineMax: xAxisTextLineMax,
              width: xAxisColumnWidth,
              centered: true,
            }
          },
          y: {
            label: {
              text: `# of ${this.group.studentDisplayName}s`,
              position: 'outer-right',
            },
            tick: {
              format: function(x) {
                return (x == Math.floor(x)) ? x : "";
              }
            },
          },
        },
        grid: {
          y: {
            lines: [{value:0}]
          }
        }
      });

      if (isAllLessThanFive) {
        attendanceByEventGraph.axis.range({ max: { y: 5 } })
      }
    }
  }
</script>


<style lang="scss" scoped>
.chart-container {
  padding: 10px 0px 20px 0px;

  .chart-inner-container {
    padding-top: 10px;
    background-color: white;
  }
}

.c3-tooltip-container {
  z-index: 10;
}
.c3-tooltip {
  border-collapse:collapse;
  border-spacing:0;
  background-color:#fff;
  empty-cells:show;
  -webkit-box-shadow: 7px 7px 12px -9px rgb(119,119,119);
     -moz-box-shadow: 7px 7px 12px -9px rgb(119,119,119);
          box-shadow: 7px 7px 12px -9px rgb(119,119,119);
  opacity: 0.9;
}
.c3-tooltip tr {
  border:1px solid #CCC;
}
.c3-tooltip th {
  background-color: #aaa;
  font-size:14px;
  padding:2px 5px;
  text-align:left;
  color:#FFF;
}
.c3-tooltip td {
  font-size:13px;
  padding: 3px 6px;
  background-color:#fff;
  border-left:1px dotted #999;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value{
  text-align: right;
}
</style>

<style lang="scss">
#graph-for-event .c3-axis.c3-axis-x {
  min-width: 100px;
  width: 100px;

  g.tick {
    width: 100px;
  }
}
</style>
