window.global ||= window

import Vue from 'vue'

import { config as i18n } from '@utils/i18n'

import FlashMessageApp from '../flash_message.vue'
import ModalsApp from '../ModalsApp.vue'

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

import 'vue-multiselect/dist/vue-multiselect.min.css'

import VueFormulate from '@braid/vue-formulate'
import formulateConfig from '@utils/formulateConfig'

import Vuetify from 'vuetify/lib'

import { WindowVariables, getGroupIdForAnalytics, getProgramIdForAnalytics } from '@utils'

import WebWorksheetsService from '@services/webWorksheets/Service'

import ActionModalDropdown from '@components/ActionModalDropdown.vue'
import BulkActionModalDropdown from '@components/BulkActionModalDropdown.vue'
import CourseStepContentSlider from '@components/CourseStepContentSlider.vue'
import GroupMembershipActionHistory from '@components/GroupMembershipActionHistory.vue'
import CoachActionTable from '@components/CoachActionTable.vue'
import OpenModalButton from '@components/general/OpenModalButton.vue'
import CoachesDashboard from '@components/CoachesDashboard.vue'
import CommunityApp from '@components/CommunityApp.vue'

import CourseStepAssignments from '../course_step_assignments.vue'
import CourseStepFeedbackApp from '../course_step_feedback.vue'
import AnalyticEventTrackingApp from '../analytic_event_tracking.vue'
import StarterTrackingApp from '../starter_tracking.vue'
import TimelinePage from '@components/interactionTimeline/InteractionTimelinePage.vue'
import UpcomingMeetingApp from '../upcoming_meeting.vue'
import StudentPacingApp from '../student_pacing_app.vue'
import StudentPacingAppV2 from '../student_pacing_app_v2.vue'
import DirectMessaging from "@components/directMessaging/DirectMessaging.vue";
import Announcements from "@components/announcements/Announcements.vue";
import AttachmentVideo from "@components/AttachmentVideo.vue";


import UsersApp from '../users_component.vue'
import PartnerRosterApp from '../partner_roster.vue'
import PartnerAnalyticsApp from '../partner_analytics.vue'
import KpiGroupsAndMeetingsPivotTable from '../kpi_groups_and_meetings_pivot_table.vue'
import StudentStatusPivotTable from '../student_status_pivot_table.vue'

import FunnelGraph from 'funnel-graph-js/dist/js/funnel-graph.min.js'

import ahoy from "ahoy.js"

ahoy.trackView({
  group_id: getGroupIdForAnalytics(),
  program_id: getProgramIdForAnalytics(),
  course_step_id: WindowVariables.courseStepId && parseInt(WindowVariables.courseStepId),
})

const vuetify = new Vuetify({ })
Vue.use(Vuetify)

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)


import { BDropdown } from 'bootstrap-vue/esm/components/dropdown/dropdown'
import { BDropdownItem } from 'bootstrap-vue/esm/components/dropdown/dropdown-item'
import { BButton } from 'bootstrap-vue/esm/components/button'
import { BTable } from 'bootstrap-vue/esm/components/table'
import { BTabs, BTab } from 'bootstrap-vue/esm/components/tabs'
import { BForm } from 'bootstrap-vue/esm/components/form'
import { BFormGroup } from 'bootstrap-vue/esm/components/form-group'
import { BFormInput } from 'bootstrap-vue/esm/components/form-input'
import { BModal } from 'bootstrap-vue/esm/components/modal'
import { BCollapse } from 'bootstrap-vue/esm/components/collapse'
import { BPagination } from 'bootstrap-vue/esm/components/pagination'
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-button', BButton)
Vue.component('b-table', BTable)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-modal', BModal)
Vue.component('b-collapse', BCollapse)
Vue.component('b-pagination', BPagination)


Vue.use(VueFormulate, formulateConfig)

Vue.config.devtools = true

import './everywhere.css'
import 'flatpickr/dist/flatpickr.min.css'


import VueRouter from 'vue-router';
Vue.use(VueRouter); //FIXME: remove?

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/direct_messaging', component: DirectMessaging },
  ],
});

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

let priorClearIntervals = []
document.addEventListener('DOMContentLoaded', () => {
  priorClearIntervals.forEach((x) => {
    clearInterval(x)
  })


  if ($('#vue-flash-message-app').length > 0) {
    const app4 = new Vue({ i18n, el: '#vue-flash-message-app', render: h => h(FlashMessageApp) })
  }

  /* eslint-disable no-new */
  new Vue({
    i18n,
    el: '#modals-vue-target',
    vuetify,
    render: h => h(ModalsApp),
  })
  /* eslint-enable no-new */

  const COMPONENT_MAP = {
    ActionModalDropdown,
    CourseStepContentSlider,
    GroupMembershipActionHistory,
    CoachActionTable,
    OpenModalButton,
    CoachesDashboard,
    CommunityApp,
    BulkActionModalDropdown,
  }

  function createVueComponent(el) {
    new Vue({
      i18n,
      el,
      vuetify,
      router,
      render: h => h(COMPONENT_MAP[el.attributes['data-vue-component'].value], {
        props: JSON.parse(el.attributes['data-props'].value),
        class: [...el.classList.values()],
      }),
    })
  }

  const targetLoadCont = document.querySelector('.target-load-container')

  if (targetLoadCont) {
    targetLoadCont.addEventListener('click', (event) => {
      if (event.target.classList.contains('vue-target-to-load')) {
        event.preventDefault()
        const targetName = event.target.attributes['data-target-to-load'].value
        const elemToInstantiate = document.querySelector('[data-target-load-id="' + targetName + '"]')
        event.target.style.display = 'none'
        createVueComponent(elemToInstantiate)
      }
    })
  }

  const toInstantiate = document.querySelectorAll('[data-vue-component]:not([data-target-load-id])')

  if (toInstantiate.length > 0) {

    if (toInstantiate.length < 30) {
      toInstantiate.forEach(el => createVueComponent(el))
    } else {
      let elemsToLoadLater = []
      setTimeout(() => {
        toInstantiate.forEach(el => {
          if (isInViewport(el)) {
            createVueComponent(el)
          } else {
            elemsToLoadLater.push(el)
          }
        })

        let loadedIndexes = []
        const intv = setInterval(
          () => {
            elemsToLoadLater.forEach((el, idx) => {
              if (!loadedIndexes.includes(idx) && isInViewport(el)) {
                createVueComponent(el)
                loadedIndexes.push(idx)
              }
            })

            if (loadedIndexes.length === elemsToLoadLater.length) {
              clearInterval(intv)
            }
          }, 100)

          priorClearIntervals.push(intv)
      }, 0)

    }
  }



  if ($('#users-app').length > 0) {
    const app3 = new Vue({ i18n, el: '#users-app', render: h => h(UsersApp) })
  }

  if ($('#analytics-charts-vue').length > 0) {
    const app4 = new Vue({ i18n, el: '#analytics-charts-vue', render: h => h(PartnerAnalyticsApp)})
  }

  if ($('#starter-tracking-app').length > 0) {
    const app5 = new Vue({ i18n, el: '#starter-tracking-app', render: h => h(StarterTrackingApp) })
  }

  if ($('#partner-roster-app').length > 0) {
    const partnerRosterApp = new Vue({ i18n, el: '#partner-roster-app', render: h => h(PartnerRosterApp)})
  }


  if ($('#course-funnel-graph').length > 0 && window.courseFunnelGraphs) {
    if (window.courseFunnelGraphs.onboarding) {
      let data = {
        colors: ['red', 'orange'],
        labels: window.courseFunnelGraphs.onboarding.map(d => d.label),
        values: window.courseFunnelGraphs.onboarding.map(d => d.value)
      }

      const onboardingFunnel = new FunnelGraph({
        container: '#course-funnel-graph',
        gradientDirection: 'horizontal',
        data: data,
        displayPercent: true,
        direction: 'horizontal',
        width: 800,
        height: 175,
      })

      onboardingFunnel.draw()
    }
  }

  var elems = document.querySelectorAll('.kpi-groups-and-meetings-pivot-table')
  elems.forEach(el => {
    new Vue({
      i18n,
      el: el,
      render: h => h(KpiGroupsAndMeetingsPivotTable, {
        props: JSON.parse(el.attributes['data-props'].value),
      }),
      vuetify: vuetify,
    })
  })

  elems = document.querySelectorAll('.student-status-pivot-table')
  elems.forEach(el => {
    new Vue({
      i18n,
      el: el,
      render: h => h(StudentStatusPivotTable, {
        props: JSON.parse(el.attributes['data-props'].value),
      }),
      vuetify: vuetify,
    })
  })




  if ($('#vue-step-assignments-target').length > 0) {
    const app9 = new Vue({ i18n, vuetify, el: '#vue-step-assignments-target', render: h => h(CourseStepAssignments) })
  }

  if ($('#timeline-page-app').length > 0) {
    const app10 = new Vue({ i18n, vuetify, el: '#timeline-page-app', render: h => h(TimelinePage) })
  }

  if ($('#conversations-area').length > 0) {
    const app11 = new Vue({ i18n, vuetify, el: '#conversations-area', render: h => h(ConversationsApp) })
  }

  if ($('#htss-feedback-app').length > 0) {
    const app12 = new Vue({ i18n, vuetify, el: '#htss-feedback-app', render: h => h(CourseStepFeedbackApp) })
  }

  if ($('#analytic-event-tracking-app').length > 0) {
    const app13 = new Vue({ i18n, vuetify, el: '#analytic-event-tracking-app', render: h => h(AnalyticEventTrackingApp) })
  }


  const assignmentRefs = $('.course-step-web-assignment-app')
  if (assignmentRefs.length > 0) {
    assignmentRefs.each((idx, assign) => {
      const ww = new WebWorksheetsService('course-step', {
        el: assign,
        courseStepId: WindowVariables.courseStepId,
        groupId: WindowVariables.groupId,
        userId: WindowVariables.userId,
        stepType: assign.getAttribute('data-step-type'),
      })
      ww.rehydrate()
    })
  }

  if ($('#my-journey-app').length > 0) {
    const app14 = new Vue({
      i18n,
      vuetify,
      el: '#my-journey-app',
      render: h => h(StarterTrackingApp, {
        props: { userId: window.myJourney.userId }
      }),
    })
  }

  if ($('#upcoming-meetings-app-sidebar').length > 0) {
    const app15 = new Vue({
      i18n,
      vuetify,
      el: '#upcoming-meetings-app-sidebar',
      render: h => h(UpcomingMeetingApp),
    })
  }

  if ($('#pacing-app-step').length > 0) {
    const app16 = new Vue({
      i18n,
      vuetify,
      el: '#pacing-app-step',
      render: h => h(StudentPacingApp),
    })
  }

  if ($('#pacing-app-step-v2').length > 0) {
    const app6 = new Vue({
      i18n,
      vuetify,
      el: '#pacing-app-step-v2',
      render: h => h(StudentPacingAppV2),
    })
  }

  if ($('#direct-messaging-app-container').length > 0) {
    const app17 = new Vue({
      i18n,
      vuetify,
      router,
      el: '#direct-messaging-app-container',
      render(h) {
        const buttonStyleClasses = this.$el.getAttribute('data-button-style-classes');
        const spanStyleClasses = this.$el.getAttribute('data-span-style-classes');

        return h(DirectMessaging, {
          props: {
            buttonStyleClasses: buttonStyleClasses,
            spanStyleClasses: spanStyleClasses
          }
        });
      }
    })
  }

  if ($('#announcements-app-container').length > 0) {
    const app18 = new Vue({
      i18n,
      vuetify,
      router,
      el: '#announcements-app-container',
      render(h) {
        const buttonStyleClasses = this.$el.getAttribute('data-button-style-classes');
        const spanStyleClasses = this.$el.getAttribute('data-span-style-classes');
        const iconStyleClasses = this.$el.getAttribute('data-icon-style-classes');
        const iconStyleFillClasses = this.$el.getAttribute('data-icon-fill-style-classes');
        const iconStrokeStyle = this.$el.getAttribute('data-icon-stroke-style');

        return h(Announcements, {
          props: {
            buttonStyleClasses: buttonStyleClasses,
            spanStyleClasses: spanStyleClasses,
            iconStyleClasses: iconStyleClasses,
            iconStyleFillClasses: iconStyleFillClasses,
            iconStrokeStyle: iconStrokeStyle
          }
        });
      }
    })
  }

  if ($('#attachment-video-app-container').length > 0) {
    const app18 = new Vue({
      i18n,
      vuetify,
      router,
      el: '#attachment-video-app-container',
      render(h) {
        return h(AttachmentVideo, {
          props: {
            thumbnail_url: this.$el.getAttribute('data-thumbnail-url'),
            video_url: this.$el.getAttribute('data-video-url'),
            subtitles: JSON.parse(this.$el.getAttribute('data-subtitles')),
            chapters: JSON.parse(this.$el.getAttribute('data-chapters')),
            attachment_id: this.$el.getAttribute('data-attachment-id'),
            group_id: this.$el.getAttribute('data-group-id'),
            course_step_id: this.$el.getAttribute('data-course-step-id'),
            course_id: this.$el.getAttribute('data-course-id'),
          }
        });
      }
    })
  }

})
